import React from "react";
import { withContext } from "Context";
import StudentActions from "./StudentActions";
import AdviserTasks from "./AdviserTasks/AdviserTasks";
import RecentStudents from "./RecentStudents/RecentStudents";
import UpcomingAppointments from "./UpcomingAppointments/UpcomingAppointments";
import DashboardLinks from "../DashboardLinks/DashboardLinks";

const links = [
  {
    name: "Reports",
    desc: "View latest student career reports",
    path: "/reports",
    icon: "fas fa-chart-bar"
  },
  {
    name: "Student Search",
    desc: "Search for students",
    path: "/students",
    icon: "fas fa-search"
  },
  {
    name: "Useful Links",
    desc: "View resources",
    path: "/useful-links",
    icon: "fas fa-external-link-alt"
  }
];

const MainDashboard = ({ user }) => {
  return (
    <div data-cy="dashboard-page" className="content-panel">
      <h1 className="page-heading">
        Welcome back {user && user.name.split(" ")[0]}
      </h1>
      <div className="row">
        <div className="col-lg-12 col-xl-4 mb-4">
          <UpcomingAppointments user={user} />
        </div>
        <div className="col-lg-12 col-xl-4 mb-4">
          <RecentStudents user={user} />
        </div>
        <div className="col-lg-12 col-xl-4 mb-4">
          <DashboardLinks links={links} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mb-4">
          <div className="white-box">
            <StudentActions user={user} />
          </div>
        </div>
        <div className="col-lg-12 col-xl-4">
          <AdviserTasks user={user} />
        </div>
      </div>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), MainDashboard);
