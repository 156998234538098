import React from "react";
import { Breadcrumbs, PageMetaTags } from "../../../components/common";

const AppointmentError = () => {
  return (
    <div style={{ height: "90vh", width: "100%" }}>
      <PageMetaTags title="Appointment Error" />
      <h1>Appointment Error</h1>
      <p>
        It looks like you may have tried to join a video, voice or live chat
        appointment outside of the scheduled time.
      </p>
      <p>Please try joining again at the scheduled time, thanks!</p>
    </div>
  );
};

export default AppointmentError;
