import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { withContext } from "Context";
import {
  Button,
  Col,
  Empty,
  Form,
  notification,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tooltip
} from "antd";
import { storeTaskList } from "../../../../store/actions/taskActions";
import { deleteTask, getTasks } from "Services/task";
import TextArea from "antd/lib/input/TextArea";
import { Breadcrumbs, PageMetaTags } from "../../../common";
import CreateTaskModal from "../CreateTaskModal";
import {
  getActionCommonDetails,
  updateActionCommonDetails
} from "../../../../services/common";
import { ADVISER, STUDENT } from "../../../../constants";
import { BenchMarks } from "../../common";
import TaskList from "../TaskList";
import RoleCheck from "../../../helpers/RoleCheck";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CKEditor } from "ckeditor4-react";

const columns = [
  {
    title: "Previous Know value",
    dataIndex: "old_know"
  },
  {
    title: "Previous Understand value",
    dataIndex: "old_understand"
  }
];

const StudentsTasks = ({ tasks, storeTasks, user }) => {
  const [taskModal, setTaskModal] = useState(false);
  const [taskdetails, setTaskdetails] = useState();
  const [fieldsData, setFieldsData] = useState();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);
  const { studentId } = useParams();
  const [benchMarks] = useState(BenchMarks);
  const [form] = Form.useForm();
  const [studentName, setStudentName] = useState();

  const editorConfig = {
    versionCheck: false,
    // Add other configuration options if needed
  };

  const fetchTasks = () => {
    setLoading(true);

    const query = { page: current };
    if (studentId) {
      query.student = studentId;
    }
    getTasks({
      body: {
        sort_column: "id",
        sort_order: "desc",
        role_id: 3,
        page_limit: limit
      },
      query
    })
      .then(res => {
        storeTasks(res);
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to fetched` });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTasks();
  }, [current, limit]);

  const removeTask = id => {
    deleteTask({ body: { task_id: id } })
      .then(res => {
        fetchTasks();
        if (res && res.success) {
          notification.success({
            message: res.message || "Task delete successfully!"
          });
        } else {
          throw Error;
        }
      })
      .catch(err => {
        notification.error({
          message: err.data.message || "Failed to delete task"
        });
      });
  };

  const renderStudentActionsList = () => {
    return (
      <React.Fragment>
        <RoleCheck authority={[ADVISER]}>
          <div className="mt-2 mb-4">
            <Button
              data-cy="button-add-agreed-action"
              className="dropdown-overlay-edit"
              onClick={() => {
                setTaskModal(true);
              }}
            >
              <i className="fas fa-pen pr-1" />
              Add agreed action
            </Button>
          </div>
        </RoleCheck>
      </React.Fragment>
    );
  };

  const renderTasksList = () => {
    return (
      <TaskList
        setTaskdetails={setTaskdetails}
        setTaskModal={setTaskModal}
        taskdetails={taskdetails}
        removeTask={removeTask}
      />
    );
  };

  const renderPagination = () => {
    return (
      <Row className="mb-2 mr-2 mt-4" type="flex" justify="end">
        <Pagination
          current={current}
          onChange={setCurrent}
          showSizeChanger
          defaultPageSize={limit}
          pageSizeOptions={["10", "25", "50", "100"]}
          onShowSizeChange={(e, p) => setLimit(p)}
          total={(tasks && tasks.total) || 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} tasks`
          }
        />
      </Row>
    );
  };

  const onFinish = values => {
    if (values.know || values.understand) {
      if (values.benchmark) {
        values.benchmark = values.benchmark.join(",");
      }
      setLoading(true);
      updateActionCommonDetails({
        student_id: studentId,
        know: values.know,
        understand: values.understand,
        benchmark: values.benchmark
      })
        .then(() => {
          localStorage.removeItem("know_" + studentId);
          localStorage.removeItem("understand_" + studentId);
          getFieldDetails();
        })
        .catch(error => {
          console.log(error);
          notification.error({ message: `Failed to fetched` });
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    // Role: Adviser, SchoolAdmin, SuperAdmin
    if (user && user.role_slug !== STUDENT && studentId) {
      getFieldDetails();
    } else if (user && user.role_slug === STUDENT && user.id) {
      // id "student id"
      getFieldDetails(user.id);
    }
  }, [studentId, user && user.id]);

  const getFieldDetails = id => {
    getActionCommonDetails({
      query: { student_id: studentId || id }
    })
      .then(response => {
        setStudentName(response.student_name);
        if (response.success) {
          if (localStorage.hasOwnProperty("know_" + studentId)) {
            response.know = localStorage.getItem("know_" + studentId);
          } else {
            localStorage.setItem("know_" + studentId, response.know);
          }
          if (localStorage.hasOwnProperty("understand_" + studentId)) {
            response.understand = localStorage.getItem(
              "understand_" + studentId
            );
          } else {
            localStorage.setItem(
              "understand_" + studentId,
              response.understand
            );
          }
          setFieldsData(response);
          form.setFieldsValue({
            know: response.know,
            understand: response.understand,
            old_know: response.old_know,
            old_understand: response.old_understand,
            benchmark: response.benchmark.map(item => item.benchmark)
          });
        } else {
          //for new task
          if (localStorage.hasOwnProperty("know_" + studentId)) {
            response.know = localStorage.getItem("know_" + studentId);
          } else {
            localStorage.setItem("know_" + studentId, "");
          }
          if (localStorage.hasOwnProperty("understand_" + studentId)) {
            response.understand = localStorage.getItem(
              "understand_" + studentId
            );
          } else {
            localStorage.setItem("understand_" + studentId, "");
          }
          setFieldsData(response);

          form.setFieldsValue({
            know: response.know,
            understand: response.understand
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to fetched fields data`
        });
      })
      .finally(() => setLoading(false));
  };
  const onChangeKnow = e => {
    localStorage.setItem("know_" + studentId, e);
  };

  const onChangeUnderstand = e => {
    localStorage.setItem("understand_" + studentId, e);
  };

  // Role: Adviser, SchoolAdmin, SuperAdmin
  const renderKnowAndUnderstandFieldForm = () => {
    return (
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col xl={12} lg={12} sm={24}>
            <div className="form-group pb-2">
              <label className="labelstyle">
                Know &nbsp;
                <Tooltip title="This section describes your starting point at the beginning of our meeting.">
                  <InfoCircleOutlined style={{ fontSize: "18px" }} />
                </Tooltip>
              </label>
              <Form.Item name="know">
                <CKEditor
                  type="classic"
                  config={editorConfig}
                  initData={localStorage.getItem("know_" + studentId)}
                  onChange={data => {
                    onChangeKnow(data.editor.getData());
                    form.setFieldsValue({
                      know: data.editor.getData()
                    });
                  }}
                />
              </Form.Item>
            </div>
          </Col>

          <Col xl={12} lg={12} sm={24}>
            <div className="form-group pb-2">
              <label className="labelstyle">
                Understand &nbsp;
                <Tooltip title="This section explains what we discussed and your understanding of the next steps.">
                  <InfoCircleOutlined style={{ fontSize: "18px" }} />
                </Tooltip>
              </label>
              <Form.Item name="understand">
                <CKEditor
                  type="classic"
                  config={editorConfig}
                  initData={localStorage.getItem("understand_" + studentId)}
                  onChange={data => {
                    onChangeUnderstand(data.editor.getData());
                    form.setFieldsValue({
                      understand: data.editor.getData()
                    });
                  }}
                />
              </Form.Item>
            </div>
          </Col>

          <Col xl={24} lg={24} sm={24}>
            {studentId && fieldsData && (
              <Table
                dataSource={[
                  {
                    key: "1",
                    old_know: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: fieldsData.old_know
                        }}
                      />
                    ),
                    old_understand: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: fieldsData.old_understand
                        }}
                      />
                    )
                  }
                ]}
                columns={columns}
                pagination={false}
              />
            )}

            {studentId && renderStudentActionsList()}

            <div className="profile-wrapper p-0">
              <div className="table-responsive mobile-spacing">
                <Spin spinning={loading}>
                  {tasks && tasks.taskList && tasks.taskList.length > 0 ? (
                    <React.Fragment>
                      <div className="list-heading">
                        <i className="fas fa-check" /> Tasks
                      </div>
                      {renderTasksList()}
                      {renderPagination()}
                    </React.Fragment>
                  ) : (
                    <Empty description="No tasks available" />
                  )}
                </Spin>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} sm={24}>
            <div className="form-group pb-2">
              <label className="labelstyle">
                Select related Gatsby Benchmarks
              </label>
              <Form.Item
                name="benchmark"
                rules={[
                  {
                    required: true,
                    message: "Please select the benchmark"
                  }
                ]}
              >
                <Select
                  mode="multiple"
                  className="select-style"
                  placeholder="Select BenchMark"
                >
                  {benchMarks.map(benchmark => (
                    <Select.Option key={benchmark.id} value={benchmark.id}>
                      {benchmark.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xl={12} lg={12} sm={24}></Col>

          <Col xl={12} lg={12} sm={12}>
            <a
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_API_URL}/action-plan-download/${studentId}`}
              target="_blank"
              className="flotlft"
            >
              <Button
                // onClick={() => downloadPdf({ pathParams: { studentId } })}
                className="my-4"
                type="primary"
              >
                Download PDF
              </Button>
            </a>
            <div className="form-group">
              <label className="labelstyle mb-4" />
              <Popconfirm
                onConfirm={() => form.submit()}
                okText="Confirm"
                okType="primary"
                placement="rightTop"
                title="Are the sure you want to save the information?"
              >
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button flotright"
                  value={loading ? "Loading..." : "Save"}
                />
              </Popconfirm>
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  // Role: Student
  const renderKnowAndUnderstandViewForm = () => {
    return (
      <Form form={form} onFinish={onFinish}>
        <div className="row">
          <div className={"col-12 col-md-6"}>
            <div className="form-group pb-2">
              <label className="labelstyle">Know</label>
              <Form.Item name="know">
                <TextArea
                  placeholder={"Know value..."}
                  className="textarea-style"
                  disabled={true}
                  autoSize={{ minRows: 5, maxRows: 8 }}
                />
              </Form.Item>
            </div>
          </div>
          <div className={"col-12 col-md-6"}>
            <div className="form-group pb-2">
              <label className="labelstyle">Previous Know value</label>
              <Form.Item name="old_know">
                <TextArea
                  placeholder={"Previous Know value..."}
                  className="textarea-style"
                  disabled={true}
                  autoSize={{ minRows: 5, maxRows: 8 }}
                />
              </Form.Item>
            </div>
          </div>
          <div className={"col-12 col-md-6"}>
            <div className="form-group pb-2">
              <label className="labelstyle">Understand value</label>
              <Form.Item name="understand">
                <TextArea
                  placeholder={"Understand value..."}
                  className="textarea-style"
                  disabled={true}
                  autoSize={{ minRows: 5, maxRows: 8 }}
                />
              </Form.Item>
            </div>
          </div>
          <div className={"col-12 col-md-6"}>
            <div className="form-group pb-2">
              <label className="labelstyle">Previous Understand value</label>
              <Form.Item name="old_understand">
                <TextArea
                  placeholder={"Previous Understand value..."}
                  className="textarea-style"
                  disabled={true}
                  autoSize={{ minRows: 5, maxRows: 8 }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs
        breadcrumbs={[
          {
            name: "Students list",
            path: `/students`
          },
          {
            name: "Student profile",
            path: `/students/${studentId}`
          },
          {
            name: "Action plan",
            path: null
          }
        ]}
      />
    );
  };

  const divStyle = {
    fontSize: "22px"
  };

  return (
    <div data-cy="student-action-list-page" className="content-panel">
      {renderBreadcrumbs()}
      <PageMetaTags title="Action Plan" />
      <div className="flex justify-between items-center" style={divStyle}>
        Student Name: {studentName}
      </div>
      <div className="student-booking">
        <h1 className="page-heading">Action Plan</h1>
      </div>

      <CreateTaskModal
        visible={taskModal}
        task={taskdetails}
        fetchtasks={fetchTasks}
        setTask={setTaskdetails}
        setVisible={setTaskModal}
        // Note: Hotfix to pass student object since no API to fetch `get-student-list` by ID to get single student*/
        student={{ id: studentId }}
      />

      {/*Note: studentId exist means accessing from non-student role */}
      {studentId
        ? renderKnowAndUnderstandFieldForm()
        : renderKnowAndUnderstandViewForm()}
    </div>
  );
};

export default withContext(
  ([{ tasks, user }, dispatch]) => ({
    tasks,
    user,
    storeTasks: data => storeTaskList(data, dispatch)
  }),
  StudentsTasks
);
