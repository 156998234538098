import React, { useEffect, useState } from "react";
import moment from "moment";
import { withContext } from "Context";
import { Empty, notification, Pagination, Row, Skeleton, Table } from "antd";
import { openLinkInNewTab, PageMetaTags } from "../../../common";
import { Breadcrumbs } from "../../../../components/common";
import { useParams } from "react-router-dom";
import {
  getAppointmentsHistory,
  getStudentAppointmentsHistory,
  getTranscript
} from "../../../../services/appointment";
import {
  ADVISER,
  APPOINTMENT_TYPES,
  MomentDateFormats
} from "../../../../constants";

import {
  AppointmentTypes,
  downloadChatUrl,
  generateChatUrl,
  handleChatDownloadScript,
  openChatLink
} from "./services/pubnub-chat";

function handleDownloadScript(value) {
  return getTranscript({ pathParams: { archive_id: value } });
}

const getDownloadText = appointmentType => {
  if (appointmentType === AppointmentTypes.video_chat) {
    return "Download Video";
  } else if (appointmentType === AppointmentTypes.voice_chat) {
    return "Download Voice";
  } else if (appointmentType === AppointmentTypes.group_call) {
    return "Download Webinar";
  }
};

const columns = [
  { title: "Adviser", dataIndex: "adviserName", key: "adviserName" },
  { title: "Student", dataIndex: "studentName", key: "studentName" },
  {
    title: "Type",
    dataIndex: "appointment_type",
    key: "appointment_type",
    render: value => {
      const type = APPOINTMENT_TYPES[value];
      return type && type.name ? type.name : "N/A";
    }
  },
  {
    title: "Date and time",
    dataIndex: "start",
    key: "start",
    render: value => moment(value).format(MomentDateFormats.LongDateFormat.LLLL)
  },
  { title: "Total Time", dataIndex: "total_time", key: "total_time" },

  {
    title: "Transcript",
    render: (value, record) => {
      if (record.archive_id) {
        return (
          <a
            href="#"
            onClick={async () => {
              const response = await handleDownloadScript(record.archive_id);
              if (response.success) {
                openLinkInNewTab(response.temp_url);
              } else {
                notification.success({ message: "Failed to fetch url" });
              }
            }}
          >
            {getDownloadText(record.appointment_type)}
          </a>
        );
      }

      if (record.appointment_type === AppointmentTypes.live_chat) {
        return (
          <a
            href="#"
            onClick={async () => {
              let messages = await handleChatDownloadScript(
                { channel_name: record.channel_id },
                { email: record.login_email }
              );
              if (record.chat_url) {
                const body = { download_url: record.chat_url };
                const response = await downloadChatUrl(body);
                openChatLink(response);
              } else {
                if (messages && messages.length > 0) {
                  messages = messages.map(item =>
                    `${item.timetoken} | ${item.uuid} | ${item.message.text} ||`.trim()
                  );
                  const body = {
                    channel_id: record.channel_id,
                    messages: messages
                  };
                  const response = await generateChatUrl(body);
                  openChatLink(response);
                } else {
                  notification.success({
                    message: "No messages history found"
                  });
                }
              }
            }}
          >
            Download Chat
          </a>
        );
      } else {
        return null;
      }
    }
  }
];

const AppointmentsHistory = ({ user }) => {
  const pageHeading = "Appointments History";
  const [loading, setLoading] = useState(false);
  const [appointmentsHistory, setAppointmentsHistory] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const params = useParams();
  const studentId = params?.studentId;
  useEffect(() => {
    if (user && user.school_id) {
      if (studentId) {
        getStudentAppointmentsHistoryListByStudentId();
      } else {
        getAppointmentsHistoryList({
          school_id: user.school_id,
          past_booking: 1
        });
      }
    } else {
      if (studentId) {
        getStudentAppointmentsHistoryListByStudentId();
      } else {
        getAppointmentsHistoryList();
      }
    }
  }, [user.school_id, pageLimit, currentPage]);

  const getAppointmentsHistoryList = async query => {
    setLoading(true);
    const response = await getAppointmentsHistory({
      body: {
        sort_column: "id",
        sort_order: "asc",
        page: currentPage,
        page_limit: pageLimit,
        filter_column: user.role_slug === ADVISER ? "adviser_id" : "student_id",
        past_booking: 1
      },
      query: query || {}
    });
    if (response.success) {
      setAppointmentsHistory(response);
    }
    setLoading(false);
  };

  const getStudentAppointmentsHistoryListByStudentId = async query => {
    setLoading(true);
    const response = await getStudentAppointmentsHistory({
      body: {
        sort_column: "id",
        sort_order: "asc",
        page: currentPage,
        page_limit: pageLimit,
        student_id: studentId,
        past_booking: 1
      },
      query: query || {}
    });

    if (response.success) {
      setAppointmentsHistory(response);
    }
    setLoading(false);
  };
  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs
        breadcrumbs={[
          {
            name: "Students list",
            path: `/students`
          },
          {
            name: "Student profile",
            path: `/students/${studentId}`
          },
          {
            name: "Appointment history",
            path: null
          }
        ]}
      />
    );
  };

  return (
    <div data-cy="appointments-history-page" className="content-panel">
      {studentId ? renderBreadcrumbs() : ""}
      <PageMetaTags title={pageHeading} />
      <h1 className="page-heading">{pageHeading}</h1>
      <div className="profile-wrapper">
        <Skeleton loading={loading}>
          <div className="container">
            {appointmentsHistory && appointmentsHistory.appointments ? (
              <Table
                dataSource={
                  appointmentsHistory && appointmentsHistory.appointments
                }
                columns={columns}
                pagination={false}
                footer={() => (
                  <Row className="mb-2 mr-4 mt-4" type="flex" justify="end">
                    <Pagination
                      current={currentPage}
                      onChange={setCurrentPage}
                      total={
                        (appointmentsHistory &&
                          appointmentsHistory.total_count) ||
                        0
                      }
                      showTotal={(total, range) =>
                        `${range[0]}-${range[1]} of ${total} appointments`
                      }
                    />
                  </Row>
                )}
              />
            ) : (
              <Empty description="No appointments to display" />
            )}
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default withContext(contextValue => {
  if (!contextValue && !contextValue?.[0]?.user) {
    return {};
  }
  const [state] = contextValue;
  const { user } = state;
  return {
    user
  };
}, AppointmentsHistory);
