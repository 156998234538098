import React, { useState } from "react";
import { Collapse } from "antd";
import moment from "moment";
import { ExerciseResultMatching } from "../StarExerciseWizard/ExerciseQuestions";
import { MomentDateFormats } from "../../../../../constants";

const { Panel } = Collapse;

const PreviousBreakDown = ({ className, pastExercises }) => {
  const dataFormat = MomentDateFormats.CustomDateFormat.dayMonthYear;
  const reverseMapping = ExerciseResultMatching;
  const keys = Object.keys(pastExercises);
  const [currentActiveKey, setCurrentActiveKey] = useState(keys[0]);

  const onPanelChange = key => {
    if (key === currentActiveKey) {
      setCurrentActiveKey(null);
    } else {
      setCurrentActiveKey(key);
    }
  };

  return keys.map((key, index) => {
    return (
      <Collapse
        key={index}
        activeKey={currentActiveKey}
        className={className}
        onChange={() => {
          onPanelChange(key);
        }}
      >
        <Panel header={reverseMapping[key].category} key={key}>
          {pastExercises[key].map((item, index) => {
            let selectedOptionText = reverseMapping[key].options.find(
              option => item.value === option.score
            ).question;
            return (
              <div key={index}>
                <p>
                  <b>Completed</b>{" "}
                  <span className={"text-muted"}>
                    {moment(item.completion_date).format(dataFormat)}
                  </span>
                </p>
                <p>{selectedOptionText}</p>
              </div>
            );
          })}
        </Panel>
      </Collapse>
    );
  });
};

export { PreviousBreakDown };
