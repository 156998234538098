import React from "react";
import { Column } from "@ant-design/plots";

const StackedColumn = ({ data }) => {
  const config = {
    data,
    isStack: true,
    xField: "benchmark",
    yField: "value",
    seriesField: "name",
    label: {
      position: "middle" // 'top', 'bottom', 'middle'
    },
    interactions: [
      {
        type: "active-region",
        enable: false
      }
    ],
    xAxis: {
      label: {
        formatter: value => {
          return value.substring(0, 10) + "...";
        }
      }
    },
    connectedArea: {
      style: (oldStyle, element) => {
        return {
          fill: "rgba(0,0,0,0.25)",
          stroke: oldStyle.fill,
          lineWidth: 0.5
        };
      }
    }
  };

  return <Column {...config} />;
};

export default StackedColumn;
