import React from "react";
import AdvisorProfile from "./AdvisorProfile";
import { Redirect, Route, Switch } from "react-router-dom";
import "./style.scss";

const Profile = () => (
  <Switch>
    <Route exact path="/profile" component={AdvisorProfile} />
    <Redirect to="/login" />
  </Switch>
);

export default Profile;
