import React, { useEffect, useState } from "react";
import { PageMetaTags } from "Common";
import { Col, Row, Skeleton, Table, Divider } from "antd";
import { useHistory } from "react-router";
import { TableSummary } from "../Shared/TableSummary";
import { reports } from "../../../common/SideBar/routes";
import { Routes } from "../../../../constants";
import { getPost16Reports } from "Services/report";
import { PieChart } from "../../Resources/StarExercise/AntDesignPlotCharts";

// Todo: remove till BE doesn't create API's till that default data will be used
const ragAfter = {
  name: "RAG (AFTER careers meeting)",
  data: [
    { sno: 1, value: 0, name: "Choices made, likely to have applied." },
    { sno: 2.1, value: 0, name: "Place identified, needs help to apply." },
    { sno: 2.2, value: 0, name: "Place identified, needs guidance." },
    { sno: 2.3, value: 0, name: "Nowhere identified, needs help." },
    { sno: 3, value: 0, name: "Needs urgent appointment." }
  ],
  total: 0
};
// Todo: remove till BE doesn't create API's till that default data will be used
const data = {
  rag_before: {
    name: "RAG (BEFORE careers meeting)",
    data: [
      { sno: 1, value: 0, name: "Choices made, likely to have applied." },
      { sno: 2.1, value: 0, name: "Place identified, needs help to apply." },
      { sno: 2.2, value: 0, name: "Place identified, needs guidance." },
      { sno: 2.3, value: 0, name: "Nowhere identified, needs help." },
      { sno: 3, value: 0, name: "Needs urgent appointment." }
    ],
    total: 0
  },
  rag_after: {
    name: "RAG (AFTER careers meeting)",
    data: [
      { sno: 1, value: 0, name: "Choices made, likely to have applied." },
      { sno: 2.1, value: 0, name: "Place identified, needs help to apply." },
      { sno: 2.2, value: 0, name: "Place identified, needs guidance." },
      { sno: 2.3, value: 0, name: "Nowhere identified, needs help." },
      { sno: 3, value: 0, name: "Needs urgent appointment." }
    ],
    total: 0
  },
  intended_level: {
    name: "Intended Level of Study",
    data: [
      { name: "3", no: 0, percent: 0 },
      { name: "4", no: 0, percent: 0 },
      { name: "6", no: 0, percent: 0 },
      { name: "Other", no: 0, percent: 0 }
    ]
  },
  destinations: {
    name: "Destinations",
    data: [
      { name: "University (Non-Russell Gp)", no: 0, percent: 0 },
      { name: "Russell Group Uni", no: 0, percent: 0 },
      { name: "Apprenticeship", no: 0, percent: 0 },
      { name: "Gap Year", no: 0, percent: 0 },
      { name: "Employment", no: 0, percent: 0 },
      { name: "FE College", no: 0, percent: 0 },
      { name: "Other Sixth Form", no: 0, percent: 0 },
      { name: "Other", no: 0, percent: 0 },
      { name: "NEET", no: 0, percent: 0 }
    ]
  },
  sectors: {
    name: "Sectors",
    data: [
      { name: "Animal Care / Land Based Studies", no: 0, percent: 0 },
      { name: "Art, Design & Creative Media", no: 0, percent: 0 },
      { name: "Business & Finance", no: 0, percent: 0 },
      { name: "Construction", no: 0, percent: 0 },
      { name: " Education & Childcare", no: 0, percent: 0 },
      { name: "Engineering", no: 0, percent: 0 },
      { name: "English", no: 0, percent: 0 },
      { name: "Government & Politics", no: 0, percent: 0 },
      { name: "Hair & Beauty", no: 0, percent: 0 },
      { name: " Health & Social (incl nursing)", no: 0, percent: 0 },
      { name: "Hospitality & Catering", no: 0, percent: 0 },
      { name: "Humanities", no: 0, percent: 0 },
      { name: "ICT / Computing", no: 0, percent: 0 },
      { name: "Languages", no: 0, percent: 0 },
      { name: "Law", no: 0, percent: 0 },
      { name: "Maths", no: 0, percent: 0 },
      { name: "Medicine & related", no: 0, percent: 0 },
      { name: "Performing Arts", no: 0, percent: 0 },
      { name: "Public Services / Armed Forces", no: 0, percent: 0 },
      { name: "Sport & Leisure", no: 0, percent: 0 },
      { name: "Science", no: 0, percent: 0 },
      { name: "Social Science", no: 0, percent: 0 },
      { name: "Other", no: 0, percent: 0 }
    ]
  }
};

const linkPostReportToStudent = (value, record) => {
  const queryString = new URLSearchParams(window.location.search);
  const yeargroup = queryString.get("year_group");
  if (yeargroup) {
    if (
      record.filter_column_key === "rag_before" ||
      record.filter_column_key === "rag_after"
    ) {
      if (
        typeof record.report_filter_field !== "undefined" &&
        record.report_filter_field
      ) {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&report_filter_field=${record.report_filter_field}&rag_data=${record.sno}`}
          >
            {value}
          </a>
        );
      } else {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&rag_data=${record.sno}`}
          >
            {value}
          </a>
        );
      }
    } else {
      if (
        typeof record.report_filter_field !== "undefined" &&
        record.report_filter_field
      ) {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&filter_column_key=${record.filter_column_key}&rag_data=${record.name}&report_filter_field=${record.report_filter_field}`}
          >
            {value}
          </a>
        );
      } else {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&filter_column_key=${record.filter_column_key}&rag_data=${record.name}`}
          >
            {value}
          </a>
        );
      }
    }
  }
};

const ragcols = [
  { dataIndex: "sno" },
  { dataIndex: "value", render: linkPostReportToStudent },
  { dataIndex: "name" }
];

const cols = [
  { dataIndex: "name" },
  { dataIndex: "no", render: linkPostReportToStudent },
  { dataIndex: "percent", render: value => `${value} %` }
];

const Post16Reports = ({ user }) => {
  const [payload, setPayload] = useState();
  const [response, setResponse] = useState();
  const [piedemoRagLive, setPiedemoRagLive] = useState();
  const [piedemoRagBefore, setPiedemoRagBefore] = useState();
  const [pieRagBeforeColor, setRagBeforeColor] = useState();
  const [pieRagAfterColor, setRagAfterColor] = useState();
  const [labelRagLive, setlabelRagLive] = useState();
  const [labelRagbefore, setlabelRagbefore] = useState();
  const [getLoading, setGetLoading] = useState(false);
  const history = useHistory();
  const [getTotal, setTotal] = useState();
  const urlQuery = new URLSearchParams(history.location.search);
  const yearsGroup = useState(urlQuery.get("year_group"))[0];

  const getReportDetails = query => {
    setGetLoading(true);
    getPost16Reports({
      pathParams: { path: history.location.pathname },
      query: query || {}
    })
      .then(res => {
        setResponse(res);
        setPayload(res.data);
        setTotal(res.total_student_count);
        if (
          typeof res.data.rag_after !== "undefined" &&
          res.data.rag_after.data
        ) {
          var newrAfterList = [];
          var newrAfterColors = [];
          var colors = ["#00b050", "#ED9A76", "#ffbc6a", "#D79334", "#ff0000"];
          for (var i = 0; i < res.data.rag_after.data.length; i++) {
            if (res.data.rag_after.data[i].sno) {
              if (res.data.rag_after.data[i].value !== 0) {
                res.data.rag_after.data[i].sno =
                  "RAG " + res.data.rag_after.data[i].sno;
                newrAfterColors.push(colors[i]);
                newrAfterList.push(res.data.rag_after.data[i]);
              } else {
                res.data.rag_after.data[i].sno =
                  "RAG " + res.data.rag_after.data[i].sno;
              }
            }
          }
          newrAfterColors.push("#833c0b");
          newrAfterList.push({
            sno: "No data",
            value: res.total_student_count - res.data.rag_after.total,
            name: "",
            filter_key: res.data.rag_after.data[0].filter_key,
            filter_column_key: "rag_after"
          });
          //console.log(newrAfterColors);
          setPiedemoRagLive(newrAfterList);
          setlabelRagLive("Live RAG");
          setRagAfterColor(newrAfterColors);
        }
        if (
          typeof res.data.rag_before !== "undefined" &&
          res.data.rag_before.data
        ) {
          var newrBeforeList = [];
          var newrBeforeColors = [];
          var colors = ["#00b050", "#ED9A76", "#ffbc6a", "#D79334", "#ff0000"];
          for (var i = 0; i < res.data.rag_before.data.length; i++) {
            if (res.data.rag_before.data[i].sno) {
              if (res.data.rag_before.data[i].value !== 0) {
                res.data.rag_before.data[i].sno =
                  "RAG " + res.data.rag_before.data[i].sno;
                newrBeforeList.push(res.data.rag_before.data[i]);
                newrBeforeColors.push(colors[i]);
              } else {
                res.data.rag_before.data[i].sno =
                  "RAG " + res.data.rag_before.data[i].sno;
              }
            }
          }
          newrBeforeList.push({
            sno: "No data",
            value: res.total_student_count - res.data.rag_before.total,
            name: "",
            filter_key: res.data.rag_before.data[0].filter_key,
            filter_column_key: "rag_before"
          });
          newrBeforeColors.push("#833c0b");
          setPiedemoRagBefore(newrBeforeList);
          setRagBeforeColor(newrBeforeColors);
          setlabelRagbefore("Post 16 Questionnaire RAG");
        }
        if (
          typeof res.data.rag_after !== "undefined" &&
          res.data.rag_after.data
        ) {
          if (history.location.pathname === "/reports/post16-eal") {
            res.data.rag_after.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_after.total,
              name: "",
              filter_key: res.data.rag_after.data[0].filter_key,
              filter_column_key: "rag_after",
              report_filter_field: "eal"
            });
          } else if (history.location.pathname === "/reports/post16-lac") {
            res.data.rag_after.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_after.total,
              name: "",
              filter_key: res.data.rag_after.data[0].filter_key,
              filter_column_key: "rag_after",
              report_filter_field: "looked_after"
            });
          } else if (history.location.pathname === "/reports/post16-send") {
            res.data.rag_after.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_after.total,
              name: "",
              filter_key: res.data.rag_after.data[0].filter_key,
              filter_column_key: "rag_after",
              report_filter_field: "sen_status"
            });
          } else if (history.location.pathname === "/reports/post16-bursary") {
            res.data.rag_after.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_after.total,
              name: "",
              filter_key: res.data.rag_after.data[0].filter_key,
              filter_column_key: "rag_after",
              report_filter_field: "16_19_bursary"
            });
          } else {
            res.data.rag_after.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_after.total,
              name: "",
              filter_key: res.data.rag_after.data[0].filter_key,
              filter_column_key: "rag_after"
            });
          }
        }

        if (
          typeof res.data.rag_before !== "undefined" &&
          res.data.rag_before.data
        ) {
          if (history.location.pathname === "/reports/post16-eal") {
            res.data.rag_before.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_before.total,
              name: "",
              filter_key: res.data.rag_before.data[0].filter_key,
              filter_column_key: "rag_before",
              report_filter_field: "eal"
            });
          } else if (history.location.pathname === "/reports/post16-lac") {
            res.data.rag_before.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_before.total,
              name: "",
              filter_key: res.data.rag_before.data[0].filter_key,
              filter_column_key: "rag_before",
              report_filter_field: "looked_after"
            });
          } else if (history.location.pathname === "/reports/post16-send") {
            res.data.rag_before.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_before.total,
              name: "",
              filter_key: res.data.rag_before.data[0].filter_key,
              filter_column_key: "rag_before",
              report_filter_field: "sen_status"
            });
          } else if (history.location.pathname === "/reports/post16-bursary") {
            res.data.rag_before.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_before.total,
              name: "",
              filter_key: res.data.rag_before.data[0].filter_key,
              filter_column_key: "rag_before",
              report_filter_field: "16_19_bursary"
            });
          } else {
            res.data.rag_before.data.push({
              sno: "No data",
              value: res.total_student_count - res.data.rag_before.total,
              name: "",
              filter_key: res.data.rag_before.data[0].filter_key,
              filter_column_key: "rag_before"
            });
          }
        }
        if (
          typeof res.data.destinations !== "undefined" &&
          res.data.destinations.data
        ) {
          if (history.location.pathname === "/reports/post16-eal") {
            if (res.total_student_count - res.data.destinations.total === 0) {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: 0,
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "eal"
              });
            } else {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.destinations.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "eal"
              });
            }
          } else if (history.location.pathname === "/reports/post16-lac") {
            if (res.total_student_count - res.data.destinations.total === 0) {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: 0,
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "looked_after"
              });
            } else {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.destinations.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "looked_after"
              });
            }
          } else if (history.location.pathname === "/reports/post16-send") {
            if (res.total_student_count - res.data.destinations.total === 0) {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: 0,
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "sen_status"
              });
            } else {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.destinations.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "sen_status"
              });
            }
          } else if (history.location.pathname === "/reports/post16-bursary") {
            if (res.total_student_count - res.data.destinations.total === 0) {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: 0,
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "16_19_bursary"
              });
            } else {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.destinations.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "16_19_bursary"
              });
            }
          } else {
            if (res.total_student_count - res.data.destinations.total === 0) {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: 0,
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations"
              });
            } else {
              res.data.destinations.data.push({
                name: "No data",
                no: res.total_student_count - res.data.destinations.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.destinations.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.destinations.data[0].filter_key,
                filter_column_key: "destinations"
              });
            }
          }
        }
        if (
          typeof res.data.expected_level !== "undefined" &&
          res.data.expected_level.data
        ) {
          if (history.location.pathname === "/reports/post16-eal") {
            if (res.total_student_count - res.data.expected_level.total === 0) {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: 0,
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "eal"
              });
            } else {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.expected_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "eal"
              });
            }
          } else if (history.location.pathname === "/reports/post16-lac") {
            if (res.total_student_count - res.data.expected_level.total === 0) {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: 0,
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "looked_after"
              });
            } else {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.expected_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "looked_after"
              });
            }
          } else if (history.location.pathname === "/reports/post16-send") {
            if (res.total_student_count - res.data.expected_level.total === 0) {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: 0,
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "sen_status"
              });
            } else {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.expected_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "sen_status"
              });
            }
          } else if (history.location.pathname === "/reports/post16-bursary") {
            if (res.total_student_count - res.data.expected_level.total === 0) {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: 0,
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "destinations",
                report_filter_field: "16_19_bursary"
              });
            } else {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.expected_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level",
                report_filter_field: "16_19_bursary"
              });
            }
          } else {
            if (res.total_student_count - res.data.expected_level.total === 0) {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: 0,
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level"
              });
            } else {
              res.data.expected_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.expected_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.expected_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.expected_level.data[0].filter_key,
                filter_column_key: "expected_level"
              });
            }
          }
        }

        if (
          typeof res.data.intended_level !== "undefined" &&
          res.data.intended_level.data
        ) {
          if (history.location.pathname === "/reports/post16-eal") {
            if (res.total_student_count - res.data.intended_level.total === 0) {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: 0,
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "eal"
              });
            } else {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.intended_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "eal"
              });
            }
          } else if (history.location.pathname === "/reports/post16-lac") {
            if (res.total_student_count - res.data.intended_level.total === 0) {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: 0,
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "looked_after"
              });
            } else {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.intended_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "looked_after"
              });
            }
          } else if (history.location.pathname === "/reports/post16-send") {
            if (res.total_student_count - res.data.intended_level.total === 0) {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: 0,
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "sen_status"
              });
            } else {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.intended_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "sen_status"
              });
            }
          } else if (history.location.pathname === "/reports/post16-bursary") {
            if (res.total_student_count - res.data.intended_level.total === 0) {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: 0,
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "16_19_bursary"
              });
            } else {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.intended_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level",
                report_filter_field: "16_19_bursary"
              });
            }
          } else {
            if (res.total_student_count - res.data.intended_level.total === 0) {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: 0,
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level"
              });
            } else {
              res.data.intended_level.data.push({
                name: "No data",
                no: res.total_student_count - res.data.intended_level.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.intended_level.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.intended_level.data[0].filter_key,
                filter_column_key: "intended_level"
              });
            }
          }
        }

        if (typeof res.data.sectors !== "undefined" && res.data.sectors.data) {
          if (history.location.pathname === "/reports/post16-eal") {
            if (res.total_student_count - res.data.sectors.total === 0) {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: 0,
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "eal"
              });
            } else {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.sectors.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "eal"
              });
            }
          } else if (history.location.pathname === "/reports/post16-lac") {
            if (res.total_student_count - res.data.sectors.total === 0) {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: 0,
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "looked_after"
              });
            } else {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.sectors.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "looked_after"
              });
            }
          } else if (history.location.pathname === "/reports/post16-send") {
            if (res.total_student_count - res.data.sectors.total === 0) {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: 0,
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "sen_status"
              });
            } else {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.sectors.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "sen_status"
              });
            }
          } else if (history.location.pathname === "/reports/post16-bursary") {
            if (res.total_student_count - res.data.sectors.total === 0) {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: 0,
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "16_19_bursary"
              });
            } else {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.sectors.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors",
                report_filter_field: "16_19_bursary"
              });
            }
          } else {
            if (res.total_student_count - res.data.sectors.total === 0) {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: 0,
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors"
              });
            } else {
              res.data.sectors.data.push({
                name: "No data",
                no: res.total_student_count - res.data.sectors.total,
                percent: Math.round(
                  ((res.total_student_count - res.data.sectors.total) /
                    res.total_student_count) *
                    100
                ),
                filter_key: res.data.sectors.data[0].filter_key,
                filter_column_key: "sectors"
              });
            }
          }
        }
      })
      .catch(() => {
        showDefaultReportData();
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  // Todo: remove till BE doesn't create API's till that default data will be used
  const showDefaultReportData = () => {
    if (
      Routes.REPORTS.POST_SETP_GURANTEE === history.location.pathname ||
      Routes.REPORTS.POST_Y12_DASHBOARD === history.location.pathname ||
      Routes.REPORTS.POST_Y13_DASHBOARD === history.location.pathname
    ) {
      const newData = data;
      newData.rag_after = ragAfter;
      setPayload({ ...newData });
    } else {
      const newData = data;
      delete newData.rag_after;
      setPayload({ ...newData });
    }
  };

  useEffect(() => {
    if (user && user.school_id && yearsGroup) {
      getReportDetails({
        school_id: user.school_id,
        year_group: yearsGroup
      });
    } else if (user && user.school_id) {
      getReportDetails({
        school_id: user.school_id
      });
    } else if (yearsGroup) {
      getReportDetails({
        year_group: yearsGroup
      });
    } else {
      getReportDetails();
    }
  }, [history.location.pathname]);

  const getTitle = () => {
    const path = reports.find(r => r.path === history.location.pathname);
    return path.name || "";
  };

  return (
    <div className="content-panel">
      <PageMetaTags title={getTitle()} />
      <h1 className="page-heading"> {getTitle()}</h1>
      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <div className="row justify-content-center align-content-center mb-4">
            <h2 className="page-sub-heading">
              Number of students in year group:{" "}
              {(response && response.total_student_count) || 0}
            </h2>
          </div>
          <div className="row">
            {labelRagLive ? (
              <div className="col-12 col-lg-6 mt-4">
                <PieChart
                  chartData={piedemoRagLive}
                  colors={pieRagAfterColor}
                  chartLabel={labelRagLive}
                />
              </div>
            ) : (
              <></>
            )}
            {labelRagbefore ? (
              <div className="col-12 col-lg-6 mt-4">
                <PieChart
                  chartData={piedemoRagBefore}
                  colors={pieRagBeforeColor}
                  chartLabel={labelRagbefore}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {payload && (
            <Row gutter={[24, 0]}>
              <Col xl={12} lg={12} md={24} sm={24}>
                {payload.rag_after && (
                  <div className="">
                    <h3 className="labelstyle">Live RAG</h3>
                    <Table
                      size="small"
                      bordered
                      showHeader={false}
                      columns={ragcols}
                      rowKey={record => record.id}
                      dataSource={payload.rag_after.data}
                      pagination={false}
                      summary={() => (
                        <TableSummary
                          total={response.total_student_count || 0}
                        />
                      )}
                    />
                  </div>
                )}

                {payload.rag_after && (
                  <Divider style={{ background: "#c0c0c0" }} />
                )}
                <div className="mt-4">
                  <h3 className="labelstyle">{payload.destinations.name}</h3>
                  <Table
                    bordered
                    size="small"
                    showHeader={false}
                    columns={cols}
                    dataSource={payload.destinations.data}
                    pagination={false}
                    summary={() => (
                      <TableSummary total={response.total_student_count || 0} />
                    )}
                  />
                </div>
                <div className="mt-4">
                  <h3 className="labelstyle">{payload.intended_level.name}</h3>
                  <Table
                    bordered
                    size="small"
                    showHeader={false}
                    columns={cols}
                    dataSource={payload.intended_level.data}
                    pagination={false}
                    summary={() => (
                      <TableSummary total={response.total_student_count || 0} />
                    )}
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={24} sm={24}>
                {payload.rag_before && (
                  <div className="">
                    <h3 className="labelstyle">Post 16 Questionnaire RAG</h3>
                    <Table
                      size="small"
                      bordered
                      showHeader={false}
                      columns={ragcols}
                      dataSource={payload.rag_before.data}
                      pagination={false}
                      summary={() => (
                        <TableSummary
                          total={response.total_student_count || 0}
                        />
                      )}
                    />
                  </div>
                )}
                {payload.rag_before && (
                  <Divider style={{ background: "#c0c0c0" }} />
                )}
                <div className="mt-4">
                  <h3 className="labelstyle">{payload.sectors.name}</h3>
                  <Table
                    bordered
                    size="small"
                    showHeader={false}
                    columns={cols}
                    dataSource={payload.sectors.data}
                    pagination={false}
                    summary={() => (
                      <TableSummary total={response.total_student_count || 0} />
                    )}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default Post16Reports;
