import { callApi } from "../utils/apiUtils";
import common from "./endpoints/common";

export const getStudentsList = ({ body, query }) =>
  callApi({ uriEndPoint: common.getStudentsList.v1, body, query });

export const getAdvisorsList = ({ body, query }) =>
  callApi({ uriEndPoint: common.getAdvisorsList.v1, body, query });

export const getTeachersList = ({ body, query }) =>
  callApi({ uriEndPoint: common.getTeachersList.v1, body, query });

export const createAdviserOffDays = ({ body, query }) =>
  callApi({ uriEndPoint: common.createAdviserOffDays.v1, body, query });

export const getLocationList = ({ body, query }) =>
  callApi({ uriEndPoint: common.getLocationList.v1, body, query });

export const getUserDetailsDt = ({ pathParams }) =>
  callApi({ uriEndPoint: common.getUserDetailsDt.v1, pathParams });

export const uploadImportSheet = body =>
  callApi({ uriEndPoint: common.uploadImportSheet.v1, body });

export const verifyTwoFactor = body =>
  callApi({ uriEndPoint: common.verifyTwoFactor.v1, body });

export const updateActionCommonDetails = body =>
  callApi({ uriEndPoint: common.updateActionCommonDetails.v1, body });

export const getActionCommonDetails = ({ query }) =>
  callApi({ uriEndPoint: common.getActionCommonDetails.v1, query });

export const getRevisionDatesVersions = body =>
  callApi({ uriEndPoint: common.getRevisionDatesVersions.v1, body });

export const getRevisionDateActionPlanDetails = body =>
  callApi({ uriEndPoint: common.getRevisionDateActionPlanDetails.v1, body });

export const getAppointmentType = ({ query }) =>
  callApi({ uriEndPoint: common.getAppointmentType.v1, query });

export const getSchoolAppointmentLocations = ({ query }) =>
  callApi({ uriEndPoint: common.getSchoolAppointmentLocations.v1, query });

export const createLocation = ({ body }) =>
  callApi({ uriEndPoint: common.createLocation.v1, body });

export const downloadPdf = ({ pathParams }) =>
  callApi({ uriEndPoint: common.downloadPdf.v1, pathParams });

export const generateChat = ({ body }) => {
  return callApi({ uriEndPoint: common.generateChat.v1, body });
};

export const downloadChat = ({ body }) => {
  return callApi({ uriEndPoint: common.downloadChat.v1, body });
};

export const clearStudentListFilter = ({ query }) => {
  return callApi({ uriEndPoint: common.clearStudentListFilter.v1, query });
};

export const getYearsList = () => {
  return callApi({ uriEndPoint: common.getYearsList.v1 });
};

export const exportStudents = body =>
  callApi({ uriEndPoint: common.exportStudents.v1, body });

export const manageStudents = body =>
  callApi({ uriEndPoint: common.manageStudents.v1, body });
