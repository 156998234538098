import { callApi } from "../utils/apiUtils";
import appointment from "./endpoints/appointment";

export const getAppointmentSlots = ({ body }) =>
  callApi({ uriEndPoint: appointment.getAppointmentSlots.v1, body });

export const createAppointment = ({ body }) =>
  callApi({ uriEndPoint: appointment.createAppointment.v1, body });

export const createGroupCallAppointment = ({ body }) =>
  callApi({ uriEndPoint: appointment.createGroupCallAppointment.v1, body });

export const getAppointments = ({ body, query }) =>
  callApi({ uriEndPoint: appointment.getAppointments.v1, body, query });

export const getAppointmentTimingsForCalenderRange = ({ query }) =>
  callApi({
    uriEndPoint: appointment.getAppointmentTimingsForCalenderRange.v1,
    query
  });

export const getAppointmentsHistory = ({ body, query }) =>
  callApi({ uriEndPoint: appointment.getAppointmentsHistory.v1, body, query });

export const getTranscript = ({ pathParams }) => {
  return callApi({ uriEndPoint: appointment.getTranscript.v1, pathParams });
};

export const showAdviserSettings = () =>
  callApi({ uriEndPoint: appointment.showAdviserSettings.v1 });

export const createAdviserSettings = ({ body }) =>
  callApi({ uriEndPoint: appointment.createAdviserSettings.v1, body });

export const updateAdviserSettings = ({ body }) =>
  callApi({ uriEndPoint: appointment.updateAdviserSettings.v1, body });

export const getAdvisorsDayOff = ({ body, query }) =>
  callApi({ uriEndPoint: appointment.showAdviserDayOff.v1, body, query });

export const updateAdvisorsDayOff = ({ body }) =>
  callApi({ uriEndPoint: appointment.editAdviserDayOff.v1, body });

export const deleteAdvisorsDayOff = ({ body }) =>
  callApi({ uriEndPoint: appointment.deleteAdviserDayOff.v1, body });

export const deleteAppointment = ({ body }) =>
  callApi({ uriEndPoint: appointment.deleteAppointment.v1, body });

export const updateAppointment = ({ body }) =>
  callApi({ uriEndPoint: appointment.updateAppointment.v1, body });

export const videoCall = ({ body }) =>
  callApi({ uriEndPoint: appointment.videoCall.v1, body });

export const voiceCall = ({ body }) =>
  callApi({ uriEndPoint: appointment.voiceCall.v1, body });

export const appointmentDetail = ({ body }) =>
  callApi({ uriEndPoint: appointment.appointmentDetail.v1, body });

export const liveChat = ({ body }) =>
  callApi({ uriEndPoint: appointment.liveChat.v1, body });

export const startRecording = ({ body }) =>
  callApi({ uriEndPoint: appointment.startRecording.v1, body });

export const stopRecording = ({ body }) =>
  callApi({ uriEndPoint: appointment.stopRecording.v1, body });

export const showAdviserDaysOff = ({ body }) => {
  return callApi({ uriEndPoint: appointment.showAdviserDaysOff.v1, body });
};

export const getStudentAppointmentsHistory = ({ body, query }) =>
  callApi({
    uriEndPoint: appointment.getStudentAppointmentsHistory.v1,
    body,
    query
  });
