import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer, Navigate, Views } from "react-big-calendar";
import moment from "moment";
import { notification } from "antd";
import { withContext } from "Context";
import classNames from "classnames";
import BookAppointmentModal from "../BookAppointmentModal";
import {
  ADVISER,
  SCHOOL_ADMIN,
  STUDENT
} from "../../../../constants/rolesSubtypes";
import {
  getAppointments,
  getAppointmentTimingsForCalenderRange
} from "../../../../services/appointment";
import { MomentDateFormats } from "../../../../constants";

const localizer = momentLocalizer(moment);

function Event({ event }) {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
        padding: 8
      }}
    >
      <div>
        <strong style={{ color: "#001940", fontSize: "13px" }}>
          {event.title}
        </strong>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column"
        }}
      >
        <div style={{ color: "#5B5B5B", fontSize: "13px" }}>
          {localizer.format(
            event.start,
            MomentDateFormats.CustomDateFormat.hourMinute
          )}
        </div>
        <div style={{ color: "#5B5B5B", fontSize: "13px" }}>
          {localizer.format(
            event.end,
            MomentDateFormats.CustomDateFormat.hourMinute
          )}
        </div>
      </div>
    </span>
  );
}

function EventAgenda({ event }) {
  return (
    <span>
      <em style={{ color: "magenta" }}>{event.title}</em>
      <p>{event.desc}</p>
    </span>
  );
}

const customDayPropGetter = date => {
  return {
    style: {
      border: "none"
    }
  };
};

const customSlotPropGetter = date => {
  return {
    style: { background: "#fff" }
  };
};

const AppointmentCalendar = ({ user, studentView }) => {
  const calendarRef = useRef();
  const [currentView, setCurrentView] = useState("day");
  const [selectedEvent, setSelectedEvent] = useState();
  const [events, setEvents] = useState();
  const [appointmentTimings, setAppointmentTimings] = useState();

  const getFilterCol = () => {
    switch (user.role_slug) {
      case ADVISER:
        return "adviser_id";
      case STUDENT:
        return "student_id";
      case SCHOOL_ADMIN:
        return "school_admin";
      default:
        return "adviser_id";
    }
  };

  const getAppointmentsList = query => {
    getAppointments({
      body: {
        sort_column: "id",
        page: 1,
        sort_order: "asc",
        page_limit: 1000,
        filter_column: getFilterCol()
        // date_start: "2021-09-08T00:00:00Z",
        // date_end: moment.utc().format(),
      },
      query: query || {}
    })
      .then(response => setEvents(response.appointments))
      .catch(error => console.log(error));
  };

  const getAppointmentsMain = () => {
    if (user && user.school_id) {
      getAppointmentsList({ school_id: user.school_id });
    } else {
      getAppointmentsList();
    }
  };

  useEffect(() => {
    getAppointmentsMain();
  }, [user.school_id]);

  useEffect(() => {
    getAppointmentTimingsForCalenderRange({ query: {} })
      .then(response => {
        if (response.success) {
          setAppointmentTimings(response);
        } else {
          notification.error({
            message: "Failed to fetch Appointment Timings For Calender"
          });
        }
        setEvents(response.appointmentTimings);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    getAppointmentsMain();
  }, []);

  const renderCalenderView = () => {
    return (
      <>
        {selectedEvent && (
          <BookAppointmentModal
            open={!!selectedEvent}
            getAppointments={getAppointmentsMain}
            onClose={() => setSelectedEvent()}
            event={selectedEvent}
            userId={
              user.role_slug === ADVISER
                ? selectedEvent.student_id
                : selectedEvent && selectedEvent.adviser_id
            }
            advisor={
              user.role_slug === ADVISER
                ? user
                : { id: (selectedEvent && selectedEvent.adviser_id) || "" }
            }
          />
        )}
        <Calendar
          localizer={localizer}
          events={(events || []).map(event => {
            return {
              ...event,
              start: new Date(event.start),
              end: new Date(event.end)
            };
          })}
          ref={calendarRef}
          startAccessor="start"
          onSelectEvent={values => setSelectedEvent(values)}
          endAccessor="end"
          timeslots={1}
          //formats={{ timeGutterFormat: MomentDateFormats.CustomDateFormat.hourMinute }}
          min={
            appointmentTimings ? new Date(appointmentTimings.start_date) : null
          }
          max={
            appointmentTimings ? new Date(appointmentTimings.end_date) : null
          }
          onNavigate={getAppointmentsMain}
          defaultView={Views.DAY}
          onView={view => setCurrentView(view)}
          dayPropGetter={customDayPropGetter}
          slotPropGetter={customSlotPropGetter}
          style={{ width: "100%" }}
          components={{
            event: Event,
            agenda: {
              event: EventAgenda
            },
            day: () => <div></div>,
            toolbar: h => {
              return (
                <div className="appointment-header">
                  <div className="o-pagination flex items-center">
                    <h2 className="sub-heading">
                      {moment(moment().format("YYYY/MM/DD")).isSame(
                        moment(h.date).format("YYYY/MM/DD")
                      )
                        ? "Today"
                        : h.label}
                    </h2>
                    <div className="pagination">
                      <a
                        onClick={() => {
                          h.onNavigate(Navigate.PREVIOUS, moment(h.date));
                        }}
                        href
                        className="prev"
                      >
                        <i className="fas fa-chevron-left" />
                      </a>
                      <a
                        href
                        onClick={() => {
                          h.onNavigate(Navigate.NEXT, moment(h.date));
                        }}
                        className="next"
                      >
                        <i className="fas fa-chevron-right" />
                      </a>
                    </div>
                  </div>
                  <div className="o-view">
                    <div
                      onClick={() => h.onView("day")}
                      className={classNames(
                        "list-view ",
                        h.view === "day" && "active"
                      )}
                    >
                      <i className="fas fa-list-ul" />
                    </div>
                    <div
                      onClick={() => h.onView("week")}
                      className={classNames(
                        "calendar-view",
                        h.view === "week" && "active"
                      )}
                    >
                      <i className="far fa-calendar-alt" />
                    </div>
                  </div>
                </div>
              );
            }
          }}
        />
      </>
    );
  };

  if (studentView) {
    return renderCalenderView();
  }

  return (
    <div
      className={classNames(
        "profile-wrapper p-0",
        currentView === "day" ? "calendar-header" : ""
      )}
    >
      {renderCalenderView()}
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), AppointmentCalendar);
