import { defaults } from "./default";

const starExercise = {
  getPreviousExercises: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/previous-exercises"
    }
  },
  getPreviousSections: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/previous-section"
    }
  },
  getStarExerciseDetailByExerciseId: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/star-exercise-detail"
    }
  },
  submitStarExercise: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/star-exercise"
    }
  },
  starExerciseChartUpload: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/star-exercise-chart-upload"
    }
  }
};

export default starExercise;
