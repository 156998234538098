import React, { useState } from "react";
import HorizontalTimeline from "react-horizontal-timeline";

const PastResultTimeline = ({ timelineData, onTimelineIndexChange }) => {
  const [value, setValue] = useState(0);

  return (
    <HorizontalTimeline
      styles={{ background: "#fff", foreground: "#7b9d6f", outline: "#dfdfdf" }}
      index={value}
      indexClick={index => {
        setValue(index);
        onTimelineIndexChange(index);
      }}
      values={timelineData}
    />
  );
};

export { PastResultTimeline };
