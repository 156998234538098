import { defaults } from "./default";

const destination = {
  saveks5Studies: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/ks5-studies"
    }
  },
  getks5Studies: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/ks5-studies"
    }
  },
  saveMayReturn: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/may-return-live-dashboard"
    }
  },
  getMayReturn: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/may-return-live-dashboard"
    }
  },
  saveSubjectOptions: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/subject-option-ucas"
    }
  },
  getSubjectOptions: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/subject-option-ucas"
    }
  },
  savePost16Question: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/post-16-questionnaire"
    }
  },
  getPost16Question: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/post-16-questionnaire"
    }
  },
  saveOctoberActivity: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/october-activity-survey"
    }
  },
  getOctoberActivity: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/october-activity-survey"
    }
  },
  getInformationNotes: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/information-notes"
    }
  },
  saveInformationNotes: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/information-notes"
    }
  },
  getKS4Data: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/ks4-data"
    }
  },
  saveKS4Data: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/ks4-data"
    }
  },
  getKS4Questionnaire: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/ks4-questionnaire"
    }
  },
  saveKS4Questionnaire: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/ks4-questionnaire"
    }
  },
  getSeptemberGuarantee: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/september-guarantee"
    }
  },
  saveSeptemberGuarantee: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/september-guarantee"
    }
  },
  getStudentData: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/student-data"
    }
  },
  saveStudentData: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/student-data"
    }
  },
  getY11Dashboard: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/y11-dashboard"
    }
  },
  saveY11Dashboard: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/y11-dashboard"
    }
  },
  getAdvisorsDropdown: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-advisor-dropdown"
    }
  },
  inlineUpdate: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/destination-tracker/inline-update"
    }
  },
  getSeptemberProvider: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/september-guarantee/provider"
    }
  },
  getOctoberProvider: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/october-guarantee/provider"
    }
  },
  getDestinationDropdown: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/destination-tracker/destination-dropdown"
    }
  }
};

export default destination;
