import { mainMiddleware } from "../middleware";
import userReducer from "./userReducer";
import appReducer from "./appReducer";
import subjectReducer from "./subjectReducer";
import usersReducer from "./usersReducer";
import taskReducer from "./taskReducer";
import studentListReducer from "./studentListReducer";

export const mainReducer = ({ app, user, subject, users, tasks }, action) => {
  const { appMiddleware, subjectMiddleware, authMiddleware } = mainMiddleware(
    action
  );
  return {
    app: appReducer(app, appMiddleware || action),
    user: userReducer(user, action),
    subject: subjectReducer(subject, subjectMiddleware || action),
    users: usersReducer(users, authMiddleware || action),
    tasks: taskReducer(tasks, appMiddleware || action),
    studentsList: studentListReducer(app, appMiddleware || action)
  };
};
