import React, { useEffect, useState } from "react";
import { Button, Form, notification, Popconfirm, Steps } from "antd";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { PageMetaTags } from "../../../../common/PageMetaTags";
import { Questionnaire } from "../Questionnaire/Questionnaire";
import { submitStarExercise } from "../../../../../services/starexercise";
import { useHistory } from "react-router-dom";
import { Categories, StatusMapping } from "./ExerciseQuestions";
import "./StarExerciseWizard.scss";

const { Step } = Steps;

const steps = Object.keys(Categories).map(categoryKey => {
  return {
    title: Categories[categoryKey].category,
    component: props => {
      return (
        <Questionnaire
          name={Categories[categoryKey].name}
          title={Categories[categoryKey].title}
          options={Categories[categoryKey].options}
          category={Categories[categoryKey].category}
          {...props}
        />
      );
    }
  };
});

const StarExerciseWizard = () => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formPayload, setFormPayload] = useState({});
  const [verticalSteps, setVerticalSteps] = useState(StatusMapping);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let status = "error";
    if (formPayload.hasOwnProperty(verticalSteps[current].key)) {
      status = "finish";
    }
    setVerticalSteps({
      ...verticalSteps,
      [current]: {
        ...verticalSteps[current],
        status: status
      }
    });
  }, [current, formPayload]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const setFormFields = (name, value) => {
    setFormPayload({
      ...formPayload,
      [name]: value
    });
  };

  const showPopConfirmDialog = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOnConfirmed = () => {
    // Note:
    //  We are using alternative way to get form fields using props.callback -> "setFormFields" is passed to
    //  child component Questionnaire
    //  Note: => form.item is only returning last step value and we need to send complete payload to BE:

    const hasAllQuestionsAnswered = validateQuestionnaire();

    if (hasAllQuestionsAnswered) {
      setConfirmLoading(true);
      submitStarExercise({
        body: {
          ...formPayload
        }
      })
        .then(response => {
          if (response.success) {
            if (response.star_exercise_id) {
              // Note: Added for UX to show spinner as something is going on:
              setTimeout(() => {
                notification.success({
                  message: "Exercise submitted successfully"
                });
                history.push(
                  `/starexercise/result/${response.star_exercise_id}`
                );
              }, 3000);
            } else {
              // In case exercise id is not returned
              history.push("/starexercise/dashboard");
            }
          } else {
            notification.error({ message: "Oops! Failed to submit exercise" });
          }
        })
        .catch(error => console.log("StarExerciseWizard.submitExercise", error))
        .finally(() => {
          // Note: Added for UX to show spinner as something is going on:
          setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
          }, 3000);
        });
    } else {
      notification.error({
        message: "Please complete all questions before submitting your answers"
      });
    }
  };

  const validateQuestionnaire = () => {
    const totalCategories = Object.keys(Categories);
    return totalCategories.every(key => {
      return !!formPayload[Categories[key].name];
    });
  };

  return (
    <HeaderWrapper
      title="Resources - Star Exercise"
      image={require("../../../../../assets/icons/header/icon-cogs.png")}
    >
      <PageMetaTags title="Resources - Star Exercise" />

      <div className="row bg-white ml-2 mr-2">
        <div className="col-12 col-lg-5 p-12 categories-section">
          <h5>Star Categories</h5>
          <br />
          <Steps direction="vertical" current={current}>
            {Object.keys(verticalSteps).map((item, index) => {
              return (
                <Step
                  key={index}
                  title={verticalSteps[item].category}
                  status={verticalSteps[item].status}
                  description={`Completed ${current} of ${
                    Object.keys(verticalSteps).length
                  }`}
                />
              );
            })}
          </Steps>
        </div>
        <div className="col-12 col-lg-7 p-12">
          <Form form={form}>
            <div className="steps-content">
              {steps[current].component({ setFormFields: setFormFields })}
            </div>

            <div className="col-12 steps-action mt-10">
              {current > 0 && (
                <Button
                  type="default"
                  style={{ margin: "0 8px" }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Popconfirm
                  title="Click confirm to submit your choices"
                  visible={visible}
                  onConfirm={handleOnConfirmed}
                  okText={"Confirm"}
                  cancelText={"Review Answers"}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={handleCancel}
                >
                  <Button
                    disabled={confirmLoading}
                    type="primary"
                    htmlType="button"
                    onClick={showPopConfirmDialog}
                  >
                    Submit Answers
                  </Button>
                </Popconfirm>
              )}
            </div>
          </Form>
        </div>
      </div>

      <br />
      <br />
    </HeaderWrapper>
  );
};

export { StarExerciseWizard };
