/**
 * Order of Object is Important!!
 * @param mergeObj
 * @param mergeObjWith
 */
function deepMerge(mergeObj, mergeObjWith) {
  if (mergeObjWith && Object.keys(mergeObjWith).length > 0) {
    const newData = JSON.parse(JSON.stringify(mergeObj));
    Object.keys(mergeObjWith).forEach(key => {
      if (newData.hasOwnProperty(key)) {
        newData[key] = {
          ...newData[key],
          ...mergeObjWith[key]
        };
      } else {
        newData[key] = mergeObjWith[key]; // assign any new prop from BE
      }
    });
    return newData;
  } else {
    return mergeObj || {};
  }
}

function deepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

const TYPE = {
  date: "date",
  select: "select",
  textArea: "textArea",
  input: "input",
  switch: "switch",
  radio: "radio"
};

const INTERESTS = [
  { value: "Interested", label: "Interested" },
  { value: "Not Sure", label: "Not Sure" },
  { value: "Not at all interested", label: "Not at all interested" }
];

const GCSE_OPTIONS = [
  { value: "School Sixth Form", label: "School Sixth Form" },
  { value: "Other School Sixth Form", label: "Other School Sixth Form" },
  { value: "Sixth Form College", label: "Sixth Form College" },
  { value: "FE College", label: "FE College" },
  { value: "Apprenticeship", label: "Apprenticeship" },
  {
    value: "Traineeship / Study Programme",
    label: "Traineeship / Study Programme"
  },
  { value: "Employment with Training", label: "Employment with Training" },
  { value: "Employment", label: "Employment" },
  { value: "Unknown", label: "Unknown" },
  { value: "NEET", label: "NEET" },
  { value: "Other", label: "Other" }
];

const LEVELS = [
  { value: "3", label: "3" },
  { value: "2", label: "2" },
  { value: "1", label: "1" },
  { value: "ENT", label: "Entry" }
];

const SECTORS = [
  { value: "A Levels", label: "A Levels" },
  {
    value: "Animal Care / Land Based Studies",
    label: "Animal Care / Land Based Studies"
  },
  {
    value: "Art, Design & Creative Media",
    label: "Art, Design & Creative Media"
  },
  { value: "Business / Financial", label: "Business / Financial" },
  { value: "Construction", label: "Construction" },
  { value: "Education & Childcare", label: "Education & Childcare" },
  { value: "Engineering", label: "Engineering" },
  { value: "Hair & Beauty", label: "Hair & Beauty" },
  { value: "Health & Social / Science", label: "Health & Social / Science" },
  { value: "Hospitality & Catering", label: "Hospitality & Catering" },
  { value: "I don't know / Unknown", label: "I don't know / Unknown" },
  { value: "ICT / Computing", label: "ICT / Computing" },
  { value: "Performing Arts / Music", label: "Performing Arts / Music" },
  {
    value: "Public Services / Armed Forces",
    label: "Public Services / Armed Forces"
  },
  { value: "Sport & Leisure", label: "Sport & Leisure" },
  { value: "Travel & Tourism", label: "Travel & Tourism" },
  { value: "Other", label: "Other" }
];

function isObj(data) {
  return typeof data === "object" && !Array.isArray(data) && data !== null;
}

function getKeyValuePair(data) {
  return Object.keys(data).reduce((prev, next) => {
    if (isObj(data[next])) {
      return { ...prev, [next]: data[next].value };
    } else {
      return { ...prev, [next]: data[next] };
    }
  }, {});
}

function isAnyFieldEditable(data) {
  const IGNORE_FIELDS_NOT_USED_BY_FE = [
    "dt_type",
    "form_visible",
    "id",
    "profile_id",
    "provider_id"
  ];
  return Object.keys(data)
    .filter(key => !IGNORE_FIELDS_NOT_USED_BY_FE.includes(key))
    .some(key => {
      if (typeof data[key] === "object") {
        return data[key] && data[key].editable;
      } else {
        return false;
      }
    });
}

export {
  deepMerge,
  deepCopy,
  getKeyValuePair,
  isAnyFieldEditable,
  INTERESTS,
  GCSE_OPTIONS,
  SECTORS,
  TYPE,
  LEVELS
};
