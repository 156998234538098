import * as Types from "../types";

const authMiddleware = ({ type, payload }) => {
  switch (type) {
    case Types.SIGN_IN:
    case Types.SIGN_UP:
      localStorage.setItem("user", JSON.stringify(payload));
      break;
    case Types.SIGN_OUT:
      localStorage.removeItem("user");
      localStorage.removeItem("selected_school");
      localStorage.removeItem("attached_school");
      return { type: Types.CLEAR_CONTEXT };
    default:
      break;
  }
};

export default authMiddleware;
