import React from "react";
import moment from "moment";

import {
  ADVISER,
  APPOINTMENT_TYPES,
  MomentDateFormats,
  STUDENT
} from "../../../../../constants";

const AppointmentConfirmationStatus = ({ user, appointmentConfirmation }) => {
  const renderAppointmentBookingStatus = (status, message) => (
    <div className="success-message">
      <span className={status ? "success-icon" : "error-icon"}>
        {status ? (
          <i className="fas fa-check-circle" />
        ) : (
          <i className="fas fa-times" />
        )}
      </span>
      <h3>{message}</h3>
    </div>
  );

  const renderBookedAppointmentDetails = details => {
    return (
      <div className="col-md-12 col-lg-6">
        {user && user.role_slug === ADVISER && (
          <span>
            Appointment for {details.student_name} by {details.advisor_name}{" "}
          </span>
        )}
        {user && user.role_slug === STUDENT && (
          <span>
            Appointment is booked by {details.student_name} for{" "}
            {details.advisor_name}{" "}
          </span>
        )}
        <br />
        {moment(details.appointment_date).format(
          MomentDateFormats.LongDateFormat.LLLL
        )}
        <br />
        {details.duration} minutes
        <br />
        <span className="text-capitalize">
          {APPOINTMENT_TYPES[details.appointment_type].name}
        </span>{" "}
        <br />
      </div>
    );
  };

  return (
    <div className="row align-items-center">
      <div className="col-md-12 col-lg-6 border-right-side">
        {appointmentConfirmation.success
          ? renderAppointmentBookingStatus(
              appointmentConfirmation.success,
              appointmentConfirmation.message ||
                "Your appointment has been booked successfully"
            )
          : renderAppointmentBookingStatus(
              appointmentConfirmation.success,
              appointmentConfirmation.message ||
                "Something went wrong while booking an appointment"
            )}
      </div>

      {appointmentConfirmation &&
        appointmentConfirmation.appointment_details &&
        renderBookedAppointmentDetails(
          appointmentConfirmation.appointment_details
        )}
    </div>
  );
};

export { AppointmentConfirmationStatus };
