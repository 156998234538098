import React, { useState } from "react";
import PropTypes from "prop-types";
import { withContext } from "Context";
import { PageMetaTags } from "Common";
import { signInSuccessAction } from "Actions/authActions";
import { Link, useHistory } from "react-router-dom";
import { Form, notification } from "antd";
import { updatePassword } from "../../../../services/user";
import { getPageQuery } from "../../../../utils/apiUtils";

const PasswordUpdate = ({ signInSuccess }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const query = getPageQuery();
  if (!query.token && !query.email.replace(" ", "+")) {
    history.push("/login");
  }

  const onFinish = values => {
    setLoading(true);
    updatePassword({
      body: {
        ...values,
        passwordToken: query.token,
        email: query.email.replace(" ", "+")
      }
    })
      .then(() => {
        setLoading(false);
        notification.success({
          message:
            "Your password has been updated. Please try logging in again."
        });
      })
      .catch(err => {
        setLoading(false);
        if ((err && err.status === 422) || (err.data && err.data.message)) {
          notification.error({ message: err.data.message });
        } else {
          notification.error({
            message: "There was a problem resetting your password."
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const renderUpdateForm = () => {
    return (
      <Form onFinish={onFinish} autoComplete="off">
        <div className="form-group-custom">
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password"
              }
            ]}
          >
            <input
              type="password"
              className="input-style"
              placeholder="Password"
            />
          </Form.Item>
        </div>
        <div className="form-group-custom">
          <Form.Item
            name="password_confirmation"
            rules={[
              {
                required: true,
                message: "Please confirm your password"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                }
              })
            ]}
          >
            <input
              type="password"
              className="input-style"
              placeholder="Confirm Password"
            />
          </Form.Item>
        </div>
        <div className="form-footer">
          <input
            type="submit"
            disabled={loading}
            className="button-style button-style-green-btm update-password button-style-fullwidth-button"
            value={loading ? "Loading..." : "Update Password"}
          />
        </div>
      </Form>
    );
  };

  const STYLE_FORGET_IMAGE = {
    height: "100%",
    width: "100%",
    objectFit: "fill"
  };
  const STYLE_LOGIN_IMAGE = {
    height: "100%",
    width: "100%",
    objectFit: "fill"
  };

  return (
    <React.Fragment>
      <PageMetaTags title="Update Password" />
      <div className="container-fluid p-0 overflow-hidden">
        <div className="row">
          <div className="col-12 col-md-7">
            <img
              style={{ ...STYLE_LOGIN_IMAGE }}
              src={require("../../../../assets/images/iProgressLogin_1920_7cblock.jpg")}
              alt="login"
            />
          </div>
          <div className="col-12 col-md-5">
            <div className="login-holder">
              <div className="login-inner text-center">
                <figure>
                  <img
                    style={{ ...STYLE_LOGIN_IMAGE }}
                    src={require("../../../../assets/images/logo-small.png")}
                    alt="logo"
                  />
                </figure>
                <h1 className="page-heading">Reset Password</h1>
                <h4 className="update-password-h4">
                  {query.email.replace(" ", "+")}
                </h4>
                {renderUpdateForm()}
              </div>
              <Link to="/login" className="forget-link">
                Back to login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PasswordUpdate.propTypes = {
  signInSuccess: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withContext(
  ([, dispatch]) => ({
    // actions
    signInSuccess: data => signInSuccessAction(data, dispatch)
  }),
  PasswordUpdate
);
