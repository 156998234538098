import React, { useState } from "react";
import { PageMetaTags } from "Common";
import { withContext } from "Context";
import { Button } from "antd";
import StudentAppointments from "../StudentAppointments";
import AppointmentCalendar from "../AppointmentCalendar/AppointmentCalendar";
import GroupCallAppointmentModal from "./GroupCallAppointmentModal";
import { ADVISER, STUDENT } from "../../../../constants";
import RoleCheck from "../../../../components/helpers/RoleCheck";

const ViewAppointments = ({ user }) => {
  const [showModal, setShowModal] = useState(false);

  const showGroupCallModal = () => {
    setShowModal(true);
  };

  return (
    <div data-cy="appointments-page">
      <PageMetaTags title="Appointments" />
      {user.role_slug === STUDENT ? (
        <StudentAppointments user={user} />
      ) : (
        <div className="content-panel">
          <h1 className="page-heading">Appointments</h1>

          <RoleCheck authority={[ADVISER]} roleClass={"mb-4"}>
            {/* Temporarily hide create career cafe button */}
            {process.env.REACT_APP_API_URL ==
            "https://careersinc-api.herokuapp.com" ? (
              <Button type="primary" onClick={showGroupCallModal}>
                Create Careers Cafe Event
              </Button>
            ) : (
              ""
            )}
            {showModal && (
              <GroupCallAppointmentModal
                user={user}
                onCancel={() => setShowModal(false)}
              />
            )}
          </RoleCheck>

          <AppointmentCalendar />
        </div>
      )}
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), ViewAppointments);
