import { callApi } from "../utils/apiUtils";
import starExercise from "./endpoints/starexercise";

export const getPreviousExercises = ({ body }) => {
  return callApi({ uriEndPoint: starExercise.getPreviousExercises.v1, body });
};

export const getPreviousSections = ({ body }) => {
  return callApi({ uriEndPoint: starExercise.getPreviousSections.v1, body });
};

export const getStarExerciseDetailByExerciseId = ({ body }) => {
  return callApi({
    uriEndPoint: starExercise.getStarExerciseDetailByExerciseId.v1,
    body
  });
};

export const submitStarExercise = ({ body }) => {
  return callApi({ uriEndPoint: starExercise.submitStarExercise.v1, body });
};

export const starExerciseChartUpload = ({ body }) => {
  return callApi({
    uriEndPoint: starExercise.starExerciseChartUpload.v1,
    body
  });
};
