import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "antd";
import { getPreviousExercises } from "../../../../../services/starexercise";
import { MomentDateFormats } from "../../../../../constants";

const PastExercises = ({ onPastExerciseClick }) => {
  const [loading, setLoading] = useState(false);
  const [previousExercises, setPreviousExercises] = useState([]);
  const dataFormat = MomentDateFormats.CustomDateFormat.dayMonthYear;

  const columns = [
    {
      title: "Date",
      dataIndex: "completion_date",
      key: "completion_date"
    },
    {
      title: "Download",
      dataIndex: "id",
      key: "id",
      render: (value, record) => {
        return (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={"btn btn-yellow"}
            onClick={() => {
              onPastExerciseClick(record);
            }}
          >
            View Result <i className={"fa fa-download ml-2"} />
          </a>
        );
      }
    }
  ];

  useEffect(() => {
    setLoading(true);
    getPreviousExercises({})
      .then(response => {
        setLoading(false);
        if (response.success && response.previous_exercises.length > 0) {
          response.previous_exercises = response.previous_exercises.map(
            exercise => {
              return {
                ...exercise,
                completion_date: moment(exercise.completion_date).format(
                  dataFormat
                )
              };
            }
          );
          setPreviousExercises(response.previous_exercises);
        }
      })
      .catch(error => console.log("PastExercises.getPreviousExercises", error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex mt-2 mb-4">
        <img
          className={"w-14"}
          alt="Future"
          src={require("../../../../../assets/icons/startool/star-green.png")}
        />
        <h5 className={"mt-3 ml-4"}>Previous Exercises</h5>
      </div>
      <Table
        loading={loading}
        size="middle"
        bordered={true}
        dataSource={previousExercises}
        columns={columns}
      />
    </React.Fragment>
  );
};

export { PastExercises };
