import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ADVISER, Routes, SCHOOL_ADMIN, STUDENT, SUPER_ADMIN } from "Constants";
import { InformationAndNotes } from "./InformationAndNotes";
import { KS4Data } from "./KS4-Data";
import { KS4Questionnaire } from "./KS4-Questionnaire";
import { SeptemberGuarantee } from "./SeptemberGuarantee";
import { StudentData } from "./StudentData";
import { Y11Dashboard } from "./Y11Dashboard";
import Ks5Studies from "./Ks5Studies";
import MayReturn from "./MayReturn";
import Post16Quest from "./Post16Quest";
import OctoberActivity from "./OctoberActivity";
import SubjectOptionsUCAS from "./SubjectOptionsUCAS";
import { Tabs } from "antd";
import { withContext } from "Context";
import { destinationTracker } from "../../common/SideBar/routes";
import MyFuture from "./MyFuture";

const { TabPane } = Tabs;

const DestinationTracker = ({ match, user }) => {
  const routes = [
    {
      path: "/my-future",
      role: [STUDENT],
      component: MyFuture
    },
    {
      path: Routes.DESTINATION_TRACKER.BOTH.INFORMATION_NOTES,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: InformationAndNotes
    },
    {
      path: Routes.DESTINATION_TRACKER.PRE_16.KS4_DATA,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: KS4Data
    },
    {
      path: Routes.DESTINATION_TRACKER.BOTH.SEPTEMBER_GUARANTEE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: SeptemberGuarantee
    },
    {
      path: Routes.DESTINATION_TRACKER.BOTH.STUDENT_DATA,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: StudentData
    },
    {
      path: Routes.DESTINATION_TRACKER.PRE_16.Y11_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: Y11Dashboard
    },
    {
      path: Routes.DESTINATION_TRACKER.POST_16.KS5_STUDIES,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: Ks5Studies
    },
    {
      path: Routes.DESTINATION_TRACKER.POST_16.MAY_RETURN_LIVE_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: MayReturn
    },
    {
      path: Routes.DESTINATION_TRACKER.POST_16.POST_16_QUESTIONNAIRE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: Post16Quest
    },
    {
      path: Routes.DESTINATION_TRACKER.POST_16.SUBJECT_OPTIONS_UCAS,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: SubjectOptionsUCAS
    },
    {
      path: Routes.DESTINATION_TRACKER.BOTH.OCTOBER_ACTIVITY_SURVEY,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: OctoberActivity
    },
    {
      path: Routes.DESTINATION_TRACKER.PRE_16.KS4_QUESTIONNAIRE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN, STUDENT],
      component: KS4Questionnaire
    }
  ];

  const history = useHistory();

  /*
   Note:
   This HOC will allow to pass data to all routes instead of using
   `withContext` on each component to get redux store e.g user
   */
  const withUser = (WrappedComponent, rest) => {
    return props => {
      return <WrappedComponent {...props} {...rest} />;
    };
  };

  const renderUI = () => (
    <Switch>
      {routes.map(route => {
        if (route.role.includes(user.role_slug)) {
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={withUser(route.component, { user })}
            />
          );
        }
      })}
      {/*Redirect to First Screen*/}
      {user.role_slug !== STUDENT && (
        <Redirect to={Routes.DESTINATION_TRACKER.BOTH.INFORMATION_NOTES} />
      )}
    </Switch>
  );

  const query = new URLSearchParams(history.location.search);

  if (user.role_slug === STUDENT) {
    return renderUI();
  }

  return (
    <div className="tabs-dt">
      <Tabs
        defaultActiveKey={match.url}
        onChange={key =>
          history.push({ pathname: key, search: history.location.search })
        }
      >
        {destinationTracker[query.get("year_group")].map(
          ({ name, studentRoleName, path }) => (
            <TabPane
              tabKey={path}
              tab={<span className="pl-2">{studentRoleName || name}</span>}
              key={path}
            />
          )
        )}
      </Tabs>
      {renderUI()}
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), DestinationTracker);
