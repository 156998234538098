import { callApi } from "../utils/apiUtils";
import { compassreport } from "./endpoints/compassreport";

export const getBenchmark = ({ body, query }) =>
  callApi({ uriEndPoint: compassreport.getBenchmark.v1, body, query });

export const getBenchmarkReports = ({ query }) =>
  callApi({ uriEndPoint: compassreport.getBenchmarkReports.v1, query });

export const getBenchmarkSummaryReports = ({ query }) =>
  callApi({ uriEndPoint: compassreport.getBenchmarkSummaryReports.v1, query });
