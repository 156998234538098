import React, { useEffect, useState } from "react";
import { Skeleton, Table, Divider } from "antd";
import { useHistory } from "react-router";
import { cols, getPageTitle, mockResponse, rag_cols } from "../Shared/common";
import { PageMetaTags } from "../../../common";
import { TableSummary } from "../Shared/TableSummary";
import { getPre16Reports } from "../../../../services/report";
import { Routes } from "../../../../constants";
import { PieChart } from "../../Resources/StarExercise/AntDesignPlotCharts";

function Pre16Reports({ user }) {
  const [payload, setPayload] = useState();
  const [response, setResponse] = useState();
  const [piedemoRagLive, setPiedemoRagLive] = useState();
  const [piedemoRagBefore, setPiedemoRagBefore] = useState();
  const [pieRagBeforeColor, setRagBeforeColor] = useState();
  const [pieRagAfterColor, setRagAfterColor] = useState();
  const [labelRagLive, setlabelRagLive] = useState();
  const [labelRagbefore, setlabelRagbefore] = useState();
  const [getLoading, setGetLoading] = useState(false);
  const [pageHeading, setPageHeading] = useState("");
  const [getTotal, setTotal] = useState();
  const history = useHistory();
  const urlQuery = new URLSearchParams(history.location.search);
  const yearsGroup = useState(urlQuery.get("year_group"))[0];

  const getReportDetails = query => {
    setGetLoading(true);
    getPre16Reports({
      pathParams: { path: history.location.pathname },
      query: query || {}
    })
      .then(response => {
        if (response.success) {
          const { data } = response;
          setResponse(response);
          setPayload(data);

          setTotal(response.total_student_count);

          if (typeof data.rag_after !== "undefined" && data.rag_after.data) {
            var newrAfterList = [];
            var newrAfterColors = [];
            var colors = [
              "#00b050",
              "#ED9A76",
              "#ffbc6a",
              "#D79334",
              "#ff0000"
            ];
            for (var i = 0; i < data.rag_after.data.length; i++) {
              if (data.rag_after.data[i].sno) {
                if (data.rag_after.data[i].value !== 0) {
                  data.rag_after.data[i].sno =
                    "RAG " + data.rag_after.data[i].sno;
                  newrAfterList.push(data.rag_after.data[i]);
                  newrAfterColors.push(colors[i]);
                } else {
                  data.rag_after.data[i].sno =
                    "RAG " + data.rag_after.data[i].sno;
                }
              }
            }
            newrAfterList.push({
              sno: "No data",
              value:
                response.total_student_count - response.data.rag_after.total,
              name: "",
              filter_key: response.data.rag_after.data[0].filter_key,
              filter_column_key: "rag_after"
            });
            newrAfterColors.push("#833c0b");
            setPiedemoRagLive(newrAfterList);
            setRagAfterColor(newrAfterColors);
            setlabelRagLive("Live RAG");
          }
          if (typeof data.rag_before !== "undefined" && data.rag_before.data) {
            var newrBeforeList = [];
            var newrBeforeColors = [];
            var colors = [
              "#00b050",
              "#ED9A76",
              "#ffbc6a",
              "#D79334",
              "#ff0000"
            ];
            for (var i = 0; i < data.rag_before.data.length; i++) {
              if (data.rag_before.data[i].sno) {
                if (data.rag_before.data[i].value !== 0) {
                  data.rag_before.data[i].sno =
                    "RAG " + data.rag_before.data[i].sno;
                  newrBeforeList.push(data.rag_before.data[i]);
                  newrBeforeColors.push(colors[i]);
                } else {
                  data.rag_before.data[i].sno =
                    "RAG " + data.rag_before.data[i].sno;
                }
              }
            }
            newrBeforeList.push({
              sno: "No data",
              value:
                response.total_student_count - response.data.rag_before.total,
              name: "",
              filter_key: response.data.rag_before.data[0].filter_key,
              filter_column_key: "rag_before"
            });
            newrBeforeColors.push("#833c0b");
            setPiedemoRagBefore(newrBeforeList);
            setRagBeforeColor(newrBeforeColors);
            setlabelRagbefore("KS4 Questionnaire RAG");
          }

          if (
            typeof response.data.rag_after !== "undefined" &&
            response.data.rag_after.data
          ) {
            if (history.location.pathname === "/reports/fsm-dashboard") {
              response.data.rag_after.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_after.total,
                name: "",
                filter_key: response.data.rag_after.data[0].filter_key,
                filter_column_key: "rag_after",
                report_filter_field: "fsm_stud"
              });
            } else if (
              history.location.pathname === "/reports/send-dashboard"
            ) {
              response.data.rag_after.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_after.total,
                name: "",
                filter_key: response.data.rag_after.data[0].filter_key,
                filter_column_key: "rag_after",
                report_filter_field: "sen_status_stud"
              });
            } else if (history.location.pathname === "/reports/pp-dashboard") {
              response.data.rag_after.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_after.total,
                name: "",
                filter_key: response.data.rag_after.data[0].filter_key,
                filter_column_key: "rag_after",
                report_filter_field: "pupil_premium_stud"
              });
            } else if (history.location.pathname === "/reports/lac-dashboard") {
              response.data.rag_after.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_after.total,
                name: "",
                filter_key: response.data.rag_after.data[0].filter_key,
                filter_column_key: "rag_after",
                report_filter_field: "looked_after_stud"
              });
            } else if (history.location.pathname === "/reports/eal-dashboard") {
              response.data.rag_after.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_after.total,
                name: "",
                filter_key: response.data.rag_after.data[0].filter_key,
                filter_column_key: "rag_after",
                report_filter_field: "eal_stud"
              });
            } else {
              response.data.rag_after.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_after.total,
                name: "",
                filter_key: response.data.rag_after.data[0].filter_key,
                filter_column_key: "rag_after"
              });
            }
          }

          if (
            typeof response.data.rag_before !== "undefined" &&
            response.data.rag_before.data
          ) {
            if (history.location.pathname === "/reports/fsm-dashboard") {
              response.data.rag_before.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_before.total,
                name: "",
                filter_key: response.data.rag_before.data[0].filter_key,
                filter_column_key: "rag_before",
                report_filter_field: "fsm_stud"
              });
            } else if (
              history.location.pathname === "/reports/send-dashboard"
            ) {
              response.data.rag_before.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_before.total,
                name: "",
                filter_key: response.data.rag_before.data[0].filter_key,
                filter_column_key: "rag_before",
                report_filter_field: "sen_status_stud"
              });
            } else if (history.location.pathname === "/reports/pp-dashboard") {
              response.data.rag_before.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_before.total,
                name: "",
                filter_key: response.data.rag_before.data[0].filter_key,
                filter_column_key: "rag_before",
                report_filter_field: "pupil_premium_stud"
              });
            } else if (history.location.pathname === "/reports/lac-dashboard") {
              response.data.rag_before.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_before.total,
                name: "",
                filter_key: response.data.rag_before.data[0].filter_key,
                filter_column_key: "rag_before",
                report_filter_field: "looked_after_stud"
              });
            } else if (history.location.pathname === "/reports/eal-dashboard") {
              response.data.rag_before.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_before.total,
                name: "",
                filter_key: response.data.rag_before.data[0].filter_key,
                filter_column_key: "rag_before",
                report_filter_field: "eal_stud"
              });
            } else {
              response.data.rag_before.data.push({
                sno: "No data",
                value:
                  response.total_student_count - response.data.rag_before.total,
                name: "",
                filter_key: response.data.rag_before.data[0].filter_key,
                filter_column_key: "rag_before"
              });
            }
          }

          if (
            typeof response.data.destinations !== "undefined" &&
            response.data.destinations.data
          ) {
            if (history.location.pathname === "/reports/fsm-dashboard") {
              if (
                response.total_student_count -
                  response.data.destinations.total ===
                0
              ) {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: 0,
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "fsm_stud"
                });
              } else {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.destinations.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "fsm_stud"
                });
              }
            } else if (
              history.location.pathname === "/reports/send-dashboard"
            ) {
              if (
                response.total_student_count -
                  response.data.destinations.total ===
                0
              ) {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: 0,
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "sen_status_stud"
                });
              } else {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.destinations.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "sen_status_stud"
                });
              }
            } else if (history.location.pathname === "/reports/pp-dashboard") {
              if (
                response.total_student_count -
                  response.data.destinations.total ===
                0
              ) {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: 0,
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "pupil_premium_stud"
                });
              } else {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.destinations.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "pupil_premium_stud"
                });
              }
            } else if (history.location.pathname === "/reports/lac-dashboard") {
              if (
                response.total_student_count -
                  response.data.destinations.total ===
                0
              ) {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: 0,
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "looked_after_stud"
                });
              } else {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.destinations.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "looked_after_stud"
                });
              }
            } else if (history.location.pathname === "/reports/eal-dashboard") {
              if (
                response.total_student_count -
                  response.data.destinations.total ===
                0
              ) {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: 0,
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "eal_stud"
                });
              } else {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.destinations.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations",
                  report_filter_field: "eal_stud"
                });
              }
            } else {
              if (
                response.total_student_count -
                  response.data.destinations.total ===
                0
              ) {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: 0,
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations"
                });
              } else {
                response.data.destinations.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.destinations.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.destinations.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.destinations.data[0].filter_key,
                  filter_column_key: "destinations"
                });
              }
            }
          }

          if (
            typeof response.data.expected_level !== "undefined" &&
            response.data.expected_level.data
          ) {
            if (history.location.pathname === "/reports/fsm-dashboard") {
              if (
                response.total_student_count -
                  response.data.expected_level.total ===
                0
              ) {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: 0,
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "fsm_stud"
                });
              } else {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.expected_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "fsm_stud"
                });
              }
            } else if (
              history.location.pathname === "/reports/send-dashboard"
            ) {
              if (
                response.total_student_count -
                  response.data.expected_level.total ===
                0
              ) {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: 0,
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "sen_status_stud"
                });
              } else {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.expected_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "sen_status_stud"
                });
              }
            } else if (history.location.pathname === "/reports/pp-dashboard") {
              if (
                response.total_student_count -
                  response.data.expected_level.total ===
                0
              ) {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: 0,
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "pupil_premium_stud"
                });
              } else {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.expected_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "pupil_premium_stud"
                });
              }
            } else if (history.location.pathname === "/reports/lac-dashboard") {
              if (
                response.total_student_count -
                  response.data.expected_level.total ===
                0
              ) {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: 0,
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "looked_after_stud"
                });
              } else {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.expected_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "looked_after_stud"
                });
              }
            } else if (history.location.pathname === "/reports/eal-dashboard") {
              if (
                response.total_student_count -
                  response.data.expected_level.total ===
                0
              ) {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: 0,
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "eal_stud"
                });
              } else {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.expected_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level",
                  report_filter_field: "eal_stud"
                });
              }
            } else {
              if (
                response.total_student_count -
                  response.data.expected_level.total ===
                0
              ) {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: 0,
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level"
                });
              } else {
                response.data.expected_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.expected_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.expected_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.expected_level.data[0].filter_key,
                  filter_column_key: "expected_level"
                });
              }
            }
          }

          if (
            typeof response.data.intended_level !== "undefined" &&
            response.data.intended_level.data
          ) {
            if (history.location.pathname === "/reports/fsm-dashboard") {
              if (
                response.total_student_count -
                  response.data.intended_level.total ===
                0
              ) {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: 0,
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "fsm_stud"
                });
              } else {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.intended_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "fsm_stud"
                });
              }
            } else if (
              history.location.pathname === "/reports/send-dashboard"
            ) {
              if (
                response.total_student_count -
                  response.data.intended_level.total ===
                0
              ) {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: 0,
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "sen_status_stud"
                });
              } else {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.intended_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "sen_status_stud"
                });
              }
            } else if (history.location.pathname === "/reports/pp-dashboard") {
              if (
                response.total_student_count -
                  response.data.intended_level.total ===
                0
              ) {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: 0,
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "pupil_premium_stud"
                });
              } else {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.intended_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "pupil_premium_stud"
                });
              }
            } else if (history.location.pathname === "/reports/lac-dashboard") {
              if (
                response.total_student_count -
                  response.data.intended_level.total ===
                0
              ) {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: 0,
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "looked_after_stud"
                });
              } else {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.intended_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "looked_after_stud"
                });
              }
            } else if (history.location.pathname === "/reports/eal-dashboard") {
              if (
                response.total_student_count -
                  response.data.intended_level.total ===
                0
              ) {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: 0,
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "eal_stud"
                });
              } else {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.intended_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level",
                  report_filter_field: "eal_stud"
                });
              }
            } else {
              if (
                response.total_student_count -
                  response.data.intended_level.total ===
                0
              ) {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: 0,
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level"
                });
              } else {
                response.data.intended_level.data.push({
                  name: "No data",
                  no:
                    response.total_student_count -
                    response.data.intended_level.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.intended_level.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.intended_level.data[0].filter_key,
                  filter_column_key: "intended_level"
                });
              }
            }
          }

          if (
            typeof response.data.sectors !== "undefined" &&
            response.data.sectors.data
          ) {
            if (history.location.pathname === "/reports/fsm-dashboard") {
              if (
                response.total_student_count - response.data.sectors.total ===
                0
              ) {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: 0,
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "fsm_stud"
                });
              } else {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.sectors.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "fsm_stud"
                });
              }
            } else if (
              history.location.pathname === "/reports/send-dashboard"
            ) {
              if (
                response.total_student_count - response.data.sectors.total ===
                0
              ) {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: 0,
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "sen_status_stud"
                });
              } else {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.sectors.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "sen_status_stud"
                });
              }
            } else if (history.location.pathname === "/reports/pp-dashboard") {
              if (
                response.total_student_count - response.data.sectors.total ===
                0
              ) {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: 0,
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "pupil_premium_stud"
                });
              } else {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.sectors.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "pupil_premium_stud"
                });
              }
            } else if (history.location.pathname === "/reports/lac-dashboard") {
              if (
                response.total_student_count - response.data.sectors.total ===
                0
              ) {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: 0,
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "looked_after_stud"
                });
              } else {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.sectors.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "looked_after_stud"
                });
              }
            } else if (history.location.pathname === "/reports/eal-dashboard") {
              if (
                response.total_student_count - response.data.sectors.total ===
                0
              ) {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: 0,
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "eal_stud"
                });
              } else {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.sectors.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors",
                  report_filter_field: "eal_stud"
                });
              }
            } else {
              if (
                response.total_student_count - response.data.sectors.total ===
                0
              ) {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: 0,
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors"
                });
              } else {
                response.data.sectors.data.push({
                  name: "No data",
                  no:
                    response.total_student_count - response.data.sectors.total,
                  percent: Math.round(
                    ((response.total_student_count -
                      response.data.sectors.total) /
                      response.total_student_count) *
                      100
                  ),
                  filter_key: response.data.sectors.data[0].filter_key,
                  filter_column_key: "sectors"
                });
              }
            }
          }
        } else {
          throw new Error("Oops! data doesn't exist");
        }
      })
      .catch(error => {
        console.log(error);
        showDefaultReportData();
      })
      .finally(() => {
        setGetLoading(false);
      });
  };

  const showDefaultReportData = () => {
    const newData = {
      // default data
      rag_before: {
        ...mockResponse.data.rag_before
      },
      destinations: {
        ...mockResponse.data.destinations
      }
    };

    if (Routes.REPORTS.PRE16.Y11_LIVE_DASHBOARD === history.location.pathname) {
      newData.rag_after = { ...mockResponse.data.rag_after };
      newData.sectors = { ...mockResponse.data.sectors };
      newData.intended_level = { ...mockResponse.data.intended_level };
      newData.expected_level = { ...mockResponse.data.expected_level };
    }
    setPayload(newData);
  };

  useEffect(() => {
    if (user && user.school_id && yearsGroup) {
      getReportDetails({
        school_id: user.school_id,
        year_group: yearsGroup
      });
    } else if (user && user.school_id) {
      getReportDetails({
        school_id: user.school_id,
        year_group: yearsGroup
      });
    } else if (yearsGroup) {
      getReportDetails({
        year_group: yearsGroup
      });
    } else {
      getReportDetails();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    setPageHeading(getPageTitle().name || "Report");
  }, []);

  const renderReportHeading = (length = 0) => {
    return (
      <div className={"row justify-content-center align-content-center"}>
        <h2 className="page-sub-heading">
          Number of students in year group: {length}
        </h2>
      </div>
    );
  };

  const renderReportTable = (item, columns, total, tblname) => {
    if (typeof item.name == "undefined") {
      return "";
    }
    const { name, data } = item;
    return (
      <div key={name} className="col-12 col-lg-6 mt-4">
        <label className="labelstyle">{name || "N/A"}</label>
        <Table
          size={"small"}
          bordered={true}
          showHeader={false}
          pagination={false}
          columns={columns}
          dataSource={data}
          summary={() => <TableSummary total={getTotal || 0} />}
        />
        {tblname === "rag_after" && (
          <Divider style={{ background: "#c0c0c0" }} />
        )}
        {tblname === "rag_before" && (
          <Divider style={{ background: "#c0c0c0" }} />
        )}
      </div>
    );
  };

  const tables = [
    { name: "rag_after", cols: rag_cols },
    { name: "rag_before", cols: rag_cols },
    { name: "intended_level", cols },
    { name: "expected_level", cols },
    { name: "destinations", cols },
    { name: "sectors", cols }
  ];

  return (
    <div className="content-panel">
      <PageMetaTags title={pageHeading} />
      <h1 className="page-heading">{pageHeading}</h1>
      <Skeleton loading={getLoading}>
        {payload ? (
          <div className="profile-wrapper">
            <div className="container">
              {renderReportHeading(response && response.total_student_count)}

              <div className="row">
                {labelRagLive ? (
                  <div className="col-12 col-lg-6 mt-4">
                    <PieChart
                      chartData={piedemoRagLive}
                      colors={pieRagAfterColor}
                      chartLabel={labelRagLive}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {labelRagbefore ? (
                  <div className="col-12 col-lg-6 mt-4">
                    <PieChart
                      chartData={piedemoRagBefore}
                      colors={pieRagBeforeColor}
                      chartLabel={labelRagbefore}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="row">
                {tables.map(
                  table =>
                    payload[table.name] &&
                    renderReportTable(
                      payload[table.name],
                      table.cols,
                      payload[table.name].total,
                      table.name
                    )
                )}
              </div>
              <br />
              <br />
            </div>
          </div>
        ) : (
          <div className="border rounded-lg mt-6">No data found!</div>
        )}
      </Skeleton>
    </div>
  );
}

export { Pre16Reports };
