export default {
  filter: {
    username: "",
    role: "",
    group: "",
    subject: "",
    status_evidence: "",
    active: "",
    page: 1
  }
};
