import React, { useEffect, useRef, useState } from "react";
import { Line } from "@ant-design/plots";

const LineChart = ({ chartData }) => {
  const chartRef = useRef();
  const [data, setData] = useState([]);

  const linesColorCode = [
    "#5B8FF9",
    "#5AD8A6",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D"
  ];

  const config = {
    data,
    legend: false,
    smooth: true,
    xField: "completion_date",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        formatter: v => {
          return `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, s => `${s},`);
        }
      }
    },
    color: linesColorCode,
    point: {
      shape: () => {
        return "circle"; // show default points as `circle`
      }
    }
  };

  useEffect(() => {
    setData(chartData);
  }, [chartData]);

  return (
    <React.Fragment>
      <Line {...config} chartRef={chartRef} />
    </React.Fragment>
  );
};

export { LineChart };
