const TYPE = {
  input: "input",
  select: "select"
};

const formItems = {
  form_visible: 1, // Default check to make it editable as fields rather label
  gcse_english: {
    name: "gcse_english",
    label: "GCSE ENGLISH",
    placeholder: "GCSE ENGLISH",
    editable: true,
    value: null,
    type: TYPE.input
  },
  gcse_maths: {
    name: "gcse_maths",
    label: "GCSE MATHS",
    placeholder: "GCSE MATHS",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  /*"gcse_science": {
    name: "gcse_science",
    label: "GCSE SCIENCE",
    placeholder: "GCSE SCIENCE",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },*/
  ks4_aps: {
    name: "ks4_aps",
    label: "MY AVERAGE GCSE SCORE",
    placeholder: "KS4 APS",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  project_ncop_d2n2: {
    name: "project_ncop_d2n2",
    label: "Project NCOP/D2N2",
    editable: true,
    visibile: true,
    value: null,
    placeholder: "Project NCOP/D2N2",
    type: TYPE.input
  },
  expected_level: {
    name: "expected_level",
    label: "What level course should I aim for after GCSE’s",
    editable: true,
    visibile: true,
    value: null,
    placeholder: "What level course should I aim for after GCSE’s",
    options: [
      {
        label: "3",
        value: "3"
      },
      {
        label: "2",
        value: "2"
      },
      {
        label: "1",
        value: "1"
      },
      {
        label: "Entry",
        value: "entry"
      }
    ],
    type: TYPE.select
  },
  alt_prov: {
    name: "alt_prov",
    label: "Alt Prov",
    placeholder: "Alt Prov",
    editable: true,
    value: null,
    type: TYPE.input
  }
};

export { formItems, TYPE };
