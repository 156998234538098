import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Modal } from "antd";
import { withContext } from "Context";
import { adviser, roleBasedRoutes, student } from "./routes";

import { STUDENT } from "../../../constants";
const SideBar = ({ hamburgerRef, showSideBar, setShowSideBar, user }) => {
  const [routes, setRoutes] = useState([]);
  const sidebarRef = useRef();
  const location = useLocation();
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    const scriptUrl =
      "https://static.zdassets.com/ekr/snippet.js?key=" +
      process.env.REACT_APP_ZENDESK_KEY;
    
    // Create a new script element
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = scriptUrl;
    script.async = true;

    // Script onload callback
    script.onload = () => {
      setLoaded(true); // Set the state to indicate that the script has been loaded
    };

    // Script onerror callback
    script.onerror = () => {
      console.error("Error loading script."); // Handle error if the script fails to load
    };

    // Append the script element to the document body
    document.body.appendChild(script);

    // Clean up function
    if (window.zE) {
      window.zE(function() {
        window.zE.hide(); // Default Hide the Launcher
      });

      // Event Handler on Close
      window.zE("webWidget:on", "close", function() {
        window.zE(function() {
          window.zE.hide();
        });
      });
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (window.zE) {
        window.zE(function() {
          window.zE.hide(); // Default Hide the Launcher
        });
        window.zE("webWidget:on", "close", function() {
          // Your callback function logic here
          window.zE(function() {
            window.zE.hide();
          });
          //console.log("Widget closed");
        });
      }
    }
  }, [isLoaded]);

  useEffect(() => {
    const handleClickOutside = event => {
      // Check if the click is outside of the sidebar and not on the hamburger menu
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setShowSideBar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const allroutes = { student, adviser, ...roleBasedRoutes };

  useEffect(() => {
    if (user) {
      setRoutes(allroutes[user.role_slug]);
    }
  }, [user]);

  const openHelpChat = () => {
    if (user.role_slug === STUDENT) {
      getHelpInfoModal();
    } else {
      setLoaded(true);
      window.zE(function() {
        window.zE.show(); // show launcher
        window.zE("webWidget", "open");
      });
    }
  };

  const getHelpInfoModal = () => {
    const modal = Modal.info({
      title: "Get help",
      content: (
        <div>
          <p>Contact your school careers lead or careers adviser for help.</p>
        </div>
      ),
      afterClose: () => {
        modal.destroy();
      }
    });
  };

  return (
    <div
      data-cy="sidebar"
      id="sidebar"
      ref={sidebarRef}
      className={classNames("left-panel shadow-xl", showSideBar && "slide")}
      style={{ height: "100vh" }}
    >
      <ul
        className={
          user.role_slug === STUDENT ? "student-left-menu" : "left-menu"
        }
      >
        {(routes || []).map(route => (
          <li
            data-cy={`sidebar-${route.name.replace(/ /g, "")}`}
            key={route.path}
          >
            <Link
              className={classNames(
                { selected: location.pathname === route.path },
                // Just for Destination Tracker due to SubMenu
                {
                  selected:
                    route.sidebarSelectedClass &&
                    location.pathname.includes(route.path)
                }
              )}
              to={route.path}
            >
              <span>
                {user.role_slug === STUDENT ? (
                  <img className="h-8 w-8" src={route.image} alt="icons" />
                ) : (
                  <i className={classNames("fas", route.icon)} />
                )}
              </span>
              {/*First Check will work to display "My Future"*/}
              {route.studentRoleName || route.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="flex flex-col w-full">
        <div className="">
          <img
            className="pb-2 px-10 h-16"
            src={require("../../../assets/images/logo-small1.png")}
            alt="images"
          />

          <Link
            to="#"
            onClick={openHelpChat}
            className="button-style button-style-gray-btm button-style-normal-button mt-3"
          >
            {user.role_slug === STUDENT ? "Get Help" : "Contact Support"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), SideBar);
