import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { Empty, notification, Skeleton } from "antd";
import { withContext } from "Context";
import { PageMetaTags } from "Common";
import { getProfileDetails } from "Services/user";
import BookAppointmentModal from "../../Appointments/BookAppointmentModal";
import { Breadcrumbs } from "../../../..//components/common";
import RoleCheck from "../../../helpers/RoleCheck";
import { STUDENT } from "../../../../constants";

const ViewAdviser = ({ user }) => {
  const { adviserId } = useParams();
  const [profileDetails, setProfileDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const userId = getAdviserId();
    if (userId) {
      setLoading(true);
      getProfileDetails({
        pathParams: {
          userId: userId
        }
      })
        .then(response => {
          setProfileDetails(response);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: "No adviser is assigned" });
    }
  }, [adviserId]);

  const getAdviserId = () => {
    return (
      adviserId || (location.pathname === "/my-adviser" && user.adviser_id)
    );
  };

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs
        breadcrumbs={[
          {
            name: "Advisers list",
            path: `/adviser`
          },
          {
            name: "Adviser profile",
            path: null
          }
        ]}
      />
    );
  };

  if (!getAdviserId()) {
    return (
      <div className="content-panel">
        <div className="profile-wrapper">
          <Empty description="No adviser is assigned" />
        </div>
      </div>
    );
  }

  return (
    <div className="content-panel">
      <PageMetaTags title="Adviser Profile" />
      {renderBreadcrumbs()}
      <div className="student-booking">
        <h1 className="page-heading">Adviser profile</h1>
        <div className="d-flex">
          {/* <span
            onClick={() => setModal(true)}
            className="button-style button-style-green-btm button-style-normal-button">
            Book Appointment
  </span> */}
          <RoleCheck authority={[STUDENT]}>
            <Link to={`/emails/new?id=${getAdviserId()}`}>
              <span className="button-style button-style-green-btm button-style-normal-button ml-2">
                <i className="fas fa-envelope pr-1" /> Send Email
              </span>
            </Link>
          </RoleCheck>
        </div>
      </div>

      {modal && (
        <BookAppointmentModal
          open={modal}
          onClose={() => setModal(false)}
          bookingFor="adviser"
          userId={getAdviserId()}
          advisor={{ id: getAdviserId() }}
        />
      )}

      <div className="profile-wrapper">
        <Skeleton loading={loading}>
          <div className="info-box">
            <h4 className="small-heading">Name</h4>
            <div className="pro-place-inner">
              <div className="info">
                <h2 className="page-sub-heading">
                  {(profileDetails && profileDetails.user_data.name) || ""}
                </h2>
                <a
                  href={`mailto:${profileDetails &&
                    profileDetails.user_data.email}`}
                  className="email"
                >
                  {(profileDetails && profileDetails.user_data.email) || ""}
                </a>
              </div>
            </div>
          </div>
          <div className="info-box">
            <h4 className="small-heading">Location</h4>
            {profileDetails &&
              profileDetails.school_data &&
              profileDetails.school_data.map((school, index) => (
                <div key={index} className="address">
                  <p>{school.school_name}</p>
                  <p>{school.school_address_line_1}</p>
                  <p>{school.school_address_line_2}</p>
                </div>
              ))}
          </div>

          {profileDetails && profileDetails.user_data.specialism && (
            <div className="info-box border-bottom-0">
              <h4 className="small-heading">Specialism</h4>
              <div className="degree">
                <span>{profileDetails.user_data.specialism}</span>
              </div>
            </div>
          )}
        </Skeleton>
      </div>
    </div>
  );
};

export default withContext(
  ([{ user }]) => ({
    user
  }),
  ViewAdviser
);
