import { callApi } from "../utils/apiUtils";
import usefulLinks from "./endpoints/usefullink";

export const getUsefulLinksList = ({ body, query }) =>
  callApi({ uriEndPoint: usefulLinks.getUsefulLinkList.v1, body, query });

export const createUsefulLink = ({ body }) =>
  callApi({ uriEndPoint: usefulLinks.createUsefulLink.v1, body });

export const updateUsefulLink = ({ body }) =>
  callApi({ uriEndPoint: usefulLinks.updateUsefulLink.v1, body });

export const deleteUsefulLink = ({ body }) =>
  callApi({ uriEndPoint: usefulLinks.deleteUsefulLink.v1, body });
