import { defaults } from "./default";

const report = {
  getY12Dashboard: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/y12-dashboard"
    }
  },
  getY13Dashboard: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/y13-dashboard"
    }
  },
  getPost16SeptemberGuarantee: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/post16-sept-guarantee"
    }
  },
  getPost16OctoberActivitySurvey: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/october_survey"
    }
  },
  getPost16Bursary: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/bursary"
    }
  },
  getPost16Send: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/send"
    }
  },
  getPost16LAC: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/lac"
    }
  },
  getPost16EAL: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/reports/eal"
    }
  },
  getPre16Reports: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: ":path"
    }
  },
  getPost16Reports: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: ":path"
    }
  }
};

export { report };
