import { withContext } from "Context";
import { signOutSuccessAction } from "Actions/authActions";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProfilePic, logoutUser } from "../../../services/user";
import Avatar from "antd/lib/avatar/avatar";
import { getInitials } from "../../../utils";
import { Badge, Select, Space } from "antd";
import { ADVISER, STUDENT, SUPER_ADMIN } from "../../../constants";
import RoleCheck from "../../helpers/RoleCheck";
import { Option } from "antd/lib/mentions";
import { getLocationList } from "../../../services/common";
import {
  profileUpdateAction,
  setSelectedSchoolAction
} from "../../../store/actions/profileActions";

const Header = ({
  hamburgerRef,
  setShowSideBar,
  signOutSuccess,
  updateProfile,
  user,
  setSelectedSchoolDispatch
}) => {
  const SCHOOL_DEFAULT_VALUE = "All";
  const [selectedSchool, setSelectedSchool] = useState();
  const [schools, setSchools] = useState();
  const [loading, setLoading] = useState(false);

  const getLocations = query => {
    setLoading(true);
    getLocationList({
      body: {
        sort_column: "created_at",
        sort_order: "desc",
        page_limit: 100000,
        page: 1,
        is_header: 1 // "adviser"
      },
      query: query || {}
    })
      .then(response => {
        if (response.status) {
          setSchools(response);
          localStorage.setItem("attached_school", JSON.stringify(response));
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      user &&
      (user.role_slug === ADVISER || user.role_slug === SUPER_ADMIN)
    ) {
      getLocations();
      if (localStorage.getItem("selected_school")) {
        onChange(JSON.parse(localStorage.getItem("selected_school")));
        setSelectedSchool(JSON.parse(localStorage.getItem("selected_school")));
      } else {
        onChange("All");

        setSelectedSchool("All");
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.accessToken) {
      getProfilePic()
        .then(response => {
          updateProfile(response);
        })
        .catch(error => console.log(error));
    }
  }, []);

  function onChange(value) {
    //console.log(`selected ${value}`);
    if (value && value !== "All") {
      localStorage.setItem("selected_school", value);
      setSelectedSchool(value);
      setSelectedSchoolDispatch({ school_id: value }); // "school_id" according to API doc
    } else {
      setSelectedSchool("All");
      localStorage.removeItem("selected_school");
      setSelectedSchoolDispatch({ school_id: null });
    }
  }

  return (
    <header className="row justify-content-between header-wrapper">
      <div className={"col-12 col-md-3"}>
        <Link to="/dashboard" className="logo">
          <img
            data-cy="header-logo"
            src={require("../../../assets/images/logo-small.png")}
            alt="logo"
            className="logo-image"
          />
        </Link>
      </div>

      <div
        data-cy="header-components"
        className={
          "col-12 col-md-9 d-flex justify-content-end align-items-center"
        }
      >
        <RoleCheck authority={[ADVISER, SUPER_ADMIN]} roleClass={"mr-4"}>
          <Select
            data-cy="header-school-dropdown"
            showSearch
            value={selectedSchool}
            defaultValue={selectedSchool}
            placeholder="Select School"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {schools &&
              schools.data &&
              schools.data.map(school => (
                <Option key={school.id} value={school.id}>
                  {school.name}
                </Option>
              ))}
          </Select>
        </RoleCheck>

        <div className={"d-flex justify-content-end align-items-center"}>
          <RoleCheck authority={[ADVISER, STUDENT]} roleClass="mr-4">
            <Link to="/emails/list">
              <Badge
                style={{ marginTop: 6 }}
                count={user.unread_email_counts || 0}
              >
                <i
                  style={{
                    fontSize: 24,
                    marginTop: 6,
                    color: "#14985f"
                  }}
                  className="fas fa-envelope"
                />
              </Badge>
            </Link>
          </RoleCheck>
          <Avatar
            style={{ background: "#16975f" }}
            src={user && user.profile_pic_path && user.profile_pic_path}
          >
            {getInitials(user && user.name)}
          </Avatar>

          <Link data-cy="profile-link" to="/profile">
            <div className="lg:text-lg sm:text-sm text-gray-900 font-bold px-2 truncate">
              {user && user.name}{" "}
              <i className="fas fa-chevron-down font-normal text-sm" />
            </div>
          </Link>

          <Link
            data-cy="logout-link"
            to="/login"
            onClick={() => {
              signOutSuccess();
              logoutUser();
              localStorage.removeItem("user");
              localStorage.removeItem("accessToken");
              localStorage.removeItem("AdvisorsDropdown");
              localStorage.removeItem("selected_school");
            }}
            className="text-gray-600 ml-4"
          >
            Logout
          </Link>
        </div>

        <div
          ref={hamburgerRef}
          data-cy="ham-menu"
          onClick={e => {
            e.stopPropagation();
            setShowSideBar(prevState => !prevState);
          }}
          className="ham-menu"
        >
          <i className="fas fa-bars" />
        </div>
      </div>
    </header>
  );
};

export default withContext(
  ([
    {
      app: { openMenu },
      user
    },
    dispatch
  ]) => ({
    // state
    openMenu,
    user,
    // actions
    setSelectedSchoolDispatch: data => setSelectedSchoolAction(data, dispatch),
    signOutSuccess: () => signOutSuccessAction(dispatch),
    updateProfile: data => profileUpdateAction(data, dispatch)
  }),
  Header
);
