import React from "react";
import { withContext } from "Context";

const RoleCheck = ({ authority, children, roleClass, user, visible }) => {
  const currentAuthority = user && user.role_slug;
  let childrenToRender;
  if (authority.includes(currentAuthority || "") && visible) {
    childrenToRender = children;
  }
  return <div className={roleClass && roleClass}>{childrenToRender}</div>;
};

RoleCheck.defaultProps = {
  visible: true
};

export default withContext(
  ([{ user }]) => ({
    user
  }),
  RoleCheck
);
