import { defaults } from "./default";

const appointment = {
  getAppointmentSlots: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/show-slots"
    }
  },
  createAppointment: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/create-appointment"
    }
  },
  createGroupCallAppointment: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/create-group-call-appointment"
    }
  },
  getAppointments: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/appointment-index"
    }
  },
  getAppointmentTimingsForCalenderRange: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/appointment-timings"
    }
  },
  getAppointmentsHistory: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-appointment-history"
    }
  },
  getTranscript: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/download-transcript/:archive_id"
    }
  },
  showAdviserSettings: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/show-settings"
    }
  },
  createAdviserSettings: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/create-setting"
    }
  },
  updateAdviserSettings: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/create-setting"
    }
  },
  showAdviserDayOff: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/adviser-offdays-index"
    }
  },
  editAdviserDayOff: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/update-adviser-offdays"
    }
  },
  deleteAdviserDayOff: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: "/delete-adviser-offdays"
    }
  },
  deleteAppointment: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: "/delete-appointment"
    }
  },
  updateAppointment: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/update-appointment"
    }
  },
  voiceCall: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/voicecall-authentication"
    }
  },
  videoCall: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/establish-appointment-interaction"
    }
  },
  appointmentDetail: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/show-appointment"
    }
  },
  liveChat: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/live-chat-credentials"
    }
  },
  startRecording: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/start-archiving"
    }
  },
  stopRecording: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/stop-archiving"
    }
  },
  showAdviserDaysOff: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/show-adviser-dayoff"
    }
  },
  getStudentAppointmentsHistory: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-student-appointment-history"
    }
  }
};

export default appointment;
