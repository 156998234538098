import Advisor from "Pages/Advisor";
import Dashboard from "Pages/Dashboard";
import Profile from "Pages/Profile";
import Student from "Pages/Student";
import Tasks from "Pages/Tasks";
import TimeLog from "Pages/TimeLog";
import UsefulLinks from "Pages/UsefulLinks";
import Appointments from "Pages/Appointments";
import SchoolLocation from "Pages/SchoolLocation";
import { Location } from "../components/pages";
import DestinationTracker from "../components/pages/DestinationTracker";
import Reports from "Pages/Reports";
import Import from "Pages/Import";
import JoinMeeting from "../components/pages/Appointments/JoinMeeting";
import Emails from "Pages/Emails";
import WebReport from "Pages/WebReport";
import CompassReport from "../components/pages/CompassReport";

// Resources Tab
import Resources from "Pages/Resources";
import Skillsometer from "../components/pages/Resources/SkillsoMeter";
import Careerometer from "../components/pages/Resources/Careerometer";
import StarExerciseRouting from "../components/pages/Resources/StarExercise/StarExerciseRouting";

import AppointmentError from "Pages/AppointmentError";

const navigationRoutes = [
  {
    path: "/dashboard",
    component: Dashboard
  },
  {
    path: "/appointments",
    component: Appointments
  },
  {
    path: "/appointments-history",
    component: Appointments
  },
  {
    path: "/appointments-history/:studentId",
    component: Appointments
  },
  {
    path: "/appointments/:appointmentId/join",
    component: JoinMeeting
  },
  {
    path: "/school-locations",
    component: SchoolLocation
  },
  {
    path: "/appointments/settings",
    component: Advisor
  },
  {
    path: "/profile",
    component: Profile
  },
  {
    path: "/my-adviser",
    component: Advisor
  },
  {
    path: "/adviser",
    component: Advisor
  },

  {
    path: "/adviser/:adviserId",
    component: Advisor
  },
  {
    path: "/tasks",
    component: Tasks
  },
  {
    path: "/emails",
    component: Emails
  },
  {
    path: "/emails/new",
    component: Emails
  },
  {
    path: "/emails/list",
    component: Emails
  },
  {
    path: "/emails/:emailId/view",
    component: Emails
  },
  {
    path: "/action-list",
    component: Tasks
  },
  {
    path: "/action-plan",
    component: Tasks
  },
  {
    path: "/timelog",
    component: TimeLog
  },
  {
    path: "/students",
    component: Student
  },
  {
    path: "/students/:studentId",
    component: Student
  },
  {
    path: "/location",
    component: Location
  },
  {
    path: "/actions",
    component: Tasks
  },
  {
    path: "/timelog/list",
    component: TimeLog
  },
  {
    path: "/compass-report",
    component: CompassReport
  },
  {
    path: "/students/:studentId/action-list",
    component: Tasks
  },
  {
    path: "/my-future",
    component: DestinationTracker
  },
  {
    path: "/destination-tracker/",
    component: DestinationTracker
  },
  {
    path: "/destination-tracker/:trackPath",
    component: DestinationTracker
  },
  {
    path: "/reports",
    component: Reports
  },
  {
    path: "/reports/:reportPath",
    component: Reports
  },
  {
    path: "/import-data",
    component: Import
  },
  {
    path: "/useful-links",
    component: UsefulLinks
  },
  {
    path: "/emsi-web-report",
    component: WebReport
  },
  {
    path: "/skillsometer",
    component: Skillsometer
  },
  {
    path: "/careerometer",
    component: Careerometer
  },
  {
    path: "/starexercise",
    component: StarExerciseRouting
  },
  {
    path: "/starexercise/result/:exerciseId",
    component: StarExerciseRouting
  },
  {
    path: "/starexercise/:path",
    component: StarExerciseRouting
  },
  {
    path: "/resources",
    component: Resources
  },
  {
    path: "/appointment-error",
    component: AppointmentError
  }
];

export { navigationRoutes };
