import React, { useEffect, useState } from "react";
import { Dropdown, Form, Input, Spin } from "antd";
import { PageMetaTags } from "../../../../components/common";
import RoleCheck from "../../../helpers/RoleCheck";
import { SUPER_ADMIN } from "../../../../constants/rolesSubtypes";
import { getLocationList } from "../../../../services/common";
import moment from "moment";
import { MomentDateFormats } from "../../../../constants";

const LocationSearch = () => {
  const [locations, setLocations] = useState();
  const [locationName, setLocationName] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const getLocations = query => {
    setLoading(true);
    if (query && query.search) {
      setLocationName(query.search);
    }
    getLocationList({
      body: {
        sort_column: "created_at",
        sort_order: "desc",
        page_limit: 1000,
        page: 1,
        is_header: 0 // 0 "super-admin"
      },
      query: query || {}
    })
      .then(response => {
        if (response.status) {
          setLocations(response);
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLocations();
  }, []);

  const renderSearchArea = () => {
    return (
      <div className="search-area">
        <Form form={form} onFinish={values => getLocations(values)}>
          <div className="row align-items-end">
            <div className="col-xl-3 col-md-12 col-lg-6">
              <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
                <label className="labelstyle">Location Search</label>
                <Form.Item name="search">
                  <Input
                    type="text"
                    className="input-style"
                    placeholder="e.g Oakgrove"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-6 col-xl-3 col-md-6 col-lg-6">
              <span
                className="button-style button-style-green-btm button-style-normal-button"
                onClick={() => form.submit()}
              >
                Search
              </span>
            </div>
            <div className="col-6 col-xl-3 col-md-6 col-lg-6 text-right">
              <a
                href="/"
                className="button-style button-style-gray-btm button-style-normal-button"
              >
                Actions
              </a>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const renderSubHeading = () => {
    return (
      <h2 className="page-sub-heading">
        There are <span>" {(locations && locations.data.length) || 0} "</span>{" "}
        results{" "}
        {locationName && (
          <React.Fragment>
            for <span>{locationName}</span>
          </React.Fragment>
        )}
      </h2>
    );
  };

  return (
    <div className="content-panel">
      <PageMetaTags title="Location Search" />
      <h1 className="page-heading">Location Search</h1>
      {renderSearchArea()}
      {renderSubHeading()}

      <Spin spinning={loading}>
        <div className="search-wrapper">
          <div className="table-responsive mobile-spacing">
            <table className="table search-list">
              <tbody>
                {locations &&
                  locations.data.map(location => (
                    <tr key={location.id}>
                      <td>
                        <label className="check-style">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            defaultValue
                          />
                          <i />
                          {location.name || "N/A"}
                        </label>
                      </td>
                      <td>
                        {moment(location.date).format(
                          MomentDateFormats.CustomDateFormat.dayMonthYear
                        )}
                      </td>
                      <td>{location.location || "N/A"}</td>
                      <td>
                        {/* Todo static buttons will discuss what functionality to achieve*/}
                        <Dropdown
                          trigger={["click"]}
                          placement="bottomRight"
                          overlay={
                            <RoleCheck authority={[SUPER_ADMIN]}>
                              <div className="border bg-white overlay">
                                <div className="dropdown-overlay-edit">
                                  <i className="fas fa-pen" /> Edit
                                </div>
                              </div>
                            </RoleCheck>
                          }
                        >
                          <i className="fas fa-ellipsis-h" />
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export { LocationSearch };
