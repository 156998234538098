import React, { useEffect, useState } from "react";
import { Form, Input, notification, Skeleton } from "antd";
import { useHistory } from "react-router";

import { PageMetaTags } from "Common";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";
import {
  getSubjectOptions,
  saveSubjectOptions
} from "../../../../services/destination";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { STUDENT } from "../../../../constants";

const formItems = [
  { name: "university_1", label: "University 1" },
  { name: "course_1", label: "Course 1" },
  { name: "university_2", label: "University 2" },
  { name: "course_2", label: "Course 2" },
  { name: "university_3", label: "University 3" },
  { name: "course_3", label: "Course 3" },
  { name: "university_4", label: "University 4" },
  { name: "course_4", label: "Course 4" },
  { name: "university_5", label: "University 5" },
  { name: "course_5", label: "Course 5" }
];

const SubjectOptionsUCAS = ({ user }) => {
  const pageHeading = "Subjects / Options / UCAS";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [editable, setEditable] = useState(true);
  const [getLoading, setGetLoading] = useState(false);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group

  const getDetails = () => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
    }
    getSubjectOptions({ query: payload })
      .then(res => {
        if (res.success) {
          setFormData(res.data || undefined);
          setEditable(res.data && res.data.form_visible === 0 ? false : true);
          const values = Object.keys(res.data).reduce(
            (prev, k) => ({ ...prev, [k]: res.data[k].value }),
            {}
          );
          form.setFieldsValue({ ...values });
          setGetLoading(false);
        }
      })
      .catch(() => setGetLoading(false));
  };

  useEffect(() => getDetails(), [query.get("id")]);

  const onFinish = values => {
    setLoading(true);
    if (query.get("id")) {
      values.student_id = query.get("id");
    }
    saveSubjectOptions({ body: values })
      .then(() => {
        setLoading(false);
        getDetails();
        notification.success({
          message: "Saved the Subjects / Options / UCAS"
        });
      })
      .catch(() => {
        setLoading(false);
        notification.error({
          message: "Failed to save Subjects / Options / UCAS"
        });
      })
      .finally(() => setLoading(false));
  };

  const renderSubHeadingText = () => {
    return (
      <div className="mb-4 ml-2 w-75">
        <p>
          This information may not be populated if you are not planning on going
          to university.
        </p>
      </div>
    );
  };

  return (
    <div className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}

      {user && user.role_slug === STUDENT && renderSubHeadingText()}

      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form form={form} onFinish={onFinish}>
            <div className="row">
              {formItems.map(({ name, label }) => (
                <div className="col-12 col-md-6">
                  <div className="form-group pb-2">
                    <label className="labelstyle">{label}</label>
                    <Form.Item name={name}>
                      {editable ? (
                        <Input
                          disabled={
                            formData && formData[name]
                              ? !formData[name].editable
                              : false
                          }
                        />
                      ) : (
                        <div className="label-desc">{formData[name].value}</div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              ))}
            </div>
            {editable && (
              <div className="form-group d-flex justify-content-end">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={loading ? "Loading..." : "Update"}
                />
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export default SubjectOptionsUCAS;
