const _range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
const convertCypressIdName = name => name.replace(/ /g, "");
const convertCypressIdPath = path => path.replace(/\//g, "");

const disabledRangeTime = (_, type) => {
  if (!type) {
    return;
  }
  const totalRange = _range(0, 24);
  let rangeBeforeMorningToDisable = totalRange.splice(0, 8);
  let rangeBeforeAfterEveningToDisable = totalRange.splice(13); // Delete from 20 onwards till last 24th entry of hour
  let disabledHourValues = [
    ...rangeBeforeMorningToDisable,
    ...rangeBeforeAfterEveningToDisable
  ];
  if (type === "start" || type === "end") {
    // Note: you can disableMinutes, disableSeconds later if you want to see API DOC
    return {
      disabledHours: () => disabledHourValues
    };
  }
  return [];
};

const isNumeric = value => {
  if (typeof value != "string") {
    return false;
  } // we only process strings!
  return (
    !isNaN(value) && !isNaN(parseFloat(value)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
};

function deepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

// Apply Table sorting using ajax:
const getSortOrder = order => {
  if (order === "ascend") {
    return "asc"; // BE handles as this string
  } else if (order === "descend") {
    return "desc"; // BE handles as this string
  }
};

const openLinkInNewTab = link => {
  window.open(link, "_blank");
};
export {
  disabledRangeTime,
  deepCopy,
  isNumeric,
  getSortOrder,
  openLinkInNewTab,
  convertCypressIdName,
  convertCypressIdPath
};
