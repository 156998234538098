import React, { useEffect } from "react";

import { Chart } from "@antv/g2";

const GraphBar = ({ id, data }) => {
  useEffect(() => {
    const chart = new Chart({
      container: `${id}container`,
      autoFit: true,
      height: 500
    });
    chart.data(data);
    chart.scale("value", {
      nice: true
    });
    chart.tooltip({
      showMarkers: false,
      shared: true
    });

    chart
      .interval()
      .position("group*value")
      .color("name")
      .adjust([
        {
          type: "dodge",
          marginRatio: 0
        }
      ]);

    chart.interaction("element-highlight-by-x");

    chart.render();
  }, []);
  return (
    <div
      style={{ height: 500, marginTop: 30, marginBottom: 10 }}
      id={`${id}container`}
    />
  );
};

export default GraphBar;
