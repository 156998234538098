import React from "react";
import UsefulLinkList from "./UsefulLinkList";
import { Redirect, Route, Switch } from "react-router-dom";

const UsefulLinks = () => {
  return (
    <Switch>
      <Route exact path="/useful-links" component={UsefulLinkList} />
      <Redirect to="/dashboard" />
    </Switch>
  );
};

export default UsefulLinks;
