import React from "react";
import { OTSession, OTStreams } from "opentok-react";
import Subscriber from "./Subscriber";
import Publisher from "./Publisher";
import RoleCheck from "../../../../helpers/RoleCheck";
import { ADVISER, STUDENT, SUPER_ADMIN } from "../../../../../constants";
import { AppointmentCallHeader } from "../AppointmentHeader/AppointmentCallHeader";
import { JoiningConfirmationModal } from "../JoiningConfirmationModal/JoiningConfirmationModal";
import "./group-call.scss";

export class OTPubSubWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      connected: false,
      connectedStreams: [],
      session: null,
      isReadyToStartCall: true,
      active: true
    };

    this.sessionEvents = {
      sessionConnected: session => {
        console.log("Event.OTPubSubWrapper.sessionConnected", session);
        this.setState({ connected: true, session: session.target });
      },
      sessionDisconnected: () => {
        console.log("Event.OTPubSubWrapper.sessionDisconnected");
        this.setState({ connected: false, session: null });
      },
      connectionCreated: newStream => {
        console.log(
          "OTPubSubWrapper.connectionCreated => New stream connected",
          newStream
        );
        this.setState({
          connectedStreams: [...this.state.connectedStreams, newStream]
        });
      },
      connectionDestroyed: response => {
        console.log(
          "OTPubSubWrapper.connectionDestroyed => Existing stream destroyed"
        );
        if (
          this.state.connectedStreams &&
          this.state.connectedStreams.length > 0
        ) {
          const filteredList = this.state.connectedStreams.filter(conn => {
            return (
              conn.connection.connectionId !== response.connection.connectionId
            );
          });
          this.setState({ connectedStreams: [...filteredList] }, () => {
            console.log("After", this.state.connectedStreams);
          });
        }
      },
      muteForced: response => {
        console.log("OTPubSubWrapper.muteForce", response);
        if (!response.active) {
          this.onUnmuteStudents();
        }
        this.setState({ active: !response.active });
      }
    };
  }

  componentWillMount() {}

  onConnect = () => {
    console.log(
      "OTPubSubWrapper.Session.onConnect => Session connect successful"
    );
  };

  onError = err => {
    console.log("OTPubSubWrapper.Session.onError => Session connect fails");
    this.setState({ error: `Failed to connect: ${err.message}` });
  };

  onUnmuteStudents() {
    if (this.props.user.role_slug !== STUDENT) {
      return;
    }

    const publisherMuteButton = document.querySelector(
      ".OTPublisherContainer .OT_mute.OT_active"
    );
    if (publisherMuteButton) {
      publisherMuteButton.click();
    }
  }

  handleIsReadyToStartCall = () => {
    this.setState({ isReadyToStartCall: true });
  };

  getPublisherName() {
    return this.props.user.role_slug !== STUDENT ? ADVISER : STUDENT;
  }

  setActive = event => {
    event.preventDefault();

    const { session } = this.state;

    const advisorStreams = session.streams.where({ name: ADVISER });
    if (this.state.active) {
      session.forceMuteAll(advisorStreams);
    } else {
      session.disableForceMute();
    }

    /*// only mute subscribers (students)
    var btnsPub = document.getElementsByClassName("pubaudio");
    for (var i = 0; i < btnsPub.length; i++) {
      btnsPub[i].click();
    }*/

    this.setState(
      {
        active: !this.state.active
      },
      () => {
        console.log("active", this.state.active);
      }
    );
  };

  render() {
    const { appointmentDetails, event } = this.props;
    const { api_key, token, session_id } = event;

    return (
      <div className={"webinar-call-wrapper"}>
        <AppointmentCallHeader
          title={"Webinar Call between"}
          appointmentDetails={appointmentDetails}
          startTime={event.start_time}
        />
        <RoleCheck authority={[ADVISER]}>
          <div
            className={"d-flex justify-content-between mt-2 mt-2"}
            style={{ width: "300px", marginBottom: ".5rem" }}
          >
            {this.state.session && (
              <button
                className={"btn btn-sm text-white bg-black"}
                onClick={this.setActive}
              >
                {this.state.active ? "Mute All" : "Unmute All"}
              </button>
            )}
          </div>
        </RoleCheck>
        {this.state.isReadyToStartCall ? (
          <OTSession
            apiKey={api_key}
            sessionId={session_id}
            token={token}
            eventHandlers={this.sessionEvents}
            onConnect={this.onConnect}
            properties={{
              MuteForcedEvent: this.state.active
            }}
            onError={this.onError}
          >
            {this.state.error ? <div>{this.state.error}</div> : null}

            <Publisher
              {...this.props}
              isPublisherMuted={!this.state.active}
              publisherProperties={{
                name: this.getPublisherName()
              }}
            />
            {/*Subscriber Streams*/}
            <OTStreams>
              <Subscriber
                {...this.props}
                subscriberProperties={{
                  name: ""
                }}
              />
            </OTStreams>
          </OTSession>
        ) : (
          <JoiningConfirmationModal
            title={"Are you ready to join Webinar ?"}
            description={
              "Please check your microphone and camera before joining"
            }
            visible={!this.state.isReadyToStartCall}
            handleIsReadyToStartCall={this.handleIsReadyToStartCall}
          />
        )}
      </div>
    );
  }
}
