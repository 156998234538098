import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

const StudentFutureCards = () => {
  return (
    <Row gutter={[24, 24]} className="my-4">
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Link to="/destination-tracker/student-data">
          <div className="bg-white rounded-lg text-gray-500 h-full">
            <div className="text-4xl p-4 font-thin">About Me...</div>
            <div className="text-sm mt-2">
              <img
                alt="about me"
                className="h-auto rounded-br-lg rounded-bl-lg object-cover w-full"
                src={require("../../../../assets/icons/cards/about_me.jpg")}
              />
            </div>
          </div>
        </Link>
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Link to="my-future">
          <div className="bg-white rounded-lg text-gray-500 h-full">
            <div className="text-4xl p-4 font-thin">My Future...</div>
            <div className="text-sm mt-2">
              <img
                alt="Future"
                className="h-auto rounded-br-lg rounded-bl-lg object-cover w-full"
                src={require("../../../../assets/images/future.jpg")}
              />
            </div>
          </div>
        </Link>
      </Col>
      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
        <Link to="my-adviser">
          <div className="bg-white rounded-lg text-gray-500 h-full">
            <div className="text-4xl p-4 font-thin">My Adviser...</div>
            <div className="text-sm mt-2">
              <img
                alt="Adviser"
                className="h-auto rounded-br-lg rounded-bl-lg object-cover w-full"
                src={require("../../../../assets/icons/cards/my_adviser.jpg")}
              />
            </div>
          </div>
        </Link>
      </Col>
    </Row>
  );
};

export default StudentFutureCards;
