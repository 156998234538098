import { Empty, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { withContext } from "Context";
import { getTasks } from "../../../../../services/task";
import { ADVISER, MomentDateFormats } from "../../../../../constants";
import { storeTaskList } from "../../../../../store/actions/taskActions";

const ActionPlanCard = ({ user, tasks, storeTasks }) => {
  const [loading, setLoading] = useState(false);
  const [tasksList, setTasksList] = useState([]);
  const history = useHistory();

  const fetchTasks = (query = {}) => {
    setLoading(true);
    const queryParams = { page: 1, ...query };
    getTasks({
      body: {
        sort_column: "id",
        sort_order: "desc",
        page_limit: 4,
        student: "all"
      },
      query: queryParams
    })
      .then(response => {
        setTasksList(response.tasks || []);
        storeTasks(response);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (user && user.school_id) {
      fetchTasks({ school_id: user.school_id });
    } else {
      fetchTasks();
    }
  }, [user.school_id]);

  useEffect(() => {
    setTasksList(tasks && tasks.taskList);
  }, [tasks]);

  return (
    <div data-cy="dashboard-agreed-actions-section" className="">
      <h2 className="page-sub-heading">Student Agreed Actions</h2>
      <Skeleton loading={loading}>
        <div className="table-responsive mobile-spacing">
          {Array.isArray(tasksList) && tasksList.length > 0 ? (
            <>
              <table className="table custom-table-style">
                <thead>
                  <tr>
                    <th>Student</th>
                    <th>Action</th>
                    <th>Due date</th>
                  </tr>
                </thead>
                <tbody data-cy="dashboard-agreed-actions-table">
                  {(tasksList || []).map(task => (
                    <tr
                      key={task.id}
                      className="cursor-pointer"
                      onClick={() => {
                        history.push(
                          `/students/${task.student_id}/action-list`
                        );
                      }}
                    >
                      <td>
                        <label className="radio-style cursor-pointer">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={task.status > 2}
                          />
                          <i />
                          {task.student || ""}
                        </label>
                      </td>
                      <td>{task.description || ""}</td>
                      <td>
                        11
                        {task.completion_date
                          ? moment(task.completion_date).format(
                              MomentDateFormats.CustomDateFormat.dayMonthYear
                            )
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {user.role_slug === ADVISER && (
                <Link
                  to="/students"
                  className="icon-button icon-button--iconcolor"
                >
                  <span>
                    <i className="fas fa-ellipsis-h" />
                  </span>{" "}
                  View all students
                </Link>
              )}
            </>
          ) : (
            <Empty description="No agreed actions found for your students" />
          )}
        </div>
      </Skeleton>
    </div>
  );
};

export default withContext(contextValue => {
  if (!contextValue && !contextValue?.dispatch) {
    return {};
  }
  const [{ tasks }, dispatch] = contextValue;
  return {
    tasks,
    storeTasks: data => storeTaskList(data, dispatch)
  };
}, ActionPlanCard);
