import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { notification, Skeleton, Timeline } from "antd";
import classNames from "classnames";

import RoleCheck from "../../../../helpers/RoleCheck";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { openLinkInNewTab } from "../../../../common";
import { PageMetaTags } from "../../../../common/PageMetaTags";
import { deepCopy } from "../../../../pages/DestinationTracker/common.js";
import { PreviousBreakDown } from "./PreviousBreakDown";
import { PastExercises } from "../PastExercises/PastExercises";
import { PastResultTimeline } from "../StarExerciseResult/PastResultTimeline";
import { LineChart, RadarChart } from "../AntDesignPlotCharts";
import {
  getPreviousSections,
  getStarExerciseDetailByExerciseId,
  starExerciseChartUpload
} from "../../../../../services/starexercise";

import { Categories } from "../../../../pages/Resources/StarExercise/StarExerciseWizard/ExerciseQuestions";
import { ExerciseResultMatching } from "../StarExerciseWizard/ExerciseQuestions";
import { STUDENT } from "../../../../../constants";

const TimeLineLegend = {
  ENABLED: 1,
  DISABLED: 0
};

const StarExerciseResult = () => {
  const categories = Categories;
  const reverseMapping = ExerciseResultMatching;
  const [chartData, setChartData] = useState([]);
  const [pastCompletedExercises, setPastCompletedExercises] = useState([]);
  const [timelineData, setTimelineData] = useState({});
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartDataFilteredData, setLineChartDataFilteredData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [timeLineCategoriesList, setTimeLineCategoriesList] = useState({
    post_16_choices: 1,
    career_ideas: 1,
    career_planning: 1,
    skills: 1,
    vocational_awareness: 1,
    employability: 1,
    labour_market: 1,
    knowledge_of_fe_he: 1
  });
  const [starChartRef, setStarChartRef] = useState();
  const [starChartForPastStarRef, setStarChartForPastStarRef] = useState();
  const [chartHiddenDataDetails, setChartHiddenDataDetails] = useState({
    id: null,
    data: []
  });
  const { exerciseId } = useParams();

  useEffect(() => {
    if (pastCompletedExercises && pastCompletedExercises.length === 0) {
      return;
    }
    // Note: Any category name can be taken since all exercise will have same [{}] per category:
    const categoryName = categories[Object.keys(categories)[0]].name;
    const howManyExercisesAreCompleted = pastCompletedExercises[
      categoryName
    ].map(item => item.completion_date);
    setTimelineData(howManyExercisesAreCompleted);
  }, [pastCompletedExercises]);

  /**
   * GET: Previous Breakdown Sections
   */
  useEffect(() => {
    setLoading(true);
    getPreviousSections({})
      .then(response => {
        if (response.success) {
          const previousSectionResult = response.previous_section_result;
          setPastCompletedExercises(previousSectionResult);

          // LineChart Data:
          // Note: Since we are getting result from past exercise API so utilizing same date for Linechart
          let lineCharData = Object.keys(previousSectionResult)
            .map(category => {
              return previousSectionResult[category].map(item => {
                return {
                  ...item,
                  category: reverseMapping[category].category
                };
              });
            })
            .flat(1);

          lineCharData.sort(
            (a, b) => new Date(a.completion_date) - new Date(b.completion_date)
          );
          setLineChartData(lineCharData);
          setLineChartDataFilteredData(lineCharData);
        } else {
          notification.success({
            message: response.message || "Failed to fetch Previous data"
          });
        }
      })
      .catch(error =>
        console.log("StarExerciseDashboard.getPreviousExercises", error)
      )
      .finally(() => setLoading(false));
  }, []);

  /**
   * GET: Exercise Detail By ExerciseId
   */
  useEffect(() => {
    if (exerciseId) {
      setLoading(true);
      getStarExerciseDetailByExerciseId({
        body: {
          star_exercise_id: exerciseId
        }
      })
        .then(response => {
          if (response.success) {
            const { star_exercise } = response;

            const mappedDate = Object.keys(categories)
              .map(category => {
                return [
                  {
                    category: categories[category].category,
                    score: hasChildren(star_exercise[categories[category].name])
                      ? star_exercise[categories[category].name].pop().value
                      : 0
                  },
                  {
                    category: `${category}-mock`, // Note: This is more sort of a "HACK" to create star by giving a mock space:
                    score: 1
                  }
                ];
              })
              .flat(1);

            setChartData(mappedDate);
          } else {
            console.log("No result found");
          }
        })
        .catch(error => {
          console.log(
            "StarExerciseResult.getStarExerciseDetailByExerciseId",
            error
          );
        })
        .finally(() => setLoading(false));
    }
  }, [exerciseId]);

  useEffect(() => {
    let filteredData = [];
    const data = deepCopy(lineChartData); // original list
    Object.keys(timeLineCategoriesList).forEach(key => {
      if (timeLineCategoriesList[key] === TimeLineLegend.ENABLED) {
        const items = data.filter(data => {
          return data.category === reverseMapping[key].category;
        });
        filteredData = filteredData.concat(items);
      }
    });

    setLineChartDataFilteredData(filteredData);
  }, [timeLineCategoriesList]);

  const onCategoryClick = category => {
    setTimeLineCategoriesList({
      ...timeLineCategoriesList,
      [category]:
        timeLineCategoriesList[category] === TimeLineLegend.ENABLED ? 0 : 1
    });
  };

  const renderCategoriesTimeLine = () => {
    let count = 0;
    return (
      <Timeline className={"timeline"}>
        {Object.keys(timeLineCategoriesList).map((key, index) => {
          if (timeLineCategoriesList[key] === 0) {
            // Handle dynamically apply class as +1 increment occurs will be using `index-1` logic
            // Since we defined generic color codes in scss file `color<NO>`
            count = count + 1;
          }
          return (
            <Timeline.Item
              className={classNames(
                "cursor-pointer",
                {
                  tick: timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  [`color${index + 1 - count}`]:
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "post-16-category":
                    key === "post_16_choices" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "career-ideas-category":
                    key === "career_ideas" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "skills-category":
                    key === "skills" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "vocational-category":
                    key === "vocational_awareness" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "employability-category":
                    key === "employability" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "labour-market-category":
                    key === "labour_market" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                },
                {
                  "knowledge-of-fe-he-category":
                    key === "knowledge_of_fe_he" &&
                    timeLineCategoriesList[key] === TimeLineLegend.ENABLED
                }
              )}
              onClick={() => {
                onCategoryClick(reverseMapping[key].name);
              }}
            >
              <span>{reverseMapping[key].category}</span>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  const onTimelineIndexChange = index => {
    const mappedDate = Object.keys(categories)
      .map(category => {
        return [
          {
            category: categories[category].category,
            score:
              pastCompletedExercises[categories[category].name][index].value
          },
          {
            category: `${category}-mock`, // Note: This is more sort of a "HACK" to create star by giving a mock space:
            score: 1
          }
        ];
      })
      .flat(1);

    setChartData(mappedDate);
  };

  useEffect(() => {
    if (
      chartHiddenDataDetails.id &&
      chartHiddenDataDetails.data &&
      chartHiddenDataDetails.data.length > 0
    ) {
      setTimeout(() => {
        handlePastExerciseDownloadReport(chartHiddenDataDetails.id);
      }, 1000);
    }
  }, [starChartForPastStarRef, chartHiddenDataDetails.id]);

  const onPastExerciseClick = pastRecord => {
    const pastCompletedExercise = pastRecord.star_exercise;
    const mappedDate = Object.keys(categories)
      .map(category => {
        return [
          {
            category: categories[category].category,
            score: pastCompletedExercise[categories[category].name][0].value
          },
          {
            category: `${category}-mock`, // Note: This is more sort of a "HACK" to create star by giving a mock space:
            score: 1
          }
        ];
      })
      .flat(1);

    setChartHiddenDataDetails({
      id: pastRecord.id,
      data: mappedDate
    });
  };

  const handleDownloadReport = async event => {
    event.preventDefault();
    event.stopPropagation();

    const response = await uploadStarChart(exerciseId); // async part to upload handled inside chart component
    if (response.success) {
      const downloadURL = `${process.env.REACT_APP_API_URL}/star-exercise-download/${exerciseId}`;
      openLinkInNewTab(downloadURL);
    } else {
      console.log("Failed to upload star chart");
    }
  };

  /***
   *
   * @param exerciseId
   * @returns {Promise<void>}
   *
   * Generates Hidden chart and post it to BE and then generate URL to fetch new report based on past exercise clicked:
   */
  const handlePastExerciseDownloadReport = async exerciseId => {
    console.log("Past Exercise Id", exerciseId);
    const response = await uploadStarChart(exerciseId, true); // async part to upload handled inside chart component
    if (response.success) {
      const downloadURL = `${process.env.REACT_APP_API_URL}/star-exercise-download/${exerciseId}`;
      openLinkInNewTab(downloadURL);
    } else {
      console.log("Failed to upload star chart");
    }
  };

  const uploadStarChart = (exerciseId, isPastExercise = false) => {
    return new Promise((resolve, reject) => {
      const base64Image = isPastExercise
        ? getBase64ImagePastExercise()
        : getBase64Image();

      if (exerciseId && base64Image) {
        starExerciseChartUpload({
          body: {
            star_exercise_id: exerciseId,
            star_chart_image: base64Image
          }
        })
          .then(response => {
            if (response.success) {
              console.log("Star Chart uploaded Successfully!");
            } else {
              console.log("Star Chart failed to upload!!");
            }
            resolve({ success: true });
          })
          .catch(error => {
            reject({
              success: false,
              error: error
            });
            console.log("RadarChart.starExerciseChartUpload", error);
          });
      } else {
        console.log("Either exercise id or Image URI not found");
      }
    });
  };

  const getBase64Image = () => {
    const { current } = starChartRef;
    return current.toDataURL();
  };

  const getBase64ImagePastExercise = () => {
    const { current } = starChartForPastStarRef;
    return current.toDataURL();
  };

  const hasChildren = category => {
    return Array.isArray(category) && category.length > 0;
  };

  return (
    <HeaderWrapper
      title="Resources - Star Exercise"
      image={require("../../../../../assets/icons/header/icon-cogs.png")}
    >
      <PageMetaTags title="Resources - Star Exercise" />

      <div className="row bg-white ml-2 mr-2">
        <div className={"col-12"}>
          <div className={"row offset-md-8 mt-md-2 exercise-buttons"}>
            <div className="col-6">
              <RoleCheck authority={[STUDENT]}>
                <Link
                  to={"/starexercise/wizard"}
                  className={"btn btn-yellow cursor-pointer"}
                >
                  Take Star Exercise <i className={"fa fa-arrow-right"} />
                </Link>
              </RoleCheck>
            </div>
            <div className="col-6">
              <RoleCheck authority={[STUDENT]}>
                <a
                  className={"btn btn-yellow cursor-pointer"}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={handleDownloadReport}
                >
                  Download Exercise
                  <i className={"fa fa-download ml-2"} />
                </a>
              </RoleCheck>
            </div>
          </div>
        </div>

        {/*Star Section*/}
        <div className="col-12 col-lg-6 mb-4">
          <h5>Star Exercise Result</h5>
          <br />
          <Skeleton loading={loading}>
            <RadarChart
              exerciseId={exerciseId}
              chartData={chartData}
              setRef={chartRef => {
                setStarChartRef(chartRef);
              }}
            />
          </Skeleton>
        </div>

        {/*Past Section Breakdown*/}
        <div className="col-12 col-lg-6 mb-4">
          <div className={"row"}>
            <div className="col-12">
              <h5 style={{ fontSize: "16x", margin: "7px" }}>
                Past Section Breakdown
              </h5>
            </div>
          </div>
          <br />
          <Skeleton loading={loading}>
            <PreviousBreakDown
              className={"past-exercise"}
              pastExercises={pastCompletedExercises}
            />
          </Skeleton>
        </div>
      </div>

      {/*Past Exercise Timeline*/}
      {timelineData && timelineData.length > 0 && (
        <div className="row bg-white ml-2 mr-2 mt-10 p-md-5">
          <div className="col-12 p-12">
            <PastResultTimeline
              timelineData={timelineData}
              onTimelineIndexChange={onTimelineIndexChange}
            />
          </div>
        </div>
      )}

      {/*Past Exercises LineChart*/}
      {lineChartData && lineChartData.length > 0 && (
        <div className="row bg-white ml-2 mr-2 mt-10 p-md-5">
          <div className="col-12 p-12">
            <LineChart chartData={lineChartDataFilteredData} />
          </div>
        </div>
      )}

      {/*Past Exercises LineChart*/}

      <div className="row bg-white ml-2 mr-2 mt-10 p-md-5">
        <div className="col-12 p-6">{renderCategoriesTimeLine()}</div>
      </div>

      {/*Past Exercises*/}
      <div className="row bg-white ml-2 mr-2 mt-10 p-md-5">
        <div className="col-12 p-12">
          <PastExercises onPastExerciseClick={onPastExerciseClick} />
        </div>
      </div>

      <br />
      <br />

      {/*
         DON'T REMOVE IT THIS HIDDEN RADAR CHART IS DOING FOLLOWING PURPOSE:
        - Generating hidden chart based on `chartHiddenDataPastExercise`
        - `setRef` will update chart reference and which will through which base64 image
           will be generated and upload STAR chart in BE by async task.
        -  which will redirect and open report URL.
      */}
      {chartHiddenDataDetails.id && (
        <div style={{ display: "none" }}>
          <RadarChart
            exerciseId={chartHiddenDataDetails.id}
            isPastExercise={true}
            chartData={chartHiddenDataDetails.data}
            setRef={chartRef => {
              setStarChartForPastStarRef(chartRef);
            }}
          />
        </div>
      )}
    </HeaderWrapper>
  );
};

export { StarExerciseResult };
