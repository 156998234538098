import React, { useEffect, useState } from "react";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import { Col, notification, Row, Skeleton } from "antd";
import { withContext } from "Context";
import { getProfileDetails } from "../../../../services/user";
import { Link } from "react-router-dom";
import BookAppointmentModal from "../../Appointments/BookAppointmentModal";

const MyAdviser = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const userId = user.adviser_id;
    if (userId) {
      setLoading(true);
      getProfileDetails({
        pathParams: {
          userId: userId
        }
      })
        .then(response => {
          setProfileDetails(response);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.error({ message: "No adviser is assigned" });
    }
  }, [user.adviser_id]);

  return (
    <HeaderWrapper
      title="My Adviser"
      image={require("../../../../assets/icons/header/icon-profile.png")}
    >
      {modal && (
        <BookAppointmentModal
          open={modal}
          onClose={() => setModal(false)}
          bookingFor="adviser"
          userId={user.adviser_id}
          advisor={{ id: user.adviser_id }}
        />
      )}
      <Row gutter={[24, 24]} className="mb-4">
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <div className="bg-white rounded-lg h-full p-4 mb-4">
            <Skeleton loading={loading}>
              <div className="flex text-gray-600 text-lg">
                <Link to="/adviser">
                  <div className="flex items-center text-gray-600">
                    <i className="fas fa-search-plus text-3xl" />
                    <span className="pl-2"> Adviser Search</span>
                  </div>
                </Link>
                <div
                  onClick={() => setModal(true)}
                  className="cursor-pointer ml-8 flex items-center"
                >
                  <img
                    src={require("../../../../assets/icons/sidebar/AddApptIcon.svg")}
                    className="h-8 w-8"
                    alt="calendar"
                  />
                  <span className="pl-2"> Book Appointment</span>
                </div>
              </div>
              <div className="mt-10">
                <div className="text-gray-600">Name</div>
                <div className="mt-2">
                  <div className="font-bold text-black">
                    {(profileDetails && profileDetails.user_data.name) || ""}
                  </div>
                  <div className="font-semibold">
                    {profileDetails && (
                      <Link to={`/emails/new?id=${user.adviser_id}`}>
                        Email Adviser
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <div className="text-gray-600">Location</div>
                <div className="mt-2">
                  {profileDetails &&
                    profileDetails.school_data &&
                    profileDetails.school_data.map((school, index) => (
                      <div key={index} className="text-gray-900 font-semibold">
                        <div>{school.school_name}</div>
                        <div>{school.school_address_line_1}</div>
                        <div>{school.school_address_line_2}</div>
                      </div>
                    ))}
                </div>
              </div>
              {profileDetails && profileDetails.user_data.specialism && (
                <div className="mt-6">
                  <div className="text-gray-600">Specialism</div>
                  <div className="text-gray-900 font-semibold mt-2">
                    <span>{profileDetails.user_data.specialism}</span>
                  </div>
                </div>
              )}
            </Skeleton>
          </div>
        </Col>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <div className="h-full">
            <Link to="/destination-tracker/student-data">
              <div className="bg-white rounded-lg text-gray-500">
                <div className="text-4xl p-4 font-thin">About Me...</div>
                <div className="text-sm mt-2">
                  <img
                    alt="about me"
                    className="h-auto rounded-br-lg rounded-bl-lg object-cover w-full"
                    src={require("../../../../assets/images/aboutme.png")}
                  />
                </div>
              </div>
            </Link>
            <Link to="/destination-tracker/september-guarantee">
              <div className="bg-white rounded-lg text-gray-500 mt-4">
                <div className="text-4xl p-4 font-thin">My Choices...</div>
                <div className="text-sm mt-2">
                  <img
                    alt="about me"
                    className="h-56 rounded-br-lg rounded-bl-lg object-cover w-full"
                    src={require("../../../../assets/icons/cards/my_choices.jpg")}
                  />
                </div>
              </div>
            </Link>
          </div>
        </Col>
      </Row>
    </HeaderWrapper>
  );
};

export default withContext(
  ([{ user }]) => ({
    user
  }),
  MyAdviser
);
