import { Radar } from "@ant-design/plots";
import React, { useEffect, useRef, useState } from "react";

const RadarChart = ({ chartData, setRef }) => {
  const chartRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(chartData);
    setRef(chartRef);
  }, [chartData]);

  const config = {
    data,
    xField: "category",
    yField: "score",
    seriesField: "date",
    width: 440,
    height: 300,
    tooltip: {
      customContent: (title, data) => {
        // Note: Don't show tooltip for `-mock` categories
        if (!title && !(data && data.length > 0)) {
          return null;
        }
        return title.indexOf("-mock") > -1
          ? null
          : `<div> <b>${title}: &nbsp; ${data.pop().value} </b> </div>`;
      }
    },
    lineStyle: {
      stroke: "#f6b743",
      fill: "#f6b743",
      fillOpacity: 1,
      lineWidth: 2,
      strokeOpacity: 0.7,
      cursor: "pointer"
    },
    meta: {
      score: {
        min: 0,
        max: 5
      }
    },
    xAxis: {
      line: null,
      tickLine: null,
      label: {
        formatter: value => {
          return value.indexOf("-mock") > -1 ? null : value;
        }
      }
    },
    yAxis: {
      line: null,
      tickLine: null,
      grid: {
        line: {
          type: "line",
          style: {
            lineDash: null
          }
        },
        alternateColor: "rgba(0, 0, 0, 0.04)"
      }
    },
    point: {
      size: 3
    }
  };

  return (
    <React.Fragment>
      <Radar {...config} chartRef={chartRef} />
    </React.Fragment>
  );
};

export { RadarChart };
