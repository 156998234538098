import React from "react";
import moment from "moment";
import { MomentDateFormats } from "../../../../../constants";

function AppointmentCallHeader({
  title,
  startTime,
  appointmentDetails,
  children
}) {
  return (
    <div style={{ fontSize: 18 }} className="page-heading m-4">
      {title} {appointmentDetails.appointment_name.advisor_name} and{" "}
      {appointmentDetails.appointment_name.student_name ||
        appointmentDetails.appointment_name.school_admin_name}{" "}
      at {moment(startTime).format(MomentDateFormats.LongDateFormat.LLLL)}
      {children}
    </div>
  );
}

export { AppointmentCallHeader };
