import React, { useEffect, useState } from "react";
import { Modal, notification, Spin, Table, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { PageMetaTags } from "../../../common";
import { uploadImportSheet } from "../../../../services/common";

const { Dragger } = Upload;
const ACCEPTED_EXTENSIONS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "csv"
];

function ImportData(props) {
  const pageHeading = "Import Data";
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const uploadProps = {
    accept: ACCEPTED_EXTENSIONS,
    multiple: false,
    disabled: uploading,
    onRemove: file => {
      setFile(null);
      notification.info({
        message: `${file.name} is removed`
      });
    },
    beforeUpload: file => {
      setUploading(true);
      setFile(file);
      return false;
    }
  };

  useEffect(() => {
    if (!file) {
      return;
    }
    uploadTaskDocument(file);
  }, [file]);

  const getHelpInfoModal = data => {
    const dataSource = data.logs;

    const columns = [
      {
        title: "Row number",
        dataIndex: "row_num",
        key: "row_num"
      },
      {
        title: "Profile email",
        dataIndex: "profile_email",
        key: "profile_email"
      },
      {
        title: "Process section",
        dataIndex: "process_section",
        key: "process_section"
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason"
      }
    ];

    const modal = Modal.error({
      title: "Import data process error log",
      width: "700",
      content: (
        <div>
          <Table dataSource={dataSource} columns={columns} />
        </div>
      ),
      afterClose: () => {
        modal.destroy();
      }
    });
  };

  const uploadTaskDocument = file => {
    const data = new FormData();
    data.append("import_sheet", file);
    setIsLoading(true);
    uploadImportSheet(data)
      .then(res => {
        if (res.logs.length > 0) {
          getHelpInfoModal(res);
        } else {
          notification.success({
            message: res.message || "Successfully uploaded file"
          });
        }
      })
      .catch(err => {
        notification.error({
          message: err.data.message || "Failed to upload file"
        });
      })
      .finally(() => {
        setIsLoading(false);
        setUploading(false);
      });
  };

  return (
    <div className="content-panel">
      <PageMetaTags title={pageHeading} />
      <h1 className="page-heading">{pageHeading}</h1>
      <Spin spinning={isLoading} style={{ paddingLeft: "50%" }}></Spin>
      <div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          style={{ paddingRight: "36px", paddingTop: "7px" }}
          className="float-right"
          href={require("../../../../assets/images/iprogress_import_template_2022.xlsx")}
        >
          Click here to download a data import template{" "}
          <i className={"fa fa-download ml-2"} />
        </a>
      </div>
      <div className="profile-wrapper">
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single upload.</p>
        </Dragger>
      </div>
    </div>
  );
}

export { ImportData };
