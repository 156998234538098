import React from "react";
import AdviserListCard from "./AdviserListCard/AdviserListCard";
import DashboardLinks from "../DashboardLinks/DashboardLinks";
import AppointmentCalendar from "../../Appointments/AppointmentCalendar/AppointmentCalendar";

const links = [
  {
    name: "Adviser Search",
    desc: "Search for Adviser",
    path: "/adviser",
    icon: "fas fa-search"
  },
  {
    name: "Student Search",
    desc: "Search for students",
    path: "/students",
    icon: "fas fa-search"
  }
];

const TeacherDashboard = ({ user }) => {
  return (
    <div className="content-panel">
      <h1 className="page-heading">
        Welcome back {user && user.name.split(" ")[0]}
      </h1>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mb-4">
          <AdviserListCard />
        </div>
        <div className="col-lg-12 col-xl-4 mb-4">
          <DashboardLinks links={links} />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-xl-8 mb-4">
          <div className="white-box white-box--height-default p-0">
            <div className="calender-wrapper">
              <AppointmentCalendar events={[]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherDashboard;
