import React from "react";
import { OTPublisher } from "opentok-react";
import { notification } from "antd";
import {
  startRecording,
  stopRecording
} from "../../../../../services/appointment";

class Publisher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      // audio: true,
      video: true,
      publisher: null
    };

    this.otPublisher = React.createRef();

    // Publisher Properties
    this.publisherProperties = {
      name: "Publisher",
      facingMode: "user",
      fitMode: "contain",
      resolution: "320x240",
      width: 300,
      height: 300,
      style: {
        audioLevelDisplayMode: "on",
        buttonDisplayMode: "on",
        nameDisplayMode: "on"
      }
    };

    // Publisher Events
    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log(
          "Event.Publisher.accessDenied => User denied access to media source"
        );
      },
      streamCreated: () => {
        console.log(
          "Event.Publisher.streamCreated => publisher stream created"
        );
      },
      streamDestroyed: ({ reason }) => {
        console.log(
          `Event.Publisher.streamDestroyed => Publisher stream destroyed due to ${reason}`
        );
      }
    };

    this.forceUpdate = () => {
      setTimeout(() => this.setState({}), 100);
    };

    this.onPublish = () => {
      console.log("Publisher.onPublish");
      this.addMuteListener();
      this.startRecording();
    };
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.handleUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);
    const muteButton = this.getMuteButton();
    if (muteButton) {
      muteButton.removeEventListener("click", this.forceUpdate);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPublisherMuted !== prevProps.isPublisherMuted) {
      this.forceUpdate();
    }
  }

  isPublisherAudioActive() {
    const muteButton = this.getMuteButton();
    if (!muteButton) {
      return false;
    }

    return muteButton.classList.contains("OT_active");
  }

  getPublisherElement() {
    if (!this.otPublisher.current) {
      return null;
    }

    const { publisher } = this.otPublisher.current.state;
    if (!publisher) {
      return null;
    }

    return publisher.element;
  }

  getMuteButton() {
    const publisherElement = this.getPublisherElement();
    if (publisherElement) {
      return publisherElement.querySelector(".OT_mute");
    }

    return null;
  }

  addMuteListener() {
    const muteButton = this.getMuteButton();
    if (muteButton) {
      muteButton.addEventListener("click", this.forceUpdate);
    }
  }

  setAudio = event => {
    event.preventDefault();

    const muteButton = this.getMuteButton();
    if (!muteButton) {
      return;
    }

    muteButton.click();

    this.forceUpdate();
  };

  setVideo = event => {
    event.preventDefault();
    this.setState({
      video: !this.state.video
    });
  };

  onError = err => {
    console.log("Publisher.onError => publisher failed to publish");
    this.setState({ error: `Failed to publish: ${err.message}` });
  };

  endCall = () => {
    this.getPublisher().then(() => {
      if (this.state.publisher) {
        if (this.props.history) {
          setTimeout(() => {
            this.stopRecording();
            this.state.publisher.destroy();
            this.props.history.push("/dashboard");
          }, 1000);
        }
      }
    });
  };

  getPublisher() {
    return new Promise((resolve, reject) => {
      if (this.otPublisher && this.otPublisher.current) {
        this.setState(
          {
            publisher: this.otPublisher.current.getPublisher()
          },
          () => {
            resolve();
          }
        );
      }
    });
  }

  handleUnload = ev => {
    ev.preventDefault();
    this.stopRecording();
    return (ev.returnValue = "Are you sure you want to close?");
  };

  changeArchvingStatus() {
    const publisherElement = this.getPublisherElement();
    if (publisherElement) {
      const archivingStatuses = Array.from(
        publisherElement.querySelectorAll(".OT_archiving-status")
      );
      archivingStatuses.forEach(archivingStatusElement => {
        archivingStatusElement.textContent = "Recording session";
      });
    }
  }

  startRecording = () => {
    console.log("recording started");
    this.changeArchvingStatus();

    startRecording({
      body: { appointment_id: this.props.appointmentDetails.appointment.id }
    });
  };

  stopRecording = async () => {
    console.log("recording stopped", this.props);
    const currentUser = await JSON.parse(localStorage.getItem("user"));

    stopRecording({
      body: {
        user_id: currentUser.id,
        appointment_id: this.props.appointmentDetails.appointment.id
      }
    })
      .then(() => {
        notification.success({ message: "Webinar call ended!" });
        if (this.props.history) {
          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        }
      })
      .catch(error => {
        console.log("Publisher.stopRecording", error);
      });
  };

  render() {
    return (
      <div>
        {this.state.error ? <div>{this.state.error}</div> : null}
        <OTPublisher
          ref={this.otPublisher}
          onInit={() => console.log("Publisher.onInit")}
          properties={{
            // publishAudio: this.state.audio,
            publishVideo: this.state.video,
            ...this.publisherProperties,
            ...this.props.publisherProperties
          }}
          onPublish={this.onPublish}
          onError={this.onError}
        />

        <div
          className={"d-flex justify-content-between mt-2 mt-2"}
          style={{ width: "300px" }}
        >
          <button
            className={"btn btn-sm text-white bg-black"}
            onClick={this.setVideo}
          >
            {this.state.video ? "Disable" : "Enable"} Video
          </button>

          <button
            className={"btn btn-sm text-white bg-black pubaudio ml-2"}
            onClick={this.setAudio}
          >
            {this.isPublisherAudioActive() ? "Enable" : "Disable"} Audio
          </button>

          <button
            className={"btn btn-sm text-white bg-black ml-2"}
            onClick={this.endCall}
          >
            End call
          </button>
        </div>
      </div>
    );
  }
}

Publisher.defaultProps = {
  publisherProperties: {}
};

export default Publisher;
