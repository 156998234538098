import React, { useState } from "react";
import { withContext } from "Context";
import { Avatar, Spin, Upload } from "antd";
import { PageMetaTags } from "Common";
import { uploadProfilePic } from "Services/user";
import { profileUpdateAction } from "../../../../store/actions/profileActions";
import { getInitials } from "../../../../utils";
import { Link } from "react-router-dom";
import { ADVISER } from "../../../../constants";

const AdvisorProfile = ({ user, updateProfile }) => {
  const [loading, setLoading] = useState(false);

  const uploadFile = file => {
    setLoading(true);
    const body = new FormData();
    body.append("profile_pic", file);
    uploadProfilePic({ body })
      .then(res => {
        updateProfile({ profile_pic_path: res.profile_pic });
        setLoading(false);
      })
      .catch(() => setLoading(false));
    return false;
  };

  const renderExtraInfo = () => {
    return (
      <React.Fragment>
        {/*Todo: (hide static data) */}
        {/*<div className="info-box">*/}
        {/*  <h4 className="small-heading mb-1">Bio</h4>*/}
        {/*  <div className="address">*/}
        {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do*/}
        {/*    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim*/}
        {/*    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut*/}
        {/*    aliquip ex ea commodo consequat.*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="info-box">*/}
        {/*  <h4 className="small-heading mb-1">REGIONS COVERED</h4>*/}
        {/*  <div className="address">*/}
        {/*    Buckinghamshire, Berkshire, Northamptonshire*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="info-box">*/}
        {/*  <h4 className="small-heading mb-1">Specialism</h4>*/}
        {/*  <div className="degree">*/}
        {/*    <span>Graphic design</span>, <span>Web design</span>,{" "}*/}
        {/*    <span>Art &amp; design</span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Link to="/appointments/settings" className="large-button">
          <span>
            <i className="far fa-calendar-alt" />
          </span>{" "}
          Appointment Settings
        </Link>
      </React.Fragment>
    );
  };

  return (
    <div data-cy="profile-page" className="content-panel">
      <PageMetaTags title="Profile" />
      <h1 className="page-heading">Your Profile</h1>
      <div className="profile-wrapper">
        <div className="info-box">
          <h4 className="small-heading">Name</h4>
          <div className="pro-place-inner">
            <Upload beforeUpload={uploadFile} fileList={[]}>
              <div className="picture">
                <Spin spinning={loading}>
                  <Avatar
                    style={{ background: "#16975f" }}
                    src={user && user.profile_pic_path && user.profile_pic_path}
                    size={70}
                  >
                    {getInitials(user && user.name)}
                  </Avatar>
                </Spin>
              </div>
            </Upload>
            <div className="info">
              <h2 className="page-sub-heading">{user && user.name}</h2>
              <a href={`mailto:${user.email}`} className="email">
                {user && user.email}
              </a>
            </div>
          </div>
        </div>

        {user &&
          user.school_data &&
          user.school_data.length > 0 &&
          user.school_data.map(school => (
            <div className="info-box">
              <h4 className="small-heading">{school.school_name}</h4>
              <div className="address">
                <p>{school.school_address_line_1}</p>
                <p>{school.school_address_line_2}</p>
              </div>
            </div>
          ))}
        {user && user.role_slug === ADVISER && renderExtraInfo()}
      </div>
    </div>
  );
};

export default withContext(
  ([{ user }, dispatch]) => ({
    user,
    updateProfile: data => profileUpdateAction(data, dispatch)
  }),
  AdvisorProfile
);
