import React, { useEffect, useState } from "react";
import { getUserDetailsDt } from "../../../services/common";
import { Breadcrumbs } from "../../../components/common";
import { Link } from "react-router-dom";

const renderStudentMetaData = studentId => {
  const [studentMetadata, setStudentMetadata] = useState();

  useEffect(() => {
    if (studentId) {
      getUserDetailsDt({ pathParams: { userId: studentId } })
        .then(response => {
          setStudentMetadata(response);
        })
        .catch(error => console.log(error));
    }
  }, []);

  if (!studentMetadata) {
    return;
  }
  return (
    <div className="info mb-4">
      <h4 className="page-sub-heading">{studentMetadata.name || "N/A"}</h4>
      {studentId && (
        <Link to={`/emails/new?id=${studentId}`}>Email Student</Link>
      )}
    </div>
  );
};

const renderDestinationTrackerBreadcrumbs = studentId => {
  if (!studentId) {
    return;
  }
  return (
    <Breadcrumbs
      breadcrumbs={[
        {
          name: "Students list",
          path: `/students`
        },
        {
          name: "Student profile",
          path: `/students/${studentId}`
        },
        {
          name: "Destination tracker",
          path: null
        }
      ]}
    />
  );
};

export { renderStudentMetaData, renderDestinationTrackerBreadcrumbs };
