import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, withRouter } from "react-router-dom";
import { withContext } from "Context";
import { Routes } from "Constants";
import { restoreUserAction } from "Actions/authActions";
import { GuestRoute, PrivateRoute } from "Helpers/routes";
import { CustomToast, Loader } from "Common";
import Login from "Pages/Login";
import { ToastProvider } from "react-toast-notifications";
import ProLayout from "../common/ProLayout";
import { currentUser } from "../../services/user";
import { profileUpdateAction } from "../../store/actions/profileActions";
import { navigationRoutes } from "../../constants/navigationRoutes";
import { Redirect, Route } from "react-router";

const RootRoute = ({ location, appLoading, loggedIn, restoreUser }) => {
  // track pageviews via GA
  useEffect(() => {
    if (location.pathname == "/") {
      return;
    }

    let gaProps = {
      send_page_view: false,
      custom_map: {
        dimension1: "school_name",
        dimension2: "year_group",
        dimension3: "adviser_id",
        dimension4: "user_role"
      }
    };

    let pageviewProps = {
      page_path: location.pathname
    };

    // if logged in, set GA user id and custom dimensions
    /*global gtag*/
    currentUser()
      .then(res => {
        gaProps.user_id = res.user.id;

        // dimension1 - school name
        pageviewProps.school_name = res.user.school_data[0].school_name;

        // dimension2 - year group
        pageviewProps.year_group = res.user.year_group;

        // dimension3 - adviser id
        pageviewProps.adviser_id = res.user.adviser_id;

        // dimension4 - user role
        pageviewProps.user_role = res.user.role_slug;

        // config
        gtag("config", "UA-204793464-4", gaProps);

        // pageview
        gtag("event", "page_view", pageviewProps);
      })
      .catch(() => {
        // config
        gtag("config", "UA-204793464-4", gaProps);

        // pageview
        gtag("event", "page_view", pageviewProps);
      });
  }, [location]);

  useEffect(() => {
    if (localStorage.accessToken) {
      currentUser()
        .then(res => {
          restoreUser(res.user);
        })
        .catch(() => restoreUser());
    } else {
      restoreUser();
    }
  }, []);

  if (appLoading) {
    return <Loader />;
  }

  return (
    <ToastProvider components={{ ToastContainer: CustomToast }}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>

        <GuestRoute
          path={Routes.LOGIN}
          loggedIn={loggedIn}
          location={location}
          component={Login}
        />
        <GuestRoute
          path={Routes.REGISTRATION}
          loggedIn={loggedIn}
          location={location}
          component={Login}
        />
        <GuestRoute
          path={Routes.FORGOT_PASSWORD}
          loggedIn={loggedIn}
          location={location}
          component={Login}
        />
        <GuestRoute
          path="/update-password"
          loggedIn={loggedIn}
          location={location}
          component={Login}
        />

        <ProLayout>
          {(navigationRoutes || []).map(route => (
            <PrivateRoute
              key={route.path}
              path={route.path}
              loggedIn={loggedIn}
              location={location}
              component={route.component}
            />
          ))}
        </ProLayout>
        <Redirect to="/login" />
      </Switch>
    </ToastProvider>
  );
};

RootRoute.propTypes = {
  location: PropTypes.shape().isRequired,
  appLoading: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  restoreUser: PropTypes.func.isRequired
};

export default withRouter(
  withContext(
    ([
      {
        app: { loading },
        user: { loggedIn }
      },
      dispatch
    ]) => ({
      // state
      loggedIn,
      appLoading: loading,
      // actions
      restoreUser: data => restoreUserAction(data, dispatch),
      updateProfile: data => profileUpdateAction(data, dispatch)
    }),
    RootRoute
  )
);
