const Routes = {
  // Auth Routes
  LOGIN: "/login",
  REGISTRATION: "/registration",
  RESET_PASSWORD: "/reset-password",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD_CONFIRM: "/password/reset/:token",
  DESTINATION_TRACKER: {
    PRE_16: {
      KS4_DATA: "/destination-tracker/ks4-data",
      KS4_QUESTIONNAIRE: "/destination-tracker/ks4-questionnaire",
      Y11_DASHBOARD: "/destination-tracker/y11-dashboard"
    },
    POST_16: {
      KS5_STUDIES: "/destination-tracker/ks5-studies",
      MAY_RETURN_LIVE_DASHBOARD:
        "/destination-tracker/may-return-live-dashboard",
      POST_16_QUESTIONNAIRE: "/destination-tracker/post-16-questionnaire",
      SUBJECT_OPTIONS_UCAS: "/destination-tracker/subject-option-ucas"
    },
    BOTH: {
      INFORMATION_NOTES: "/destination-tracker/information-notes",
      SEPTEMBER_GUARANTEE: "/destination-tracker/september-guarantee",
      OCTOBER_ACTIVITY_SURVEY: "/destination-tracker/october-activity-survey",
      STUDENT_DATA: "/destination-tracker/student-data"
    }
  },
  REPORTS: {
    PRE16: {
      YEAR10DASHBOARD: "/reports/dashboard-y10",
      YEAR11DASHBOARD: "/reports/dashboard-y11",
      YEAR11LDASHBOARD: "/reports/dashboard-y11-leaver",
      YEAR12DASHBOARD: "/reports/dashboard-y12",
      YEAR13DASHBOARD: "/reports/dashboard-y13",
      YEAR13LDASHBOARD: "/reports/dashboard-y13-leaver",
      Y10_DASHBOARD: "/reports/y10-dashboard",
      Y11_LIVE_DASHBOARD: "/reports/y11-live-dashboard",
      Y11_L_LIVE_DASHBOARD: "/reports/y11-leaver-dashboard",
      SEPTEMBER_GUARANTEE: "/reports/september-guarantee",
      OCTOBER_ACTIVITY_SURVEY: "/reports/october-survey",
      FSM_DASHBOARD: "/reports/fsm-dashboard",
      PP_DASHBOARD: "/reports/pp-dashboard",
      SEND_DASHBOARD: "/reports/send-dashboard",
      LAC_DASHBOARD: "/reports/lac-dashboard",
      EAL_DASHBOARD: "/reports/eal-dashboard"
    },
    POST_Y12_DASHBOARD: "/reports/y12-dashboard",
    POST_Y13_DASHBOARD: "/reports/y13-dashboard",
    POST_Y13_L_DASHBOARD: "/reports/y13-leaver-dashboard",
    POST_SETP_GURANTEE: "/reports/post16-september-guarantee",
    POST_OCTOBER_SUR: "/reports/post16-october-survey",
    POST_BURSARY: "/reports/post16-bursary",
    POST_SEND: "/reports/post16-send",
    POST_LAC: "/reports/post16-lac",
    POST_EAL: "/reports/post16-eal"
  }
};

export { Routes };
