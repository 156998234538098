import { GCSE_OPTIONS, SECTORS, TYPE } from "../common";

const formItems = {
  form_visible: 1, // Default check to make it editable as fields rather label
  course: {
    name: "course",
    label: "COURSE NAME",
    placeholder: "Input Course",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  provider: {
    name: "provider",
    label: "WHERE AM I GOING NEXT?",
    placeholder: "Input Provider",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  offer_made: {
    name: "offer_made",
    label: "HAVE THEY OFFERED ME A PLACE?",
    checkedChildren: "YES",
    unCheckedChildren: "NO",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.switch
  },
  russell_group: {
    name: "russell_group",
    label: "Russell Group",
    checkedChildren: "YES",
    unCheckedChildren: "NO",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.switch
  },
  elephant_group: {
    name: "elephant_group",
    label: "Elephant Group",
    checkedChildren: "YES",
    unCheckedChildren: "NO",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.switch
  },
  destination: {
    name: "destination",
    label: "WHAT TYPE OF PLACE AM I GOING TO?",
    placeholder: "Select Destination",
    editable: true,
    visibile: true,
    value: null,
    options: GCSE_OPTIONS,
    type: TYPE.select
  },
  level: {
    name: "level",
    label: "WHAT LEVEL COURSE IT IS?",
    placeholder: "Select Level",
    editable: true,
    visibile: true,
    value: null,
    options: [
      { label: "3", value: "3" },
      { label: "2", value: "2" },
      { label: "1", value: "1" },
      { label: "Entry", value: "entry" }
    ],
    type: TYPE.select
  },
  sector: {
    name: "sector",
    label: "Sector",
    placeholder: "Select Sector",
    editable: true,
    visibile: true,
    value: null,
    options: SECTORS,
    type: TYPE.select
  }
};

export { formItems, TYPE };
