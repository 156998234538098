import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { Link } from "react-router-dom";
import SentList from "./SentList";
import { getEmails } from "../../../../services/user";
import { PageMetaTags } from "Common";
const { TabPane } = Tabs;

const ListEmails = ({ user, match }) => {
  const [loading, setLoading] = useState(false);
  const [emailType, setEmailType] = useState("received-email-list");
  const [emailList, setEmailList] = useState();
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    setLoading(true);
    getEmails({
      pathParams: { emailType },
      body: { page_limit: limit, page: current }
    })
      .then(res => {
        setEmailList(res);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      });
  }, [emailType, current, limit]);

  return (
    <div data-cy="emailslist-page" className="content-panel">
      <PageMetaTags title="Emails" />
      <Row justify="space-between">
        <Col>
          <h1 className="page-heading">Emails</h1>
        </Col>
        <Col>
          <Link to="/emails/new">
            <input
              type="submit"
              className="button-style button-style-green-btm button-style-normal-button"
              value="Send Email"
            />
          </Link>
        </Col>
      </Row>

      <div className="py-2 bg-white">
        <Tabs
          tabBarStyle={{ margin: 0 }}
          defaultActiveKey="received-email-list"
          onChange={key => {
            setEmailType(key);
            setCurrent(1);
            setLimit(10);
          }}
        >
          <TabPane
            tabKey="received-email-list"
            tab={<span className="pl-2">Received Emails</span>}
            key="received-email-list"
          >
            <SentList
              emails={emailList}
              loading={loading}
              setCurrent={setCurrent}
              current={current}
              setLimit={setLimit}
              limit={limit}
              sent={false}
              setEmail={setEmailList}
            />
          </TabPane>
          <TabPane
            tabKey="sent-email-list"
            tab={<span className="pl-2">Sent Emails</span>}
            key="sent-email-list"
          >
            <SentList
              emails={emailList}
              loading={loading}
              setCurrent={setCurrent}
              current={current}
              setLimit={setLimit}
              limit={limit}
              setEmail={setEmailList}
              sent
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), ListEmails);
