import React from "react";
import { PageMetaTags } from "Common";

const HeaderWrapper = ({ children, title, image }) => {
  return (
    <div>
      <PageMetaTags title={title} />
      <div
        style={{ background: "#ffa62c" }}
        className="rounded-lg text-white my-6 flex items-center"
      >
        <div className="font-bold py-8 px-4 text-3xl">{title}</div>
        {image && <img src={image} alt="img" className="h-24" />}
      </div>
      {children}
    </div>
  );
};

export default HeaderWrapper;
