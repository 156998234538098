import React from "react";
import { Modal } from "antd";
import PropTypes from "prop-types";

const ModalWrapper = props => {
  if (!props) {
    return;
  }

  return (
    <div
      data-cy={props["data-cy"] || ""}
      className="modal fade"
      id="modalWrapper"
    >
      <Modal
        title={props.title}
        bodyStyle={props.bodyStyle}
        width={props.width}
        footer={props.footer}
        centered={props.centered}
        visible={props.visible}
        destroyOnClose={props.destroyOnClose}
        onCancel={props.onCancel}
        afterClose={props.afterClose}
      >
        {props.children}
      </Modal>
    </div>
  );
};

ModalWrapper.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  footer: PropTypes.any,
  visible: PropTypes.bool,
  centered: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
  onCancel: PropTypes.func.isRequired
};

ModalWrapper.defaultProps = {
  title: "Default Modal Title",
  width: 400,
  footer: null,
  visible: false,
  centered: true,
  destroyOnClose: true,
  afterClose: () => {
    console.log("Default afterClose invoked");
  }
};

export { ModalWrapper };
