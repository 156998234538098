import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { withContext } from "Context";
import { Y10Dashboard } from ".././Y10Dashboard";
import { SeptemberGuarantee } from ".././SeptemberGuarantee";
import { OctoberActivitySurvey } from ".././OctoberActivitySurvey";
import { Pre16Reports } from ".././Pre16Reports";
import Post16Reports from ".././Post16Reports";
import {
  ADVISER,
  Routes,
  SCHOOL_ADMIN,
  SUPER_ADMIN
} from "../../../../constants";
import { Col, Row } from "antd";
import { PageMetaTags } from "Common";
//import { reports } from "../../common/SideBar/routes";
import ".././style.scss";

const Year13Dashboard = ({ user }) => {
  const routes = [
    {
      path: Routes.REPORTS.PRE16.Y10_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Y10Dashboard
    },
    {
      path: Routes.REPORTS.PRE16.SEPTEMBER_GUARANTEE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: SeptemberGuarantee
    },
    {
      path: Routes.REPORTS.PRE16.OCTOBER_ACTIVITY_SURVEY,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: OctoberActivitySurvey
    },
    {
      path: Routes.REPORTS.PRE16.Y11_LIVE_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.Y11_LIVE_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.FSM_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.PP_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.SEND_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.LAC_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.EAL_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.POST_Y12_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_Y13_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_BURSARY,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_EAL,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_LAC,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_OCTOBER_SUR,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_SEND,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_SETP_GURANTEE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    }
  ];

  /*
  Note:
  This HOC will allow to pass data to all routes instead of using
  `withContext` on each component to get redux store e.g user
 */
  const withUser = (WrappedComponent, rest) => {
    return props => {
      return <WrappedComponent {...props} {...rest} />;
    };
  };

  const renderUI = () => (
    <Switch>
      {routes.map(route => {
        if (route.role.includes(user.role_slug)) {
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={withUser(route.component, { user })}
            />
          );
        }
      })}
      <Route path="/reports">
        <div className="content-panel">
          <PageMetaTags title="Report" />
          <h1 className="page-heading">Reports</h1>
          <div className="container">
            <p className="report-top-para">
              These Reports will detail the latest known intended destination
              for Students.
            </p>
            <Row
              gutter={[36, 36]}
              className="col-12"
              style={{ paddingBottom: "20px" }}
            >
              <Col xl={6} lg={6} md={12} sm={24} xs={24}></Col>
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={24}
                xs={24}
                style={{ marginLeft: "60px" }}
              >
                <Link
                  to="/reports/y13-dashboard?year_group=13"
                  className="showclass"
                  target={"_blank"}
                >
                  <div style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                    <img
                      alt="year 13 dashboard"
                      src={require("../../../../assets/images/report/year-13-dashbaord.png")}
                    />
                  </div>
                </Link>
              </Col>
              <Col xl={6} lg={6} md={12} sm={24} xs={24}></Col>
            </Row>
            <p className="pheight"></p>
            <Row
              gutter={[21, 21]}
              className="col-12"
              style={{ paddingBottom: "26px" }}
            >
              <Col
                xl={4}
                lg={3}
                md={12}
                sm={21}
                xs={21}
                style={{
                  marginRight: "-10.5px",
                  paddingTop: "10.5px",
                  paddingBottom: "10.5px"
                }}
              >
                <Link
                  to="/reports/post16-september-guarantee?year_group=13"
                  target={"_blank"}
                >
                  <div>
                    <img
                      alt="post 16 sept"
                      src={require("../../../../assets/images/report/post-16-sept.png")}
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={4}
                lg={3}
                md={12}
                sm={21}
                xs={21}
                style={{
                  marginRight: "-10.5px",
                  paddingTop: "10.5px",
                  paddingBottom: "10.5px"
                }}
              >
                <Link
                  to="/reports/post16-october-survey?year_group=13"
                  target={"_blank"}
                >
                  <div>
                    <img
                      alt="post-16-oct"
                      src={require("../../../../assets/images/report/post-16-oct.png")}
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={4}
                lg={3}
                md={12}
                sm={21}
                xs={21}
                style={{
                  marginRight: "-10.5px",
                  paddingTop: "10.5px",
                  paddingBottom: "10.5px"
                }}
              >
                <Link
                  to="/reports/post16-bursary?year_group=13"
                  target={"_blank"}
                >
                  <div>
                    <img
                      alt="post-16-bursary"
                      src={require("../../../../assets/images/report/post-16-bursary.png")}
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={4}
                lg={3}
                md={12}
                sm={21}
                xs={21}
                style={{
                  marginRight: "-10.5px",
                  paddingTop: "10.5px",
                  paddingBottom: "10.5px"
                }}
              >
                <Link to="/reports/post16-send?year_group=13" target={"_blank"}>
                  <div>
                    <img
                      alt="post-16-send"
                      src={require("../../../../assets/images/report/post-16-send.png")}
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={4}
                lg={3}
                md={12}
                sm={21}
                xs={21}
                style={{
                  marginRight: "-10.5px",
                  paddingTop: "10.5px",
                  paddingBottom: "10.5px"
                }}
              >
                <Link to="/reports/post16-lac?year_group=13" target={"_blank"}>
                  <div>
                    <img
                      alt="post-16-lac"
                      src={require("../../../../assets/images/report/post-16-lac.png")}
                    />
                  </div>
                </Link>
              </Col>
              <Col
                xl={4}
                lg={3}
                md={12}
                sm={21}
                xs={21}
                style={{
                  marginRight: "-10.5px",
                  paddingTop: "10.5px",
                  paddingBottom: "10.5px"
                }}
              >
                <Link to="/reports/post16-eal?year_group=13" target={"_blank"}>
                  <div>
                    <img
                      alt="post-16-eal"
                      src={require("../../../../assets/images/report/post-16-eal.png")}
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </Route>

      {/*Redirect to Dashboard*/}
      <Redirect to="/dashboard" />
    </Switch>
  );

  return renderUI();
};

export default withContext(([{ user }]) => ({ user }), Year13Dashboard);
