import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAdvisorsList } from "Services/common";

const AdviserListCard = () => {
  const [advisors, setAdvisors] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAdvisor = () => {
    setLoading(true);
    getAdvisorsList({
      body: {
        sort_column: "id",
        sort_order: "desc",
        page_limit: 5,
        page: 1
      },
      query: {}
    })
      .then(res => {
        setLoading(false);
        setAdvisors(res.user_list || []);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => getAdvisor(), []);

  return (
    <div className="white-box white-box--height-default">
      <form>
        <div className="row">
          <div className="col-xl-12">
            <h2 className="page-sub-heading">Advisers</h2>
          </div>
        </div>
        <Skeleton loading={loading}>
          <div className="table-responsive mobile-spacing">
            <table className="table custom-table-style">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(advisors || []).map((adviser, index) => (
                  <tr key={index}>
                    <td>
                      <span className="text-bold">{adviser.name}</span>
                    </td>
                    <td>
                      <a className="text-btm text-btm--text-blue">
                        {adviser.school_name}
                      </a>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Link
                        to={`/adviser/${adviser.id}`}
                        className="text-btm text-btm--text-green"
                      >
                        View adviser
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Skeleton>
      </form>
    </div>
  );
};

export default AdviserListCard;
