import React from "react";
import { Redirect } from "react-router-dom";
import { OTPublisher, OTSession, OTStreams, OTSubscriber } from "opentok-react";
import {
  startRecording,
  stopRecording
} from "../../../../../services/appointment";
import { notification } from "antd";
import { JoiningConfirmationModal } from "../JoiningConfirmationModal/JoiningConfirmationModal";
import { AppointmentCallHeader } from "../AppointmentHeader/AppointmentCallHeader";
// eslint-disable-next-line no-unused-vars
const OT = require("@opentok/client");

export default class VideoCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      connection: "Connecting",
      isReadyToStartCall: false,
      publishVideo: true,
      showError: false
    };

    this.sessionEventHandlers = {
      sessionConnected: () => {
        this.setState({ connection: "Connected" });
      },
      sessionDisconnected: () => {
        this.setState({ connection: "Disconnected" });
      },
      sessionReconnected: () => {
        this.setState({ connection: "Reconnected" });
      },
      sessionReconnecting: () => {
        this.setState({ connection: "Reconnecting" });
      }
    };

    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log("User denied access to media source");
      },
      streamCreated: () => {
        console.log("Publisher stream created");
      },
      streamDestroyed: ({ reason }) => {
        console.log(`Publisher stream destroyed because: ${reason}`);
      }
    };

    this.subscriberEventHandlers = {
      videoEnabled: () => {
        console.log("Subscriber video enabled");
      },
      videoDisabled: () => {
        console.log("Subscriber video disabled");
      }
    };
  }

  handleIsReadyToStartCall = () => {
    this.setState({ isReadyToStartCall: true });
  };

  startRecording = () => {
    console.log("recording started", this.props);
    startRecording({
      body: { appointment_id: this.props.appointmentDetails.appointment.id }
    });
  };

  stopRecording = async () => {
    console.log("recording stopped", this.props);
    const currentUser = await JSON.parse(localStorage.getItem("user"));

    stopRecording({
      body: {
        user_id: currentUser.id,
        appointment_id: this.props.appointmentDetails.appointment.id
      }
    })
      .then(() => {
        notification.success({ message: "Video call ended!" });
        if (this.props.history) {
          setTimeout(() => {
            this.props.history.push("/dashboard");
          }, 1000);
        }
      })
      .catch(() => {});
  };

  handleUnload = ev => {
    ev.preventDefault();
    // if (this.state.isReadyToStartCall) {
    // only if the user have joined the call
    this.stopRecording();
    // }

    return (ev.returnValue = "Are you sure you want to close?");
  };

  componentDidMount() {
    console.log("recording started", this.props);

    // check dates and redirect to error page if needed
    const date_now = new Date();
    const date_start = new Date(
      this.props.appointmentDetails.appointment.start_time
    );
    const date_end = new Date(
      this.props.appointmentDetails.appointment.end_time
    );

    let showError = false;
    if (date_now < date_start) {
      showError = true;
    } else if (date_now > date_end) {
      showError = true;
    }

    if (showError) {
      this.setState(state => ({
        showError: showError
      }));
    }

    window.addEventListener("beforeunload", this.handleUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnload);
  }

  onSessionError = error => {
    this.setState({ error });
  };

  onPublish = () => {
    this.startRecording();
    console.log("Publish Success");
  };

  onPublishError = error => {
    this.setState({ error });
  };

  onSubscribe = () => {
    this.setState({ userFull: true });
    console.log("Subscribe Success");
  };

  onSubscribeError = error => {
    this.setState({ error });
  };

  toggleVideo = () => {
    this.setState(state => ({
      publishVideo: !state.publishVideo
    }));
  };

  render() {
    const { appointmentDetails, event } = this.props;
    const sessionId = event.session_id;
    const { token } = event;
    const apiKey = event.api_key;

    console.log(this.props.appointmentDetails, "EB}T}");

    const { publishVideo, isReadyToStartCall, showError } = this.state;

    if (showError) {
      return <Redirect to="/appointment-error" />;
    }

    return (
      <div style={{ width: "100%" }}>
        <AppointmentCallHeader
          title={"Video appointment between"}
          appointmentDetails={appointmentDetails}
          startTime={event.start_time}
        />
        {/* <div id="sessionStatus">Session Status: {connection}</div>
        {error ? (
          <div className="error">
            <strong>Error:</strong> {JSON.stringify(error)}
          </div>
        ) : null} */}
        {isReadyToStartCall ? (
          <OTSession
            apiKey={apiKey}
            sessionId={sessionId}
            token={token}
            onError={this.onSessionError}
            eventHandlers={this.sessionEventHandlers}
          >
            {/* <button id="videoButton" onClick={this.toggleVideo}>
            {publishVideo ? "Disable" : "Enable"} Video
          </button> */}
            <OTStreams>
              <OTSubscriber
                properties={{
                  width: "100%",
                  height: "77vh"
                }}
                onSubscribe={this.onSubscribe}
                onError={this.onSubscribeError}
                eventHandlers={this.subscriberEventHandlers}
              />
            </OTStreams>
            <OTPublisher
              properties={{
                publishVideo,
                width: "100",
                height: "100"
              }}
              onPublish={this.onPublish}
              onError={this.onPublishError}
              eventHandlers={this.publisherEventHandlers}
            />
            <div style={{ position: "fixed", bottom: 10, left: "50%" }}>
              <div style={{ display: "flex", transform: "translate(-50%, 0)" }}>
                <a
                  className="button-style button-style-green-btm button-style-normal-button"
                  id="videoButton"
                  onClick={this.toggleVideo}
                  key="3"
                >
                  {publishVideo ? "Disable" : "Enable"} Video
                </a>
                <a
                  key="2"
                  className="button-style  button-style-normal-button"
                  id="endButton"
                  onClick={this.stopRecording}
                  style={{
                    backgroundColor: "#ea4335",
                    color: "white",
                    marginLeft: "1rem"
                  }}
                >
                  End call
                </a>
              </div>
            </div>
          </OTSession>
        ) : (
          <JoiningConfirmationModal
            title={"Are you ready to join ?"}
            description={
              "Please check your microphone and camera before joining"
            }
            visible={!isReadyToStartCall}
            handleIsReadyToStartCall={this.handleIsReadyToStartCall}
          />
        )}
      </div>
    );
  }
}
//🎥
