import { DatePicker, Form, Modal, notification } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createTask, editTask } from "../../../../services/task";
import { MomentDateFormats } from "../../../../constants";

const CreateTaskModal = ({
  visible,
  setVisible,
  task,
  setTask,
  fetchtasks,
  student,
  setStudent
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (task) {
      form.setFieldsValue({
        ...task,
        completion_date: moment(task.completion_date)
      });
    }
  }, [task]);

  const [loading, setLoading] = useState(false);

  const buttontext = () => {
    if (loading) {
      return "Loading...";
    } else if (task && !loading) {
      return "Update Task";
    } else {
      return "Create Task";
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        destroyOnClose
        centered
        footer={null}
        width={800}
        afterClose={() => {
          setTask();
          setStudent();
        }}
        onCancel={() => setVisible(false)}
      >
        <Form
          data-cy="form-add-agreed-action"
          form={form}
          onFinish={async values => {
            setLoading(true);
            const body = {
              ...values,
              completion_date: moment(values.completion_date)
                .format()
                .split("T")[0],
              student_id: student.id
            };
            let response;
            if (task) {
              body.status = task.status;
              body.task_id = task.id;
              response = await editTask({ body });
            } else {
              response = await createTask({ body });
            }
            setLoading(false);
            if (response && response.success) {
              fetchtasks();
              notification.success({
                message:
                  response.message ||
                  `Task ${task ? "updated" : "created"} successfully!`
              });
              form.resetFields();
              setVisible(false);
            } else {
              notification.error({
                message:
                  response.message ||
                  response.data.message ||
                  `Failed to ${task ? "update" : "create"} task!`
              });
            }
          }}
        >
          <div className="row align-items-center px-4 pt-4">
            <div className="col-md-12 col-lg-12 ">
              <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-4">
                <label className="labelstyle">TASK DESCRIPTION</label>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter task description."
                    }
                  ]}
                >
                  <input
                    data-cy="field-description-add-agreed-action"
                    type="text"
                    className="input-style"
                  />
                </Form.Item>
              </div>
              <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-4">
                <label className="labelstyle">PRIORITY</label>
                <Form.Item
                  name="priority"
                  rules={[
                    { required: true, message: "Please select task priority." }
                  ]}
                >
                  <select
                    data-cy="field-priority-add-agreed-action"
                    className="select-style"
                  >
                    <option value={undefined}>——Select——</option>
                    <option value="Normal">Normal</option>
                    <option value="High">High</option>
                    <option value="Low">Low</option>
                  </select>
                </Form.Item>
              </div>
              <div className="form-group">
                <label className="labelstyle">COMPLETION DATE</label>
                <div className="fake-input select-style select-style-medium-width">
                  <Form.Item
                    name="completion_date"
                    rules={[
                      {
                        required: true,
                        message: "Please select task completion date."
                      }
                    ]}
                  >
                    <DatePicker
                      data-cy="field-completion_date-add-agreed-action"
                      style={{ width: "100%" }}
                      placeholder=""
                      format={MomentDateFormats.CustomDateFormat.dayMonthYear}
                      disabledDate={current =>
                        current &&
                        current <
                          moment()
                            .endOf("day")
                            .subtract(1, "day")
                      }
                      bordered={false}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-group text-right">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={buttontext()}
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

CreateTaskModal.defaultProps = {
  setTask: () => {},
  setStudent: () => {}
};

export default CreateTaskModal;
