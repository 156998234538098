import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Dropdown, Menu } from "antd";
import { PageMetaTags } from "Common";
import { withContext } from "Context";
import { useParams } from "react-router";
import { getProfileDetails } from "Services/user";
import { destinationTracker } from "../../../common/SideBar/routes";
import { getAdvisorsDropdown } from "../../../../services/destination";
import RoleCheck from "../../../helpers/RoleCheck";
import {
  ADVISER,
  SCHOOL_ADMIN,
  SUPER_ADMIN
} from "../../../../constants/rolesSubtypes";
import BookAppointmentModal from "../../Appointments/BookAppointmentModal";
import { Breadcrumbs } from "../../../../components/common";
import StudentProfile from "./StudentProfile";
import * as notification from "react-dom/test-utils";
import { DownOutlined } from "@ant-design/icons";

const ViewStudentProfile = props => {
  const { studentId } = useParams();
  const [studentProfileDetails, setStudentProfileDetails] = useState();
  const [selectedAdviser, setSelectedAdviser] = useState();
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getAdvisorsDropdown({
      query: { student_id: studentId }
    })
      .then(res => {
        localStorage.setItem("AdvisorsDropdown", JSON.stringify(res));
      })
      .catch(() => "");
  }, [studentId]);

  useEffect(() => {
    if (studentId) {
      getProfileDetails({ pathParams: { userId: studentId } })
        .then(response => {
          setStudentProfileDetails(response);
        })
        .catch(error => {
          console.log(error);
          notification.error({
            message: `Failed to fetch Student ${studentId} profile details`
          });
        });
    }
  }, [studentId]);

  const handleClick = e => {
    if (
      !(
        studentProfileDetails.user_data &&
        studentProfileDetails.user_data.year_group
      )
    ) {
      notification.error({
        message: `Please assign Year group to student ${studentId}`
      });
      return;
    }
    const { item } = e;
    if (item.props) {
      const path = item.props["data-path"];
      const navigateTo = `${path}?id=${studentId}&year_group=${studentProfileDetails.user_data.year_group}`;
      history.push(navigateTo);
    }
  };

  const renderDestinationTrackerOverlayMenu = () => {
    return studentProfileDetails &&
      studentProfileDetails.user_data &&
      studentProfileDetails.user_data.year_group ? (
      <Menu onClick={handleClick}>
        {destinationTracker[studentProfileDetails.user_data.year_group].map(
          ({ path, name }) => (
            <Menu.Item data-path={path}>{name}</Menu.Item>
          )
        )}
      </Menu>
    ) : (
      <span className="info">
        There's been a problem: No year group has been assigned to this student
      </span>
    );
  };

  const renderStudentActionsList = () => {
    return (
      <React.Fragment>
        <RoleCheck authority={[ADVISER]}>
          <Button
            onClick={() => {
              setSelectedAdviser(() => props.user);
              setBookAppointmentModal(value => !value);
            }}
          >
            <i className="fas fa-calendar-check pr-1" /> Book appointment
          </Button>
          <Link to={`/emails/new?id=${studentId}`}>
            <Button>
              <i className="fas fa-envelope pr-1" /> Send Email
            </Button>
          </Link>
        </RoleCheck>

        <RoleCheck authority={[ADVISER, SCHOOL_ADMIN, SUPER_ADMIN]}>
          <Link to={`/appointments-history/${studentId}`}>
            <Button>
              <i className="fas fa-history pr-1" /> Appointment history
            </Button>
          </Link>
          <Link
            className={"ant-btn"}
            style={{ color: "black" }}
            to={`/students/${studentId}/action-list`}
          >
            <div className="dropdown-overlay-edit">
              <i className="fas fa-list" /> View action plan
            </div>
          </Link>
          <Dropdown overlay={renderDestinationTrackerOverlayMenu}>
            <Button>
              <i className="fas fa-sliders-h pr-1" />
              Destination tracker
              <DownOutlined />
            </Button>
          </Dropdown>
        </RoleCheck>
      </React.Fragment>
    );
  };

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs
        breadcrumbs={[
          {
            name: "Students list",
            path: `/students`
          },
          {
            name: "Student Profile",
            path: null
          }
        ]}
      />
    );
  };

  return (
    <div className="content-panel">
      {renderBreadcrumbs()}

      <PageMetaTags title="Adviser Profile" />
      <div className="student-booking">
        <h1 className="page-heading">Student profile</h1>
      </div>

      {bookAppointmentModal && (
        <BookAppointmentModal
          open={bookAppointmentModal}
          advisor={selectedAdviser}
          setAdvisor={setSelectedAdviser}
          onClose={setBookAppointmentModal}
          bookingFor="student"
          userId={studentId}
        />
      )}

      <div className="d-flex justify-content-end mb-4">
        {renderStudentActionsList()}
      </div>

      {studentProfileDetails && (
        <StudentProfile
          user={props.user}
          studentProfileDetails={studentProfileDetails}
          studentId={studentId}
        />
      )}
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), ViewStudentProfile);
