import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import StudentsList from "./StudentsList/StudentsList";
import ViewStudentProfile from "./ViewStudentProfile/ViewStudentProfile";

const Student = () => (
  <Switch>
    <Route exact path="/students" component={StudentsList} />
    <Route exact path="/students/:studentId" component={ViewStudentProfile} />
    <Redirect to="/login" />
  </Switch>
);

export default Student;
