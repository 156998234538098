import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const PageMetaTags = ({ title }) => {
  if (title) {
    title = `${title} - iProgress`;
  } else {
    title = "Progress Careers - iProgress";
  }
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

PageMetaTags.propTypes = {
  title: PropTypes.string.isRequired
};

export { PageMetaTags };
