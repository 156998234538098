const TYPE = {
  input: "input",
  select: "select"
};

const formItems = {
  form_visible: 1, // Default check to make it editable as fields rather label
  school_id: {
    name: "school_id",
    label: "MY SCHOOL",
    placeholder: "School name",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.select
  },
  name: {
    name: "name",
    label: "Name",
    placeholder: "Input Name",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  vmg_tutor_group: {
    name: "vmg_tutor_group",
    label: "Tutor/Form",
    placeholder: "Input VMG / Tutor group",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  ks4_average_score: {
    name: "ks4_average_score",
    label: "KS4 Average Points Score",
    placeholder: "Input KS4 Average Points Score",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  ethnicity: {
    name: "ethnicity",
    label: "MY ETHNICITY",
    placeholder: "Select Ethnicity",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  gcse_english_lang_grade: {
    name: "gcse_english_lang_grade",
    label: "GCSE English Lang Grade",
    placeholder: "Select GCSE English Lang Grade",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  gcse_maths_grade: {
    name: "gcse_maths_grade",
    label: "GCSE Maths Grade",
    placeholder: "Select GCSE Maths Grade",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  gcse_science_grade_avg: {
    name: "gcse_science_grade_avg",
    label: "GCSE Science Grade (Avg for triple)",
    placeholder: "Select GCSE Science Grade",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  "16_19_bursary": {
    name: "16_19_bursary",
    label: "16 - 19 Bursary",
    placeholder: "Input Bursary",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  eal: {
    name: "eal",
    label: "EAL",
    placeholder: "Input EAL",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  fsm: {
    name: "fsm",
    label: "FSM",
    placeholder: "Input FSM",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  looked_after: {
    name: "looked_after",
    label: "Looked after",
    placeholder: "Input Looked after",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  home_school: {
    name: "home_school",
    label: "Home School",
    placeholder: "Input Home School",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  pupil_premium: {
    name: "pupil_premium",
    label: "Pupil Premium",
    placeholder: "Input Pupil Premium",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  sen_status: {
    name: "sen_status",
    label: "SEN Status",
    placeholder: "Input SEN Status",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  upn: {
    name: "upn",
    label: "UPN",
    placeholder: "Input UPN",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  first_language: {
    name: "first_language",
    label: "First language",
    placeholder: "Input First language",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  year_group: {
    name: "year_group",
    label: "YEAR GROUP",
    placeholder: "Select Year Group",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.input
  },
  gender: {
    name: "gender",
    label: "Gender",
    placeholder: "Select Gender",
    options: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" }
    ],
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.select
  },
  about_me: {
    name: "about_me",
    label: "What you need to know about me?",
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.textArea
  }
};

export { formItems, TYPE };
