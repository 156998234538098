export const BenchMarks = [
  { id: 1, name: "1) Stable careers programme" },
  { id: 2, name: "2) Learning from career info & LMI" },
  { id: 3, name: "3) Addressing needs of each pupil" },
  { id: 4, name: "4) Linking curriculum to careers" },
  { id: 5, name: "5) Employer/Employee encounters" },
  { id: 6, name: "6) Experience of workplaces" },
  { id: 7, name: "7) Encounters with FE & HE" },
  { id: 8, name: "8) Personal guidance" }
];

export const ReversBenchMarksMapping = {
  1: BenchMarks[0],
  2: BenchMarks[1],
  3: BenchMarks[2],
  4: BenchMarks[3],
  5: BenchMarks[4],
  6: BenchMarks[5],
  7: BenchMarks[6],
  8: BenchMarks[7]
};
