import React, { useEffect, useState } from "react";
import { Form, Input, notification, Pagination, Row } from "antd";
import { PageMetaTags } from "Common";
import {
  createUsefulLink,
  deleteUsefulLink,
  getUsefulLinksList,
  updateUsefulLink
} from "../../../../services/usefullink";

const UsefulLinkList = () => {
  const pageHeading = "Useful links list";
  const [loading, setLoading] = useState(false);
  const [selectedLink, setSelectedLink] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [usefulLinksList, setUsefulLinksList] = useState([]);
  const [form] = Form.useForm();

  const getUserUsefulLinksList = () => {
    getUsefulLinksList({
      body: {
        page_limit: pageLimit,
        page: currentPage
      },
      query: {}
    })
      .then(response => {
        if (response.success) {
          setUsefulLinksList(response.useful_link);
        }
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    getUserUsefulLinksList();
  }, [currentPage, pageLimit]);

  useEffect(() => {
    if (!selectedLink) {
    } else {
      console.log(selectedLink);
      form.setFieldsValue({ link: selectedLink.link });
    }
  }, [selectedLink]);

  const deleteLink = link_id => {
    deleteUsefulLink({ body: { link_id: link_id } })
      .then(response => {
        if (response.success) {
          notification.success({ message: "Successfully deleted the link" });
          getUserUsefulLinksList();
        }
      })
      .catch(err => {
        notification.error({ message: "Failed to delete the link" });
      });
  };

  const addLink = value => {
    const body = {
      link: value.link
    };

    if (!isValidHttpUrl(value.link)) {
      notification.info({
        message:
          "link is not valid it should start either from https:// ot http://"
      });
      return;
    }
    setLoading(true);
    createUsefulLink({ body })
      .then(response => {
        if (response.success) {
          form.resetFields();
          notification.success({ message: "Successfully created new link" });
          getUserUsefulLinksList();
        } else {
          notification.error({ message: "Failed to create new link" });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: "Failed to create new link" });
      })
      .finally(() => setLoading(false));
  };

  const updateLink = (updatedLink, linkId) => {
    const body = {
      link: updatedLink,
      link_id: linkId
    };
    if (!isValidHttpUrl(updatedLink)) {
      notification.info({
        message:
          "link is not valid it should start either from https:// ot http://"
      });
      return;
    }
    setLoading(true);
    updateUsefulLink({ body })
      .then(response => {
        if (response.success) {
          setIsEditMode(false);
          form.resetFields();
          notification.success({ message: "Successfully updated new link" });
          getUserUsefulLinksList();
        } else {
          notification.error({ message: "Failed to update link" });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: "Failed to update link" });
      })
      .finally(() => setLoading(false));
  };

  const isValidHttpUrl = string => {
    let url;
    try {
      url = new URL(string);
    } catch (err) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  // DEPRECATED:
  // const renderDropdownOverlay = (usefulLink) => {
  //   return (
  //     <div className='border bg-white overlay'>
  //       <Link style={{ color: "black" }}
  //             onClick={() => {
  //               setIsEditMode(true);
  //               setSelectedLink(usefulLink);
  //             }}>
  //         <div className='dropdown-overlay-edit'>
  //           <i className='fas fa-list' /> Edit link
  //         </div>
  //       </Link>
  //       <Link style={{ color: "black" }} onClick={() => {
  //         deleteLink(usefulLink.id);
  //       }}>
  //         <div className='dropdown-overlay-edit'>
  //           <i className='fas fa-eye' /> Delete link
  //         </div>
  //       </Link>
  //     </div>
  //   );
  // };

  const renderPagination = () => {
    if (!usefulLinksList) {
      return;
    }
    return (
      <Row className="mb-2 mr-2 mt-4" type="flex" justify="end">
        <Pagination
          current={currentPage}
          onChange={setCurrentPage}
          showSizeChanger
          defaultPageSize={pageLimit}
          pageSizeOptions={["10", "25", "50", "100"]}
          onShowSizeChange={(e, p) => setPageLimit(p)}
          total={(usefulLinksList && usefulLinksList.total_count) || 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} tasks`
          }
        />
      </Row>
    );
  };

  const renderAddLinkForm = () => {
    return (
      <Form
        form={form}
        onFinish={value => {
          if (isEditMode) {
            updateLink(value.link, selectedLink.id);
          } else {
            addLink(value);
          }
        }}
      >
        <div className="search-area">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="form-group-custom mb-4 mb-sm-4 mb-lg-4 mb-xl-0">
                <label className="labelstyle">
                  {" "}
                  {isEditMode ? "Edit Link" : "Add Link"}
                </label>
                <Form.Item
                  name="link"
                  rules={[
                    {
                      required: true,
                      message: "Link is required."
                    }
                  ]}
                >
                  <Input
                    type="url"
                    placeholder="e.g Add any link"
                    className="input-style"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <label className="labelstyle">&nbsp;</label>
              <span
                onClick={() => form.submit()}
                className="button-style button-style-green-btm button-style-normal-button"
              >
                {isEditMode ? "Edit Link" : "Add Link"}
              </span>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <div data-cy="useful-links-page" className="content-panel">
      <PageMetaTags title={pageHeading} />
      <h1 className="page-heading">{pageHeading}</h1>

      {/*DEPRECATED: DON"T DELETE IT PERFORMS: ADD FUNCTIONALITY*/}
      {/*{renderAddLinkForm()}*/}

      <div className="search-wrapper">
        <div className="table-responsive mobile-spacing">
          <table style={{ minHeight: 30 }} className="table">
            <thead>
              <tr>
                <th>
                  <label className="check-style">No#</label>
                </th>

                <th>
                  <label>Link</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {(usefulLinksList || []).map((usefulLink, index) => (
                <tr key={usefulLink.id + index}>
                  <td>
                    <label className="check-style">{index + 1}</label>
                  </td>
                  <td>
                    <a
                      href={usefulLink.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {usefulLink.link_name}
                    </a>
                  </td>

                  {/*DEPRECATED: DON"T DELETE IT PERFORMS: EDIT/DELETE FUNCTIONALITY*/}
                  {/*<td onClick={(e) => e.stopPropagation()}>*/}
                  {/*  <div*/}
                  {/*    className='chose-item dropleft'*/}
                  {/*    data-toggle='dropdown'>*/}
                  {/*    <Dropdown*/}
                  {/*      trigger={["click"]}*/}
                  {/*      placement='bottomRight'*/}
                  {/*      overlay={() => renderDropdownOverlay(usefulLink)}>*/}
                  {/*      <div*/}
                  {/*        className='chose-item dropleft'*/}
                  {/*        data-toggle='dropdown'>*/}
                  {/*        <i className='fas fa-ellipsis-h' />*/}
                  {/*      </div>*/}
                  {/*    </Dropdown>*/}
                  {/*  </div>*/}
                  {/*</td>*/}
                </tr>
              ))}
            </tbody>
          </table>
          {renderPagination()}
        </div>
      </div>
    </div>
  );
};

export default UsefulLinkList;
