import appState from "./appState";
import userState from "./userState";
import subjectState from "./subjectState";
import usersState from "./usersState";
import taskState from "./taskState";

export const initialState = {
  app: appState,
  user: userState,
  subject: subjectState,
  users: usersState,
  tasks: taskState
};
