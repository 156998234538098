import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DatePicker, Form, Input, notification, Select, Skeleton } from "antd";
import moment from "moment/moment";
import { Option } from "antd/lib/mentions";
import TextArea from "antd/lib/input/TextArea";
import { formItems, TYPE } from "./formItemSchema";
import { PageMetaTags } from "../../../../components/common";
import {
  getInformationNotes,
  saveInformationNotes,
  getAdvisorsDropdown
} from "../../../../services/destination";
import { deepMerge, isAnyFieldEditable } from "../common";
import { MomentDateFormats, STUDENT } from "../../../../constants";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";

function InformationAndNotes({ user }) {
  const pageHeading = "Information & Notes";
  const DATE_FORMAT_TO_PUT = MomentDateFormats.CustomDateFormat.dayMonthYear;
  const DATE_FORMAT_TO_DISPLAY =
    MomentDateFormats.CustomDateFormat.dayMonthYear;
  const [informationNotesData, setInformationNotesData] = useState(null);
  const [editable, setEditable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group

  const getAdvertiseList = studentId => {
    getAdvisorsDropdown({
      body: {},
      query: { student_id: studentId }
    })
      .then(res => {
        formItems.careersInc_adviser.options = res;
        localStorage.removeItem("AdvisorsDropdown");
        //localStorage.setItem('AdvisorsDropdown', JSON.stringify(res));
      })
      .catch(() => "");
  };

  useEffect(() => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
      getAdvertiseList(query.get("id"));
    }
    getInformationNotes({ query: payload })
      .then(response => {
        if (response.success && response.status_code === 200) {
          const isFormEditable = isAnyFieldEditable(response.data);
          if (
            !isFormEditable ||
            !(response.data && response.data["form_visible"])
          ) {
            setEditable(false);
          }
          const newData = deepMerge(formItems, response.data);
          setInformationNotesData(() => {
            setInformationNotesData(newData);
          });
        } else {
          setInformationNotesData(() => {
            setInformationNotesData(formItems);
          });
        }
      })
      .catch(error => {
        console.log(error);
        setInformationNotesData(() => {
          setInformationNotesData(formItems);
        });
      })
      .finally(() => setGetLoading(false));
  }, [query.get("id")]);

  const onFinish = formValues => {
    setLoading(true);
    if (query.get("id")) {
      // Note: `student_id` is required when GET/PUT from `adviser`
      formValues.student_id = query.get("id");
    }
    changeDateFieldFormat(formValues);
    saveInformationNotes({ body: formValues })
      .then(res => {
        notification.success({ message: `Saved the ${pageHeading}` });
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to save ${pageHeading}`
        });
      })
      .finally(() => setLoading(false));
  };

  // Todo: Hotfix to revert either deal as form or manually on submit
  const changeDateFieldFormat = formValues => {
    if (formValues.careers_interview_date_1) {
      formValues.careers_interview_date_1 = formValues.careers_interview_date_1.format(
        DATE_FORMAT_TO_PUT
      );
    }
    if (formValues.careers_interview_date_2) {
      formValues.careers_interview_date_2 = formValues.careers_interview_date_2.format(
        DATE_FORMAT_TO_PUT
      );
    }
    if (formValues.careers_interview_date_3) {
      formValues.careers_interview_date_3 = formValues.careers_interview_date_3.format(
        DATE_FORMAT_TO_PUT
      );
    }
  };

  const renderAsLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input
              defaultValue={item.value}
              readOnly={true}
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderAsTextareaLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <TextArea
              className="textarea-style"
              readOnly={true}
              defaultValue={item.value}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderDateElement = item => {
    if (!item) {
      return;
    }

    if (!item.editable || !informationNotesData["form_visible"]) {
      return (
        <div className="col-12 col-md-6">
          <div className="form-group pb-2">
            <label className="labelstyle">{item.label}</label>
            <Form.Item name={item.name}>
              <DatePicker
                format={MomentDateFormats.CustomDateFormat.dayMonthYear}
                defaultValue={
                  item.value
                    ? moment(
                        item.value,
                        MomentDateFormats.CustomDateFormat.dayMonthYear
                      )
                    : item.value
                }
                disabled
                placeholder={item.placeholder}
                suffixIcon={null}
              />
            </Form.Item>
          </div>
        </div>
      );
    }

    if (!item.value && item.defaultValue) {
      return (
        <div className="col-12 col-md-6">
          <div className="form-group pb-4">
            <label className="labelstyle">{item.label}</label>
            <Form.Item name={item.name}>
              <DatePicker
                format={MomentDateFormats.CustomDateFormat.dayMonthYear}
                className={"w-100"}
                defaultValue={moment(item.defaultValue)}
              />
            </Form.Item>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-4">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <DatePicker
              format={MomentDateFormats.CustomDateFormat.dayMonthYear}
              className={"w-100"}
              defaultValue={
                item.value
                  ? moment(
                      item.value,
                      MomentDateFormats.CustomDateFormat.dayMonthYear
                    )
                  : null
              }
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderInputElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !informationNotesData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input
              defaultValue={item.value}
              disabled={!item.editable}
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderSelectElement = item => {
    //debugger;
    if (!item) {
      return;
    }
    if (!item.editable || !informationNotesData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-4">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Select
              showSearch
              className={"w-100"}
              allowClear={true}
              style={{ width: 200 }}
              placeholder={item.placeholder}
              optionFilterProp="children"
              disabled={!item.editable}
              defaultValue={item.value}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option disabled>Select</Option>
              {item.options &&
                item.options.map(option => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
            </Select>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderTextAreaElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !informationNotesData["form_visible"]) {
      return renderAsTextareaLabel(item);
    }
    return (
      <div className="col-12 col-md-6">
        <label className="labelstyle">{item.label}</label>
        <Form.Item name={item.name}>
          <TextArea
            className="textarea-style"
            rows={5}
            disabled={!item.editable}
            defaultValue={item.value}
          />
        </Form.Item>
      </div>
    );
  };

  const renderSubHeadingText = () => {
    return (
      <div className="mb-2">
        <p>
          Throughout your time in education you will have the opportunity to
          meet with a careers adviser. These meetings are all about you and the
          plans you need to put in place to prepare for when you leave
          education. Take a look at the following statements and see which you
          identify with most...
        </p>
        <ul className="bullets" type="1">
          <li key="0">
            I have no idea what I want to do or be. It’s worrying that people
            keep asking me
          </li>
          <li key="1">
            It’s too early to think about a career. I need to focus on my exams
          </li>
          <li key="2">
            I don’t even understand what I can do when I leave school
          </li>
          <li key="3">I want to go to Uni</li>
          <li key="4">I think an apprenticeship might be good for me</li>
        </ul>
        <p>
          It doesn’t matter what your starting point is, your careers
          appointment is an opportunity to discuss your ambitions, your hopes
          and also the things that may be preventing you from doing what you
          really want to do. During the meeting you will agree an action plan
          which sets out your personal goals and support to achieve them.
        </p>
      </div>
    );
  };

  return (
    <div className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}
      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}

      {user && user.role_slug === STUDENT && renderSubHeadingText()}

      <div className="profile-wrapper">
        <Skeleton loading={getLoading}>
          <Form form={form} onFinish={onFinish}>
            <div className="row">
              {informationNotesData &&
                Object.keys(formItems).map(key => {
                  if (
                    informationNotesData[key].type === TYPE.date &&
                    informationNotesData[key].visibile
                  ) {
                    return renderDateElement(informationNotesData[key]);
                  } else if (
                    informationNotesData[key].type === TYPE.input &&
                    informationNotesData[key].visibile
                  ) {
                    return renderInputElement(informationNotesData[key]);
                  } else if (
                    informationNotesData[key].type === TYPE.select &&
                    informationNotesData[key].visibile
                  ) {
                    return renderSelectElement(informationNotesData[key]);
                  } else if (
                    informationNotesData[key].type === TYPE.textArea &&
                    informationNotesData[key].visibile
                  ) {
                    return renderTextAreaElement(informationNotesData[key]);
                  }
                })}

              {/*Submit*/}
              {editable && (
                <div className="col-12 mt-4">
                  <div className="form-group pb-4">
                    <input
                      type="submit"
                      className="button-style button-style-green-btm button-style-normal-button"
                      disabled={loading}
                      value={loading ? "Loading..." : "Submit"}
                    />
                  </div>
                </div>
              )}
            </div>
          </Form>
        </Skeleton>
      </div>
    </div>
  );
}

export { InformationAndNotes };
