import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ListEmails from "./ListEmails";
import SendEmail from "./SendEmail/SendEmail";
import ViewEmail from "./ViewEmail";

const Emails = () => (
  <Switch>
    <Route exact path="/emails/list" component={ListEmails} />
    <Route exact path="/emails/new" component={SendEmail} />
    <Route exact path="/emails/:emailId/view" component={ViewEmail} />
    <Redirect to="/login" />
  </Switch>
);

export default Emails;
