import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { BenchMarkReports } from "./BenchMarkReports";
import { BenchmarkSummaryReports } from "./BenchmarkSummaryReports";
import { MomentDateFormats } from "../../../constants";
import { PageMetaTags } from "Common";

const { RangePicker } = DatePicker;

const CompassReport = () => {
  const [date, setDate] = useState({ start: "", end: "" });
  const dateFormat = MomentDateFormats.CustomDateFormat.dayMonthYearhypen;
  const currentDate = new Date();

  const oneYearAgoFromTodaysDate = new Date();
  oneYearAgoFromTodaysDate.setFullYear(
    oneYearAgoFromTodaysDate.getFullYear() - 1
  );

  useEffect(() => {
    setDate({
      start: moment(oneYearAgoFromTodaysDate).format(dateFormat),
      end: moment(currentDate).format(dateFormat)
    });
  }, []);

  const renderDatePickerRange = () => {
    return (
      <React.Fragment>
        <RangePicker
          size="large"
          style={{ marginBottom: 20 }}
          className="select-style w-full"
          onChange={val => {
            setDate({
              start: val
                ? moment(val[0]).format()
                : moment(oneYearAgoFromTodaysDate).format(dateFormat),
              end: val
                ? moment(val[1]).format()
                : moment(currentDate).format(dateFormat)
            });
          }}
          defaultValue={[
            moment(oneYearAgoFromTodaysDate, dateFormat),
            moment(currentDate, dateFormat)
          ]}
          format={MomentDateFormats.CustomDateFormat.dayMonthYear}
        />
        <div className="mb-4">
          <h2 className="page-sub-heading">
            Showing data from{" "}
            <span>
              {moment(date.start).format(
                MomentDateFormats.CustomDateFormat.dayMonthYear
              )}
            </span>{" "}
            to{" "}
            <span>
              {moment(date.end).format(
                MomentDateFormats.CustomDateFormat.dayMonthYear
              )}
            </span>
          </h2>
        </div>
        <br />
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="content-panel">
        <PageMetaTags title="Compass Report" />
        <h1 className="page-heading" style={{ textTransform: "none" }}>
          Gatsby benchmark report
        </h1>
        <div className="profile-wrapper">
          {renderDatePickerRange()}

          <BenchmarkSummaryReports date={date} />

          <BenchMarkReports date={date} />
        </div>
      </div>
    </>
  );
};

export default CompassReport;
