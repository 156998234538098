// Reference: https://momentjs.com/docs/#/displaying/format/
export const MomentDateFormats = {
  CustomDateFormat: {
    hourMinute: "hh:mm",
    twentyfourHourMinute: "HH:mm",
    dayMonthYear: "DD/MM/YYYY", // UK Format
    dayMonthYearhypen: "YYYY-MM-DD",
    yearMonthDay: "YYYY/MM/DD",
    dayMonthYearHourMinutes: "DD/MM/YYYY HH:mm",
    yearMonthDayHourMinutesSeconds: "YYYY-MM-DD HH:mm:ss"
  },
  DayOfWeek: {
    dddd: "dddd"
  },
  DayOfMonth: {
    DD: "DD"
  },
  Time: {
    LT: "LT"
  },
  LongDateFormat: {
    LT: "h:mm A",
    LTS: "h:mm:ss A",
    L: "MM/DD/YYYY", // e.g 02/03/2022 MM/DD/YYYY
    LL: "MMMM Do YYYY",
    LLL: "MMMM Do YYYY LT", // e.g February 3, 2022 12:26 PM
    LLLL: "dddd, MMMM Do YYYY LT" // e.g Thursday, February 3, 2022 12:26 PM
  }
};
