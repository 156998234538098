import { Empty, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import GraphBar from "./GraphBar";
import { getBenchmarkReports } from "../../../services/compassreport";
import { ReversBenchMarksMapping } from "../common";

const BenchMarkReports = ({ date }) => {
  const [loading, setLoading] = useState(false);
  const [reportsData, setReportsData] = useState();
  const reversBenchMarksMapping = ReversBenchMarksMapping;

  useEffect(() => {
    fetchBenchMarkReports();
  }, [date]);

  const fetchBenchMarkReports = () => {
    setLoading(true);
    getBenchmarkReports({
      query: {
        start_date: date.start,
        end_date: date.end
      }
    })
      .then(res => {
        if (res.data && Object.keys(res.data).length > 0) {
          res.data = Object.keys(res.data).map(key => {
            const data = res.data[key];
            return {
              ...data,
              name: reversBenchMarksMapping[data.benchmark_id].name // update name property
            };
          });
        }
        setReportsData(res);
      })
      .catch(error => {
        console.log("CompassReport.fetchBenchMarkReports", error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Skeleton loading={loading}>
      {/* Graph Charts: */}
      {reportsData && Object.keys(reportsData.data).length > 0 ? (
        <>
          {reportsData &&
            Object.keys(reportsData.data).map(key => (
              <div key={key} className="mb-4">
                <h2 className="page-sub-heading mb-4">
                  {reportsData.data[key].name}
                </h2>
                <div className="labelstyle" style={{ textTransform: "none" }}>
                  Activity logged for Advisers:{" "}
                  {reportsData.data[key].advisor_logs}
                </div>
                <div className="labelstyle" style={{ textTransform: "none" }}>
                  Activity logged for Students:{" "}
                  {reportsData.data[key].student_logs}
                </div>
                <GraphBar id={key} data={reportsData.data[key].data} />
              </div>
            ))}
        </>
      ) : (
        <Empty description="No benchMark report found!" />
      )}
    </Skeleton>
  );
};

export { BenchMarkReports };
