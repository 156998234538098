import React, { useRef, useState } from "react";
import { Redirect } from "react-router";
import Header from "../Header";
import SideBar from "../SideBar";
import { withContext } from "Context";
import { STUDENT } from "../../../constants";

const ProLayout = ({ children, user }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const hamburgerRef = useRef();

  if (children) {
    return (
      <section className="dashboard-wrapper">
        <div className="container-fluid">
          <Header hamburgerRef={hamburgerRef} setShowSideBar={setShowSideBar} />
          <div
            className="dashboard-body-wrapper"
            style={{
              marginRight: -15,
              marginLeft: -15
            }}
          >
            <SideBar
              hamburgerRef={hamburgerRef}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
            />
            <div
              style={{
                background: user.role_slug === STUDENT ? "#eae8e7" : "#fafbfb"
              }}
              className={
                "max-w-auto mx-auto px-4 sm:px-8 lg:px-8 w-full overflow-y-auto" +
                (children[29].key === window.location.pathname
                  ? "reportss"
                  : "")
              }
            >
              {children}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <Redirect to="/login" />;
  }
};

export default withContext(([{ user }]) => ({ user }), ProLayout);
