import React, { useEffect, useState } from "react";
import { Form, notification, Skeleton, Table } from "antd";
import { withContext } from "Context";
import TextArea from "antd/lib/input/TextArea";
import { PageMetaTags } from "../../../../components/common";
import {
  createLocation,
  getSchoolAppointmentLocations
} from "../../../../services/common";
import { deepMerge } from "../../../pages/DestinationTracker/common.js";

const columns = [{ title: "Location", dataIndex: "location", key: "location" }];
const ViewLocations = ({ user }) => {
  const pageHeading = "School Meeting Locations";
  const [loading, setLoading] = useState(false);
  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [schoolAppointmentLocations, setSchoolAppointmentLocations] = useState(
    []
  );

  useEffect(() => {
    let query = { query: { school_admin_id: user.id } };
    setLoading(true);
    getSchoolAppointmentLocations(query)
      .then(response => {
        if (response.success) {
          setSchoolAppointmentLocations(response.school_appointment_locations);
        } else {
          notification.error({
            message: `Failed to fetch School Locations`
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to fetch School Locations`
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const renderSubHeading = () => {
    return (
      <p className="text-muted">
        These are the locations in your school that students and advisers will
        be able to choose when booking face to face meeting
      </p>
    );
  };

  const onFinish = value => {
    if (!value && value.location) {
      return;
    }
    const body = {
      location: value.location
    };
    setCreateLocationLoading(true);
    createLocation({ body })
      .then(response => {
        if (response.success) {
          notification.success({
            message: "Successfully created new location"
          });
          const newLocation = response.school_appointment_location;
          const updatedLocations = [
            ...deepMerge(schoolAppointmentLocations),
            { id: newLocation.id, location: newLocation.location }
          ];
          setSchoolAppointmentLocations(() => updatedLocations);
        } else {
          notification.error({
            message: "Failed to create location"
          });
        }
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: "Failed to create location" });
      })
      .finally(() => setCreateLocationLoading(false));
  };

  const renderAddLocationForm = () => {
    return (
      <Form onFinish={onFinish}>
        <div className="row">
          <div className="col-12 col-md-9 pt-2">
            <div className="form-group-custom">
              <Form.Item
                name="location"
                rules={[
                  {
                    min: 5,
                    message:
                      "The location must be between 10 and 250 characters."
                  },
                  {
                    max: 250,
                    message:
                      "The location must be between 10 and 250 characters."
                  },
                  {
                    required: true,
                    message: "Please enter location"
                  }
                ]}
              >
                <TextArea
                  placeholder={"Add School location..."}
                  className="textarea-style"
                  autoSize={{ minRows: 5, maxRows: 8 }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div style={{ width: "100%" }} className="form-footer ">
              <input
                type="submit"
                disabled={createLocationLoading}
                className="button-style button-style-green-btm button-style-fullwidth-button"
                value={createLocationLoading ? "Loading..." : "Add Location"}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <div className="content-panel">
      <PageMetaTags title="Location Search" />
      <h1 className="page-heading">{pageHeading}</h1>
      {renderSubHeading()}
      <div className="profile-wrapper">
        {renderAddLocationForm()}
        <Skeleton loading={loading}>
          <div className="container">
            {schoolAppointmentLocations.length > 0 && (
              <Table
                dataSource={schoolAppointmentLocations}
                columns={columns}
              />
            )}
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default withContext(
  ([{ user }]) => ({
    user
  }),
  ViewLocations
);
