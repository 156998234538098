import React from "react";
import { withContext } from "Context";
import { Redirect, Route, Switch } from "react-router-dom";
import { STUDENT } from "../../../../constants";
import {
  StarExerciseDashboard,
  StarExerciseResult,
  StarExerciseWizard
} from "./index";
import "./index.scss";

const StarExerciseRouting = ({ user }) => {
  const routes = [
    {
      path: "/starexercise/dashboard",
      role: [STUDENT],
      component: StarExerciseDashboard
    },
    {
      path: "/starexercise/wizard",
      role: [STUDENT],
      component: StarExerciseWizard
    },
    {
      path: "/starexercise/result/:exerciseId",
      role: [STUDENT],
      component: StarExerciseResult
    }
  ];

  /*
   Note:
   This HOC will allow to pass data to all routes instead of using
   `withContext` on each component to get redux store e.g user
   */
  const withUser = (WrappedComponent, rest) => {
    return props => {
      return <WrappedComponent {...props} {...rest} />;
    };
  };

  return (
    <Switch>
      {routes.map(route => {
        if (route.role.includes(user.role_slug)) {
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={withUser(route.component, { user })}
            />
          );
        }
      })}

      {/*Redirect to Dashboard*/}
      <Redirect to="/starexercise/dashboard" />
    </Switch>
  );
};

export default withContext(([{ user }]) => ({ user }), StarExerciseRouting);
