const APPOINTMENT_TYPES = {
  face: {
    name: "Face to Face",
    value: "face"
  },
  video_chat: {
    name: "Video Chat",
    value: "video_chat"
  },
  voice_chat: {
    name: "Voice Chat",
    value: "voice_chat"
  },
  live_chat: {
    name: "Live Chat",
    value: "live_chat"
  },
  group_call: {
    name: "Group Call",
    value: "group_call"
  }
};

export { APPOINTMENT_TYPES };
