import React from "react";
import { OTSubscriber } from "opentok-react";

class Subscriber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      // audio: true,
      video: false,
      subscriber: null
    };

    this.otSubscriber = React.createRef();

    this.subscriberProperties = {
      fitMode: "contain",
      name: "Subscriber",
      preferredFrameRate: 15,
      resolution: "320x240",
      width: 300,
      height: 300
    };

    this.subscriberEventHandlers = {
      connected: e => {
        console.log("Event.Subscriber.connected => Subscriber connected", e);
        this.setSubscriberName(e.target);
      },
      destroyed: response => {
        console.log("Event.Subscriber.destroyed => Subscriber destroyed");
      },
      videoEnabled: () => {
        console.log(
          "Event.Subscriber.videoEnabled => Subscriber video enabled"
        );
      },
      videoDisabled: () => {
        console.log("Event.Subscriber.videoDisabled Subscriber video disabled");
      }
    };

    this.forceUpdate = () => {
      setTimeout(() => this.setState({}), 100);
    };

    this.onSubscribe = () => {
      console.log("Subscriber.onSubscribe subscribe successfully completes");
      this.addMuteListener();
    };
  }

  componentWillUnmount() {
    const muteButton = this.getMuteButton();
    if (muteButton) {
      muteButton.removeEventListener("click", this.forceUpdate);
    }
  }

  setSubscriberName({ element, stream }) {
    const titleElement = element.querySelector(".OT_name");
    if (!titleElement) {
      return;
    }

    titleElement.textContent = stream.name;
  }

  isSubscriberAudioActive() {
    const muteButton = this.getMuteButton();
    if (!muteButton) {
      return false;
    }

    return muteButton.classList.contains("OT_active");
  }

  getSubscriberElement() {
    if (!this.otSubscriber.current) {
      return null;
    }

    const { subscriber } = this.otSubscriber.current.state;
    if (!subscriber) {
      return null;
    }

    return subscriber.element;
  }

  getMuteButton() {
    const subscriberElement = this.getSubscriberElement();

    if (subscriberElement) {
      return subscriberElement.querySelector(".OT_mute");
    }

    return null;
  }

  addMuteListener() {
    const muteButton = this.getMuteButton();
    if (muteButton) {
      muteButton.addEventListener("click", this.forceUpdate);
    }
  }

  setAudio = event => {
    event.preventDefault();

    const muteButton = this.getMuteButton();
    if (!muteButton) {
      return;
    }

    muteButton.click();

    this.forceUpdate();
  };

  setVideo = event => {
    this.setState({
      video: !this.state.video
    });
  };

  endCall = () => {
    this.getSubscriber().then(() => {
      if (this.state.subscriber) {
        if (this.props.history) {
          setTimeout(() => {
            this.state.subscriber.destroy();
            this.props.history.push("/dashboard");
          }, 1000);
        }
      }
    });
  };

  onError = err => {
    console.log("Subscriber.onError => failed to subscribe");
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  };

  getSubscriber() {
    return new Promise((resolve, reject) => {
      console.log(this.otSubscriber);
      if (this.otSubscriber && this.otSubscriber.current) {
        this.setState(
          {
            subscriber: this.otSubscriber.current.getSubscriber()
          },
          () => {
            resolve();
          }
        );
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.error ? <div>{this.state.error}</div> : null}
        <OTSubscriber
          ref={this.otSubscriber}
          properties={{
            // subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
            ...this.subscriberProperties,
            ...this.props.subscriberProperties
          }}
          eventHandlers={this.subscriberEventHandlers}
          retry={true}
          maxRetryAttempts={2}
          retryAttemptTimeout={5000}
          onSubscribe={this.onSubscribe}
          onError={this.onError}
        />

        <div
          className={"d-flex justify-content-between mt-2 mt-2"}
          style={{ width: "300px" }}
        >
          <button
            className={"btn btn-sm text-white bg-yellow-500"}
            onClick={this.setVideo}
          >
            {this.state.video ? "Disable" : "Enable"} Video
          </button>

          <button
            className={"btn btn-sm text-white subaudio bg-yellow-500 ml-2"}
            onClick={this.setAudio}
          >
            {this.isSubscriberAudioActive() ? "Enable" : "Disable"} Audio
          </button>

          <button
            className={"btn btn-sm text-white bg-yellow-500 ml-2"}
            onClick={this.endCall}
          >
            End call
          </button>
        </div>
      </React.Fragment>
    );
  }
}

Subscriber.defaultProps = {
  subscriberProperties: {}
};

export default Subscriber;
