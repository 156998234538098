import { defaults } from "./default";

const common = {
  getUsefulLinkList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/show-links"
    }
  },
  createUsefulLink: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/add-link"
    }
  },
  updateUsefulLink: {
    v1: {
      ...defaults.methods.PUT,
      ...defaults.versions.v1,
      uri: "/edit-link"
    }
  },
  deleteUsefulLink: {
    v1: {
      ...defaults.methods.DELETE,
      ...defaults.versions.v1,
      uri: "/delete-link"
    }
  }
};

export default common;
