// Categories
// - used in questionnaires:
// - used to build star chart
// - user to show build collapsable accordian to show past exercise result:

export const Categories = {
  Post16Choices: {
    category: "Post 16 Choices",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I have applied for a post 16 option and have been offered a place",
        score: 5
      },
      {
        question:
          "I have researched my post 16 options and have all the relevant information I need to make a decision. I have carefully considered all available options.",
        score: 4
      },
      {
        question:
          "I have a knowledge of some colleges/ courses/ apprenticeships but require further information and support.",
        score: 3
      },
      {
        question:
          "I am aware of some colleges/courses but have not researched them.",
        score: 2
      },
      {
        question:
          "I do not know what my options are and have not researched them",
        score: 1
      }
    ],
    name: "post_16_choices"
  },
  CareerIdeas: {
    category: "Career Ideas",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I have applied for and been accepted on an appropriate post 16 pathway for my career aim",
        score: 5
      },
      {
        question:
          "I have a definite career idea and have received all the information, advice and guidance required to help me make this decision. I have fully considered all my options.",
        score: 4
      },
      {
        question:
          "I have a definite career aim but am not sure of the entry routes.",
        score: 3
      },
      {
        question:
          "I have a career idea but do not know very much about my chosen career. I have not really considered other possibilities.",
        score: 2
      },
      {
        question:
          "I do not have a current career idea and have not researched possible careers.",
        score: 1
      }
    ],
    name: "career_ideas"
  },
  CareerPlanning: {
    category: "Career Planning",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I have a good career plan and know exactly the steps I need to take to achieve my goal.",
        score: 5
      },
      {
        question:
          "I am reviewing and evaluating my career plan on a regular basis.",
        score: 4
      },
      {
        question:
          "I have developed a clear careers plan and am aware of the steps I need to take to achieve my goals.",
        score: 3
      },
      {
        question:
          "I have a career idea but will require support in developing a plan to achieve my aims.",
        score: 2
      },
      {
        question:
          "I have not yet considered a career option and do not know how to access support. ",
        score: 1
      }
    ],
    name: "career_planning"
  },
  Skills: {
    category: "Skills",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I am fully aware of the skills I possess or would like to develop and how these skills are relevant to my chosen career.",
        score: 5
      },
      {
        question:
          "I am aware of how my skills would be used in my chosen career",
        score: 4
      },
      {
        question:
          "I have begun to look at my skills and how they could be used in a variety of work settings.",
        score: 3
      },
      {
        question:
          "I am aware of things that I am good at but have not linked them to any career ideas.",
        score: 2
      },
      {
        question:
          "I have not evaluated my own skills or considered how I can apply them in a career. ",
        score: 1
      }
    ],
    name: "skills"
  },
  VocationalAwareness: {
    category: "Vocational Awareness",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I have a clear career idea and am able to explain my reasoning for choosing it as a suitable option.",
        score: 5
      },
      {
        question:
          "I have a career idea which I am evaluating against interventions on my action plan.",
        score: 4
      },
      {
        question:
          "I have a career idea or a number of ideas which I have started to research. This is giving me a better understanding of if I would like them or not.",
        score: 3
      },
      {
        question:
          "I have a career idea but am not able to explain the rationale for that idea.",
        score: 2
      },
      {
        question: "I do not have a career idea",
        score: 1
      }
    ],
    name: "vocational_awareness"
  },
  Employability: {
    category: "Employability",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I feel confident that I could talk or write about my skills and qualities during a selection process.",
        score: 5
      },
      {
        question:
          "I can show I am developing skills and qualities which will help with my employability.",
        score: 4
      },
      {
        question:
          "I can recognise my own skills and qualities in and out of school that will help make me employable.",
        score: 3
      },
      {
        question:
          "I can identify key skills and qualities employers are looking for.",
        score: 2
      },
      {
        question:
          "I do not know the skills and qualities employers are looking for.",
        score: 1
      }
    ],
    name: "employability"
  },
  LabourMarket: {
    category: "Labour Market",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I am able to explain how LMI has helped me reach an informed decision on my future career plans",
        score: 5
      },
      {
        question: "I am able to use LMI independently in my career planning.",
        score: 4
      },
      {
        question:
          "I am aware of the main employment sectors which have growing demand for staff.",
        score: 3
      },
      {
        question:
          "I can describe the main types of employment in the local area.",
        score: 2
      },
      {
        question:
          "I have no idea of the main types of employment in the local area.",
        score: 1
      }
    ],
    name: "labour_market"
  },
  Knowledge: {
    category: "Knowledge of FE/HE",
    title: "Please select the option which most closely matches you:",
    options: [
      {
        question:
          "I have a good knowledge of FE and HE and have been able to use this knowledge in my careers planning. I have made an informed decision if I want to or do not want to go to university.",
        score: 5
      },
      {
        question:
          "I have considered my post 16 choices but am unsure of the possible opportunities my choice could lead to in HE.",
        score: 4
      },
      {
        question:
          "I am beginning to understand the options open to me in FE and HE.",
        score: 3
      },
      {
        question:
          "I have some knowledge of my post 16 choices but little knowledge of HE.",
        score: 2
      },
      {
        question:
          "I have no idea of the possible options available to me at FE/HE.",
        score: 1
      }
    ],
    name: "knowledge_of_fe_he"
  }
};

export const ExerciseResultMatching = {
  post_16_choices: Categories["Post16Choices"],
  career_ideas: Categories["CareerIdeas"],
  career_planning: Categories["CareerPlanning"],
  skills: Categories["Skills"],
  vocational_awareness: Categories["VocationalAwareness"],
  employability: Categories["Employability"],
  labour_market: Categories["LabourMarket"],
  knowledge_of_fe_he: Categories["Knowledge"]
};

export const StatusMapping = {
  0: { category: "Post 16 Choices", status: "wait", key: "post_16_choices" },
  1: { category: "Career Ideas", status: "wait", key: "career_ideas" },
  2: { category: "Career Planning", status: "wait", key: "career_planning" },
  3: { category: "Skills", status: "wait", key: "skills" },
  4: {
    category: "Vocational Awareness",
    status: "wait",
    key: "vocational_awareness"
  },
  5: { category: "Employability", status: "wait", key: "employability" },
  6: { category: "Labour Market", status: "wait", key: "labour_market" },
  7: {
    category: "Knowledge of FE/HE",
    status: "wait",
    key: "knowledge_of_fe_he"
  }
};
