import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Divider,
  Form,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Row
} from "antd";
import moment from "moment";
import { createAdviserOffDays } from "Services/common";
import {
  deleteAdvisorsDayOff,
  getAdvisorsDayOff,
  updateAdvisorsDayOff
} from "../../../../services/appointment";
import { MomentDateFormats } from "../../../../constants";

const { RangePicker } = DatePicker;

const AdviserLeaveModal = ({ open, onCancel }) => {
  const [leaves, setLeaves] = useState();
  const [current, setCurrent] = useState(1);
  const [limit, setLimit] = useState(10);

  const rules = [
    {
      required: true,
      message: "Please select start and end date."
    }
  ];
  const [loading, setLoading] = useState(false);
  const [editDay, setEditDay] = useState({});

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const onFinish = formValues => {
    const body = {
      start_time: moment(formValues["advisorDaysOffTime"][0]).format(
        MomentDateFormats.CustomDateFormat.yearMonthDayHourMinutesSeconds
      ),
      end_time: moment(formValues["advisorDaysOffTime"][1]).format(
        MomentDateFormats.CustomDateFormat.yearMonthDayHourMinutesSeconds
      )
    };

    if (body.start_time && body.end_time) {
      onCreateAdviserOffDays(body);
    }
    form.resetFields();
  };

  const onCreateAdviserOffDays = body => {
    setLoading(true);
    createAdviserOffDays({ body })
      .then(response => {
        setLoading(false);
        if (response && response.success) {
          showAdvisorsDayOff();
          notification.success({
            message:
              response.message || "Your designated leaves has been marked off"
          });
        } else {
          notification.error({
            message: response.message || "Failed to add day off"
          });
        }
      })
      .catch(error => {
        setLoading(false);
        error &&
          error.data &&
          notification.error({
            message: error.data.end_time || "Failed to add day off"
          });
      })
      .finally(() => setLoading(false));
  };

  const showAdvisorsDayOff = () => {
    getAdvisorsDayOff({ body: { page_limit: limit }, query: { page: current } })
      .then(res => setLeaves(res))
      .catch(() => setLeaves([]));
  };

  useEffect(() => {
    showAdvisorsDayOff();
  }, [current, limit]);

  const editInput = () => (
    <Form
      style={{ display: "flex", alignItems: "center" }}
      className="px-4"
      form={editForm}
      onFinish={values => {
        const body = {
          start_time: moment(values["advisorDaysOffTime"][0]).format(
            MomentDateFormats.CustomDateFormat.yearMonthDayHourMinutesSeconds
          ),
          end_time: moment(values["advisorDaysOffTime"][1]).format(
            MomentDateFormats.CustomDateFormat.yearMonthDayHourMinutesSeconds
          ),
          adviser_offday_id: Object.keys(editDay)[0]
        };
        updateAdvisorsDayOff({ body })
          .then(response => {
            setLoading(false);
            if (response && response.success) {
              setEditDay({});
              showAdvisorsDayOff();
              notification.success({
                message:
                  response.message || "Your designated leaves has been updated"
              });
            } else {
              notification.error({
                message: response.message || "Failed to update day off"
              });
            }
          })
          .catch(error => {
            setLoading(false);
            error &&
              error.data &&
              notification.error({
                message: error.data.end_time || "Failed to  update day off"
              });
          })
          .finally(() => setLoading(false));
      }}
    >
      <div className="form-group">
        <Form.Item name="advisorDaysOffTime" rules={rules}>
          <RangePicker
            size="large"
            className="select-style w-full"
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                moment("00:00", MomentDateFormats.CustomDateFormat.hourMinute),
                moment("11:59", MomentDateFormats.CustomDateFormat.hourMinute)
              ]
            }}
            format={MomentDateFormats.CustomDateFormat.dayMonthYearHourMinutes}
          />
        </Form.Item>
      </div>

      <div className="form-group text-right ml-2">
        <input
          type="submit"
          className="button-style button-style-green-btm button-style-normal-button"
          value={loading ? "Loading..." : "Update"}
        />
      </div>
    </Form>
  );

  const deletedayoff = id => {
    deleteAdvisorsDayOff({ body: { adviser_off_day_id: id } })
      .then(response => {
        if (response && response.success) {
          notification.success({
            message:
              response.message || "Your designated leaves has been deleted"
          });
          showAdvisorsDayOff();
        } else {
          notification.error({
            message: response.message || "Failed to delete day off"
          });
        }
      })
      .catch(error => {
        error &&
          error.data &&
          notification.error({
            message: error.data.end_time || "Failed to delete day off"
          });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="modal fade" id="adviserleavemodal">
      <Modal
        visible={open}
        width={650}
        onCancel={onCancel}
        centered
        footer={null}
        destroyOnClose
        bodyStyle={{ padding: 0 }}
        afterClose={() => {
          setEditDay({});
          editForm.resetFields();
          form.resetFields();
        }}
      >
        <div className="modal-title px-4 pt-4">Day Off</div>
        <Form
          style={{ display: "flex", alignItems: "center" }}
          className="px-4"
          form={form}
          onFinish={onFinish}
        >
          <div className="form-group">
            <Form.Item name="advisorDaysOffTime" rules={rules}>
              <RangePicker
                size="large"
                className="select-style w-full"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment(
                      "00:00",
                      MomentDateFormats.CustomDateFormat.hourMinute
                    ),
                    moment(
                      "11:59",
                      MomentDateFormats.CustomDateFormat.hourMinute
                    )
                  ]
                }}
                format={
                  MomentDateFormats.CustomDateFormat.dayMonthYearHourMinutes
                }
              />
            </Form.Item>
          </div>

          <div className="form-group text-right ml-2">
            <input
              type="submit"
              className="button-style button-style-green-btm button-style-normal-button"
              value={loading ? "Loading..." : "Submit"}
            />
          </div>
        </Form>

        <div className="leaves-list">
          {leaves &&
            Array.isArray(leaves.adviser_off_days) &&
            leaves.adviser_off_days.map(leave => (
              <div key={leave.id} className="leaves">
                {editDay[leave.id] ? (
                  <div className="mt-3">{editInput()}</div>
                ) : (
                  <div className="leaves-item">
                    <div className="">
                      <div className="heading"> {leave.adviser_name} </div>
                      <div className="description">
                        {leave.start_time &&
                          moment(leave.start_time).format("lll")}{" "}
                        -{" "}
                        {leave.end_time && moment(leave.end_time).format("lll")}
                      </div>
                    </div>
                    <div className="">
                      <a
                        onClick={() => {
                          editForm.setFieldsValue({
                            advisorDaysOffTime: [
                              moment(leave.start_time),
                              moment(leave.end_time)
                            ]
                          });
                          setEditDay({ [leave.id]: true });
                        }}
                        className="download-button"
                      >
                        Edit
                      </a>
                      <Divider type="vertical" />
                      <Popconfirm
                        title="Do you want to removed day off?"
                        okText="Delete"
                        okType="danger"
                        onCancel={() => {}}
                        onConfirm={() => deletedayoff(leave.id)}
                      >
                        <a className="delete-button">Delete</a>
                      </Popconfirm>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <Row className="p-4" type="flex" justify="end">
          <Pagination
            current={current}
            onChange={setCurrent}
            // showSizeChanger
            defaultPageSize={limit}
            pageSizeOptions={["10", "25", "50", "100"]}
            onShowSizeChange={(e, p) => setLimit(p)}
            total={(leaves && leaves.total_count) || 0}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} tasks`
            }
          />
        </Row>
      </Modal>
    </div>
  );
};

export { AdviserLeaveModal };
