export default {
  loading: true,
  openMenu: false,
  breadcrumbs: [],
  export_to_pdf: {
    loading: false,
    activityReady: 0,
    evidenceReady: 0
  },
  initialLoad: true
};
