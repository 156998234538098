import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { storeTaskList } from "../../../../store/actions/taskActions";
import moment from "moment";
import { Dropdown, notification, Pagination, Row } from "antd";
import { Link } from "react-router-dom";
import { deleteTimeLog, listTimeLog } from "Services/user";
import { PageMetaTags } from "Common";

const TimeLogList = ({ storeTasks }) => {
  const [timeLogsList, setTimeLogsList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const fetchTimeLogsList = () => {
    const body = {
      page_limit: pageLimit,
      page: currentPage
    };
    listTimeLog({ body })
      .then(res => setTimeLogsList(res))
      .catch(() => {});
  };

  useEffect(() => {
    fetchTimeLogsList();
  }, [pageLimit, currentPage]);

  const renderPagination = () => {
    if (!timeLogsList) {
      return;
    }
    return (
      <Row className="mb-2 mr-2 mt-4" type="flex" justify="end">
        <Pagination
          current={currentPage}
          onChange={setCurrentPage}
          showSizeChanger
          defaultPageSize={pageLimit}
          pageSizeOptions={["10", "25", "50", "100"]}
          onShowSizeChange={(e, p) => setPageLimit(p)}
          total={(timeLogsList && timeLogsList.total_count) || 0}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} tasks`
          }
        />
      </Row>
    );
  };

  const removeTask = id => {
    deleteTimeLog({ body: { time_log_id: id, _method: "delete" } })
      .then(res => {
        fetchTimeLogsList();
        if (res && res.status) {
          notification.success({
            message: res.message || "Time log deleted successfully"
          });
        } else {
          throw Error;
        }
      })
      .catch(err => {
        notification.error({
          message: err.data.message || "Failed to delete time log"
        });
      });
  };

  return (
    <div className="content-panel">
      <div className="student-booking">
        <PageMetaTags title="Time Logs" />
        <h1 className="page-heading">Time Logs</h1>
        <Link
          to="/timelog"
          className="button-style button-style-green-btm button-style-normal-button text-white cursor-pointer"
        >
          Log Time
        </Link>
      </div>
      <div className="profile-wrapper p-0">
        <div className="table-responsive mobile-spacing">
          <div className="list-heading">
            <i className="fas fa-check" /> Time logs
          </div>
          <ul className="task-listing">
            {timeLogsList &&
              Array(timeLogsList.task) &&
              timeLogsList.task.map(task => (
                <li key={task.id}>
                  <label className="radio-style">
                    <p className="student-name">{task && task.student}</p>
                    <p>
                      {moment(task.start_time).format("lll")} -{" "}
                      {moment(task.end_time).format("lll")}
                    </p>
                    <p>{task && task.description}</p>
                  </label>
                  <div className="task-date">
                    <div
                      className="select-area dropdown"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="date">{task.school && task.school}</span>
                      <Dropdown
                        trigger="hover"
                        placement="bottomRight"
                        overlay={
                          <div className="border bg-white overlay">
                            <div
                              onClick={() => removeTask(task.id)}
                              className="dropdown-overlay-delete"
                            >
                              <i className="fas fa-trash" /> Delete
                            </div>
                          </div>
                        }
                      >
                        <div className="select-drop">
                          <i className="fas fa-ellipsis-h" />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          {/* {tasks.total > 0 && <Pagination current={0} />} */}

          {renderPagination()}
          {timeLogsList &&
            Array.isArray(timeLogsList.task) &&
            timeLogsList.task.length === 0 && (
              <div className="text-center my-4 text-xl-start empty-tasks">
                No time log available
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default withContext(
  ([{ tasks }, dispatch]) => ({
    tasks,
    storeTasks: data => storeTaskList(data, dispatch)
  }),
  TimeLogList
);
