import { notification } from "antd";
import PubNub from "pubnub";

import { openLinkInNewTab } from "../../../../common";
import { downloadChat, generateChat } from "../../../../../services/common";

export const AppointmentTypes = {
  video_chat: "video_chat",
  voice_chat: "voice_chat",
  live_chat: "live_chat",
  group_call: "group_call"
};

export function handleChatDownloadScript(config, user) {
  return new Promise((resolve, reject) => {
    const pubnub = new PubNub({
      uuid: user.email,
      publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
      secretKey: process.env.REACT_APP_PUBNUB_SECRET_KEY
    });

    const fetchMessages = async (channelName, tokeTime) => {
      return pubnub.fetchMessages({ channels: [channelName], start: tokeTime });
    };

    const handleFetchMessagesByTokenTime = async (channelName, tokenTime) => {
      const channelEncodedName = encodeURIComponent(channelName);
      const response = await fetchMessages(channelName, tokenTime);

      let paginatedData = [];
      paginatedData = response.channels[channelEncodedName];

      // Note: Recursive call:
      // Retrieve backdated messages from time start `timetoken`
      // for first message of latest 100 messages retrieved
      if (
        response.channels &&
        response.channels[channelEncodedName] &&
        response.channels[channelEncodedName].length > 99
      ) {
        const firstMessageTimeToken =
          response.channels[channelEncodedName][0].timetoken;
        const previousMessages = await fetchMessages(
          channelName,
          firstMessageTimeToken
        );
        paginatedData = [
          ...paginatedData,
          previousMessages.channels[channelEncodedName]
        ];
      }

      return paginatedData;
    };

    pubnub.fetchMessages(
      {
        channels: [config.channel_name]
      },
      async (status, response) => {
        if (status.statusCode === 200) {
          const channelName = encodeURIComponent(config.channel_name);
          const previousMessages = response.channels[channelName] || [];
          let totalMessages = [...previousMessages];
          if (
            response.channels[channelName] &&
            response.channels[channelName].length > 99
          ) {
            const firstMessageTimeToken =
              response.channels[channelName][0].timetoken;
            const result = await handleFetchMessagesByTokenTime(
              config.channel_name,
              firstMessageTimeToken
            );
            totalMessages = [...totalMessages, ...result];
          }
          resolve(totalMessages);
        } else {
          reject([]);
        }
      }
    );
  });
}

export const generateChatUrl = async body => {
  return generateChat({ body: { ...body } });
};

export const downloadChatUrl = async body => {
  return downloadChat({ body: { ...body } });
};

export const openChatLink = response => {
  if (response.success) {
    openLinkInNewTab(response.download_url);
  } else {
    notification.success({ message: "Failed to fetch download url" });
  }
};
