import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Skeleton } from "antd";

import { Breadcrumbs } from "../../../../common";
import { PageMetaTags } from "../../../../common/PageMetaTags";
import HeaderWrapper from "Common/ProLayout/HeaderWrapper";
import RoleCheck from "../../../../../components/helpers/RoleCheck";
import { PastExercises } from "../PastExercises/PastExercises";
import { getPreviousExercises } from "../../../../../services/starexercise";
import { STUDENT } from "../../../../../constants";

const renderBreadcrumbs = () => {
  return (
    <div className="ml-2">
      <Breadcrumbs
        breadcrumbs={[
          {
            name: "Resources",
            path: `/resources`
          },
          {
            name: "Star Exercise",
            path: null
          }
        ]}
      />
    </div>
  );
};

const StarExerciseDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [previousExercises] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // Extra API call added since BE doesn't provide any mechanism to check if any exercise is
    // completed so we have used a way where to check if past exercise contains at-least 1 entry:

    setLoading(true);
    getPreviousExercises({})
      .then(response => {
        if (response.success && response.previous_exercises.length > 0) {
          const lastSubmittedExercise = response.previous_exercises.pop();
          history.push(`/starexercise/result/${lastSubmittedExercise.id}`);
        }
      })
      .catch(error =>
        console.log("StarExerciseDashboard.getPreviousExercises", error)
      )
      .finally(() => setLoading(false));
  }, []);

  if (previousExercises && previousExercises.length > 0) {
    return;
  }

  return (
    <HeaderWrapper
      title="Resources - Star Exercise"
      image={require("../../../../../assets/icons/header/icon-cogs.png")}
    >
      <PageMetaTags title="Resources - Star Exercise" />

      {renderBreadcrumbs()}

      <div className="row bg-white ml-2 mr-2">
        <Skeleton loading={loading}>
          <div className="col-12 col-lg-6 p-12 text-muted">
            <p>
              We like to use our Careers Star to help you visualise and build
              self-awareness of your current thoughts and paths available to
              you.
            </p>
            <br />

            <p>
              Your Careers Adviser will talk to you about your own aspirations
              and goals, current feelings on your future and aspirations, and
              talk through any support that you may need.
            </p>
            <br />

            <p>
              {" "}
              Together you will agree progress targets which measures the level
              that you feel you have and the level you can reach on a visual
              scale of 1-5, using our supporting benchmark scoring below.
            </p>
            <br />
            <p>
              We will come back to your Star (in another colour each time)
              regularly and look at the progress you have made. This will help
              us to monitor outcomes achieved as you build confidence and
              self-esteem as your star expands.
            </p>
            <br />

            <RoleCheck authority={[STUDENT]} roleClass={"mb-4"}>
              <Link
                to={"/starexercise/wizard"}
                className={"btn btn-yellow cursor-pointer"}
              >
                Take Star Exercise <i className={"fa fa-arrow-right ml-2"} />
              </Link>
            </RoleCheck>
          </div>
          <div className="col-12 col-lg-5 offset-1 mt-12">
            <div className="h-full">
              <div className="bg-white rounded-lg text-gray-500">
                <div className="text-sm mt-2">
                  <img
                    alt="about me"
                    src={require("../../../../../assets/images/star-exercise-dashboard.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Skeleton>
      </div>
      <br />

      <div className="row bg-white ml-2 mr-2">
        <div className="col-12 p-12">
          <Skeleton loading={loading}>
            <PastExercises />
          </Skeleton>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export { StarExerciseDashboard };
