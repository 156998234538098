import React from "react";
import { notification, Upload } from "antd";
import { uploadTaskDoc } from "../../../../services/task";

const UploadTaskDocuments = ({ taskId }) => {
  const onUpload = (file, taskId) => {
    const body = new FormData();
    body.append("task_doc", file);
    uploadTaskDoc({ body, pathParams: { taskId } })
      .then(response => {
        notification.success({
          message: response.message || "Successfully uploaded"
        });
      })
      .catch(error => {
        console.log(error);
        notification.success({
          message: error.data.message || "Failed to upload"
        });
      });
    return false;
  };

  return (
    <Upload fileList={[]} beforeUpload={file => onUpload(file, taskId)}>
      <div className="dropdown-overlay-edit">
        <i className="fas fa-upload" /> Upload document
      </div>
    </Upload>
  );
};

export { UploadTaskDocuments };
