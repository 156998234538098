import React, { useEffect, useState } from "react";
import { notification, Spin } from "antd";
import { appointmentDetail } from "Services/appointment";
import { useParams } from "react-router";
import VideoCall from "./VideoCall";
import VoiceCall from "./VoiceCall";
import LiveChat from "./LiveChat";
import { liveChat, videoCall } from "../../../../services/appointment";
import OTPubSubWrapper from "./GroupCall";

const JoinMeeting = props => {
  const [videoDetails, setVideoDetails] = useState();
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { appointmentId } = useParams();

  useEffect(() => {
    const sidebarElement = document.getElementById("sidebar");
    sidebarElement.style.display = "none";

    return () => (sidebarElement.style.display = "flex");
  }, []);

  const components = {
    group_call: (videoDetails, appointmentDetails) => {
      if (!videoDetails || !appointmentDetails) {
        return null;
      }
      return (
        <OTPubSubWrapper
          embedID={videoDetails.embedID}
          room={videoDetails.room}
          event={videoDetails}
          appointmentDetails={appointmentDetails}
          {...props}
        />
      );
    },
    video_chat: (videoDetails, appointmentDetails) => {
      if (!videoDetails || !appointmentDetails) {
        return null;
      }
      return (
        <VideoCall
          embedID={videoDetails.embedID}
          room={videoDetails.room}
          event={videoDetails}
          appointmentDetails={appointmentDetails}
          {...props}
        />
      );
    },
    voice_chat: (videoDetails, appointmentDetails) => {
      if (!videoDetails || !appointmentDetails) {
        return null;
      }
      return (
        <VoiceCall
          embedID={videoDetails.embedID}
          room={videoDetails.room}
          event={videoDetails}
          appointmentDetails={appointmentDetails}
          {...props}
        />
      );
    },
    live_chat: (videoDetails, appointmentDetails) => {
      if (!videoDetails || !appointmentDetails) {
        return null;
      }
      return (
        <LiveChat
          appointmentDetails={appointmentDetails}
          config={videoDetails}
          {...props}
        />
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    appointmentDetail({ body: { appointment_id: appointmentId } }).then(res => {
      setAppointmentDetails(res && res);
      if (
        res.appointment &&
        (res.appointment.appointment_type === "group_call" ||
          res.appointment.appointment_type === "video_chat" ||
          res.appointment.appointment_type === "voice_chat")
      ) {
        videoCall({ body: { appointment_id: appointmentId } })
          .then(res => {
            setVideoDetails(res);
          })
          .catch(() => {
            notification.error({ message: "Failed to establish connection" });
          })
          .finally(() => setLoading(false));
      } else if (
        res.appointment &&
        res.appointment.appointment_type === "live_chat"
      ) {
        liveChat({ body: { appointment_id: appointmentId } })
          .then(res => {
            setVideoDetails(res);
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      }
    });
  }, [appointmentId]);

  if (loading || !appointmentDetails) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <Spin loading />
      </div>
    );
  }
  return components[appointmentDetails.appointment.appointment_type](
    videoDetails,
    appointmentDetails
  );
};

export default JoinMeeting;
