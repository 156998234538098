import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withContext } from "Context";
import AdvisorTasks from "./AdvisorTasks";
import StudentsTasks from "./StudentsTasks";
import StudentActionPlan from "./StudentActionPlan/StudentActionPlan";
import {
  ADVISER,
  SCHOOL_ADMIN,
  STUDENT,
  SUPER_ADMIN
} from "../../../constants/rolesSubtypes";
import "./style.scss";

const routes = [
  {
    path: "/students/:studentId/action-list",
    role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
    component: StudentsTasks
  },
  {
    path: "/action-list",
    role: [ADVISER],
    component: StudentsTasks
  },
  {
    path: "/tasks",
    role: [ADVISER, STUDENT],
    component: AdvisorTasks
  },
  {
    path: "/action-plan",
    role: [STUDENT],
    component: StudentActionPlan
  }
];

const Tasks = ({ user }) => {
  return (
    <Switch>
      {routes.map(route => {
        if (route.role.includes(user.role_slug)) {
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={route.component}
            />
          );
        }
      })}
      <Redirect to="/404" />
    </Switch>
  );
};

export default withContext(([{ user }]) => ({ user }), Tasks);
