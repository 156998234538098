import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import StackedColumn from "./StackedColumn";
import { getBenchmarkSummaryReports } from "../../../services/compassreport";
import { ReversBenchMarksMapping } from "../common";

const BenchmarkSummaryReports = ({ date }) => {
  const [loading, setLoading] = useState(false);
  const [benchmarkSummaryData, setBenchmarkSummaryData] = useState([]);
  const reversBenchMarksMapping = ReversBenchMarksMapping;

  useEffect(() => {
    fetchBenchMarkSummaryReports();
  }, [date]);

  const fetchBenchMarkSummaryReports = () => {
    setLoading(true);
    getBenchmarkSummaryReports({
      query: {
        start_date: date.start,
        end_date: date.end
      }
    })
      .then(res => {
        if (res.data && res.data.length > 0) {
          res.data = res.data.map(item => {
            return {
              ...item,
              benchmark: reversBenchMarksMapping[item.benchmark_id].name // used for `xField` in StackedColumn chart
            };
          });
        }
        setBenchmarkSummaryData(res.data);
      })
      .catch(error => {
        console.log("CompassReport.fetchBenchMarkSummaryReports", error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Skeleton loading={loading}>
      {benchmarkSummaryData && benchmarkSummaryData.length > 0 ? (
        <div className="mb-4">
          <h2 className="page-sub-heading mb-4">Summary of all benchmarks</h2>
          <StackedColumn data={benchmarkSummaryData} />
        </div>
      ) : null}
    </Skeleton>
  );
};

export { BenchmarkSummaryReports };
