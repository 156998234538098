import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LocationSearch } from "./LocationSearch";

const Location = () => (
  <Switch>
    <Route exact path="/location" component={LocationSearch} />
    <Redirect to="/login" />
  </Switch>
);
export { Location };
