import { defaults } from "./default";

const common = {
  getAdvisorsList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-advisor-list"
    }
  },
  getStudentsList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-student-list"
    }
  },
  getTeachersList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-teacher-list"
    }
  },
  createAdviserOffDays: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/create-adviser-offdays "
    }
  },
  getLocationList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-location"
    }
  },
  uploadImportSheet: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/import-sheet",
      headerProps: {
        "Content-Type": "multipart/form-data"
      }
    }
  },
  verifyTwoFactor: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/verify-twofactor "
    }
  },
  getUserDetailsDt: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-user-details-dt/:userId"
    }
  },
  updateActionCommonDetails: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/update-action-common-details "
    }
  },
  getActionCommonDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-action-common-details"
    }
  },
  getRevisionDatesVersions: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/revision-dates"
    }
  },
  getRevisionDateActionPlanDetails: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/action-plan-details"
    }
  },
  getAppointmentType: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-appointment-type"
    }
  },
  getSchoolAppointmentLocations: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/school-appointment-locations"
    }
  },
  createLocation: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/school-appointment-location"
    }
  },
  downloadPdf: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/action-plan-download/:studentId"
    }
  },
  generateChat: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/generate-chat"
    }
  },
  downloadChat: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/download-chat"
    }
  },
  clearStudentListFilter: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/clear-student-list-filter"
    }
  },
  getYearsList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-years-list"
    }
  },
  exportStudents: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/download-student-list"
    }
  },
  manageStudents: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/manage-students"
    }
  }
};

export default common;
