import React, { useEffect, useState } from "react";
import { Form, notification } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { saveStudentData } from "../../../../services/destination";
import RoleCheck from "../../../helpers/RoleCheck";
import { MomentDateFormats } from "../../../../constants";
import {
  ADVISER,
  SCHOOL_ADMIN,
  SUPER_ADMIN
} from "../../../../constants/rolesSubtypes";

const StudentProfile = ({ user, studentProfileDetails, studentId }) => {
  const [loading, setLoading] = useState(false);
  const dayMonthYearHourMinutes =
    MomentDateFormats.CustomDateFormat.dayMonthYearHourMinutes;
  const [form] = Form.useForm();

  useEffect(() => {
    if (studentProfileDetails) {
      form.setFieldsValue({
        learner_manger_notes:
          studentProfileDetails.user_data.learner_manager_notes
      });
    }
  }, []);

  const onFinish = formValues => {
    setLoading(true);
    formValues.student_id = studentId;
    saveStudentData({ body: formValues })
      .then(() => {
        notification.success({ message: `Saved the notes` });
      })
      .catch(error => {
        console.log(error);
        notification.error({ message: `Failed to save notes` });
      })
      .finally(() => setLoading(false));
  };

  const renderManagerNotesForm = () => {
    return (
      <Form form={form} onFinish={onFinish}>
        <div className="form-group">
          <label className="labelstyle">SEND and Learning Manager notes</label>
          <Form.Item name="learner_manger_notes">
            <TextArea
              className="textarea-style"
              placeholder={"Add notes..."}
              disabled={user && [SUPER_ADMIN].includes(user.role_slug)}
              autoSize={{ minRows: 5, maxRows: 8 }}
            />
          </Form.Item>
        </div>
        <RoleCheck authority={[ADVISER, SCHOOL_ADMIN]}>
          <div className="form-group text-right ml-2">
            <input
              type="submit"
              className="button-style button-style-green-btm button-style-normal-button"
              disabled={loading}
              value={loading ? "Loading..." : "Submit"}
            />
          </div>
        </RoleCheck>
      </Form>
    );
  };

  return (
    <div className="content-panel">
      <div className="profile-wrapper">
        <div className="info-box">
          <h4 className="small-heading">Name</h4>
          <div className="pro-place-inner">
            <div className="info">
              <h2 className="page-sub-heading">
                {studentProfileDetails.user_data.name || ""}
              </h2>
              {studentProfileDetails.user_data &&
                studentProfileDetails.user_data.adviser && (
                  <p>Adviser: {studentProfileDetails.user_data.adviser}</p>
                )}
              {
                <p>
                  Last login:{" "}
                  {studentProfileDetails.user_data.last_login
                    ? moment(studentProfileDetails.user_data.last_login).format(
                        dayMonthYearHourMinutes
                      )
                    : "Hasn’t logged in yet"}
                </p>
              }
            </div>
          </div>
        </div>
        <div className="info-box">
          <h4 className="small-heading">Location</h4>
          <div className="address">
            <ul>
              <li>{studentProfileDetails.school_data[0].school_name}</li>
              <li>
                {studentProfileDetails.school_data[0].school_address_line_1}
              </li>
              <li>
                {studentProfileDetails.school_data[0].school_address_line_2}
              </li>
            </ul>
          </div>
        </div>

        {/*Todo: Hide Static Data*/}
        {/*<div className="info-box border-bottom-0">*/}
        {/*  <h4 className="small-heading">Specialism</h4>*/}
        {/*  <div className="degree">*/}
        {/*    <span>Graphic design</span>, <span>Web design</span>,{" "}*/}
        {/*    <span>Art &amp; design</span>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*TODO: BACKEND INTEGRATION WWHAT STUDENT HAS WRITTEN NEEDED IN API RESPONSE*/}
        <div className="info-box">
          <div className="row">
            <div className="col-12 col-md-6">{renderManagerNotesForm()}</div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label className="labelstyle">
                  What the student has written about themselves
                </label>
                <TextArea
                  readOnly={true}
                  className="textarea-style"
                  autoSize={{ minRows: 5, maxRows: 8 }}
                  value={
                    (studentProfileDetails.user_data &&
                      studentProfileDetails.user_data.about_me) ||
                    ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProfile;
