import React, { useEffect, useState } from "react";
import { withContext } from "Context";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { readEmail, sendEmail, singleEmail } from "../../../../services/user";
import { Button, Collapse, Form, notification, Skeleton } from "antd";
import { CKEditor } from "ckeditor4-react";
import moment from "moment";

const { Panel } = Collapse;

function stripHtml(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const ViewEmail = ({ user }) => {
  const [emailDetails, setEmailDetails] = useState();
  const [showReply, setShowReply] = useState(false);

  const { emailId } = useParams();

  const emailGet = () => {
    singleEmail({ body: { id: emailId } })
      .then(res => {
        setEmailDetails(res);
        setActiveKey(res.mails.length || 1);
        readEmail({ body: { id: emailId } });
        setLoading(false);
        setSendLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setSendLoading(false);
      })
      .finally(() => {
        setLoading(false);
        setSendLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    emailGet();
  }, []);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [activeKey, setActiveKey] = useState([1]);

  const history = useHistory();

  const editorConfig = {
    versionCheck: false,
    // Add other configuration options if needed
  };

  return (
    <div className="content-panel">
      <Skeleton loading={loading}>
        <div className="profile-wrapper">
          <h2 className="page-sub-heading">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              <i className="fas fa-arrow-left mr-2"></i>
            </span>
            {emailDetails && emailDetails.mails[0].subject}
          </h2>
          {emailDetails && (
            <Collapse
              onChange={values => {
                console.log(values, "Values");
                setActiveKey([values.reverse()[0]]);
              }}
              style={{ background: "#fff" }}
              // bordered={false}
              activeKey={activeKey}
              defaultActiveKey={[
                (emailDetails && emailDetails.mails.length) || 0
              ]}
              expandIcon={({ isActive }) => <></>}
              className="site-collapse-custom-collapse"
            >
              {emailDetails &&
                emailDetails.mails.map((mail, index) => (
                  <Panel
                    header={
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="d-flex">
                            <div>From:</div>
                            <div className="font-weight-bold ml-2">
                              {mail.sender_name}
                            </div>
                            <div className="d-none ml-2">
                              {`<${
                                user.email === mail.sender_email
                                  ? mail.sender_email
                                  : mail.sender_custom_email
                              }>`}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div>To:</div>
                            <div className="font-weight-bold ml-2">
                              {mail.receiver_name}
                            </div>
                            <div className="d-none ml-2">
                              {`<${
                                user.mail === mail.receiver_email
                                  ? mail.receiver_email
                                  : mail.receiver_custom_email
                              }>`}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {moment(mail.created_at).format("LLL")}
                        </div>
                      </div>
                    }
                    key={index + 1}
                    className="site-collapse-custom-panel"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          {/* <h2 className="page-sub-heading">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => history.goBack()}>
                            <i className="fas fa-arrow-left mr-2"></i>
                          </span>
                          {mail && mail.subject}
                        </h2> */}
                          {/* <div className="d-flex">
                            <div className="d-flex">
                              <div className="font-weight-normal">From: </div>
                              <div className="ml-2">
                                <div className="font-weight-bold">
                                  {mail && mail.sender_name}
                                </div>
                                <div>{mail && mail.sender_email}</div>
                              </div>
                            </div>

                            <div className="d-flex ml-4">
                              <div className="font-weight-normal">To: </div>
                              <div className="ml-2">
                                <div className="font-weight-bold">
                                  {mail && mail.receiver_name}
                                </div>
                                <div>{mail && mail.receiver_email}</div>
                              </div>
                            </div>
                          </div> */}

                          <div
                            className="mt-4"
                            dangerouslySetInnerHTML={{
                              __html: mail && mail.mail_body
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Panel>
                ))}
            </Collapse>
          )}
          {!showReply ? (
            <div className="my-4">
              <Button onClick={() => setShowReply(true)}>Reply</Button>
            </div>
          ) : (
            <div className="my-4">
              <Form
                form={form}
                onFinish={values => {
                  const body = { ...values, from: user.id };
                  setSendLoading(true);
                  sendEmail({
                    body: {
                      ...body,
                      show_body: stripHtml(body.mail_body).slice(0, 100),
                      id: emailDetails.mails[0].id,
                      to:
                        user.id === emailDetails.mails[0].sender_id
                          ? emailDetails.mails[0].receiver_id
                          : emailDetails.mails[0].sender_id,
                      from: user.id,
                      subject: emailDetails.mails[0].subject
                    }
                  })
                    .then(res => {
                      setShowReply(false);
                      emailGet();
                      notification.success({
                        message: res.message || `Email sent successfully!`
                      });
                      form.resetFields();
                    })
                    .catch(err => {
                      notification.error({
                        message:
                          err.message ||
                          err.data.message ||
                          `Failed to send email!`
                      });
                      setSendLoading(false);
                    });
                }}
              >
                <Form.Item name="mail_body">
                  <CKEditor
                    type="classic"
                    config={editorConfig}
                    onChange={data => {
                      form.setFieldsValue({
                        mail_body: data.editor.getData()
                      });
                    }}
                  />
                </Form.Item>
                <div className="d-flex my-2 justify-content-end">
                  <Button onClick={() => setShowReply(false)}>Cancel</Button>
                  <Button
                    loading={sendLoading}
                    onClick={() => form.submit()}
                    className="ml-2"
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </Skeleton>
    </div>
  );
};

export default withContext(([{ user }]) => ({ user }), ViewEmail);
