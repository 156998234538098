import React from "react";
import { Route, Switch } from "react-router-dom";
import { withContext } from "Context";
import { ImportData } from "./import-data";
import { SCHOOL_ADMIN } from "../../../constants";

const Import = ({ user }) => {
  const routes = [
    {
      path: "/import-data",
      role: [SCHOOL_ADMIN],
      component: ImportData
    }
  ];

  const renderUI = () => (
    <Switch>
      {routes.map(route => {
        if (route.role.includes(user.role_slug)) {
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={route.component}
            />
          );
        }
      })}
    </Switch>
  );

  return renderUI();
};

export default withContext(([{ user }]) => ({ user }), Import);
