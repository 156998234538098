import React, { useState } from "react";
import moment from "moment";
import { Button, DatePicker, Form, Input, notification } from "antd";
import { ModalWrapper } from "../../../modals/shared/ModalWrapper";
import { createGroupCallAppointment } from "../../../../services/appointment";
import { disabledRangeTime } from "./../../../../components/common";
import { APPOINTMENT_TYPES, MomentDateFormats } from "../../../../constants";

const { RangePicker } = DatePicker;

const { TextArea } = Input;

const GroupCallAppointmentModal = ({ user, onCancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [groupCallData, setGroupCallData] = useState();

  const onFinish = fieldsValue => {
    if (user && !user.school_id) {
      notification.info({ message: "Please select school" });
      return;
    }

    const { webinar_time, group_call_description } = fieldsValue;
    const body = {
      school_id: user.school_id,
      start_time: moment.utc(webinar_time[0]).format(),
      end_time: moment.utc(webinar_time[1]).format(),
      group_call_description: group_call_description
    };
    bookGroupCallAppointment(body);
  };

  const bookGroupCallAppointment = body => {
    setLoading(true);
    createGroupCallAppointment({ body })
      .then(response => {
        if (response.success) {
          setGroupCallData(response);
        } else {
          notification.error({
            message:
              response.message || "Failed to create group call appointment"
          });
        }
      })
      .catch(error => {
        notification.error({
          message: "Failed to create group call appointment"
        });
      })
      .finally(() => setLoading(false));
  };

  const renderBookedWebinarAppointmentStatus = () => {
    return (
      <div className="row">
        <div className="col-12 col-lg-6 border-right-side">
          <span
            className={groupCallData.success ? "success-icon" : "error-icon"}
          >
            {groupCallData.success ? (
              <i className="fas fa-check-circle" />
            ) : (
              <i className="fas fa-times" />
            )}
          </span>
          <h5>{groupCallData.message}</h5>
        </div>
        {groupCallData && groupCallData.appointment_details && (
          <div className="col-12 col-lg-6">
            <br />
            {moment(groupCallData.appointment_details.appointment_date).format(
              MomentDateFormats.LongDateFormat.LLLL
            )}
            <br />
            {groupCallData.appointment_details.duration} minutes
            <br />
            <span className="text-capitalize">
              {
                APPOINTMENT_TYPES[
                  groupCallData.appointment_details.appointment_type
                ].name
              }
            </span>{" "}
            <br />
          </div>
        )}
      </div>
    );
  };
  return (
    <ModalWrapper
      title={`Group call functionality`}
      width={600}
      footer={null}
      visible={true}
      onCancel={onCancel}
      afterClose={() => {
        form.resetFields();
        onCancel();
      }}
    >
      {!groupCallData && (
        <Form form={form} onFinish={onFinish}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="webinar_time" className="labelstyle">
                  Enter Webinar time
                </label>
                <Form.Item
                  name="webinar_time"
                  rules={[
                    {
                      required: true,
                      message: "Please select start and end date."
                    }
                  ]}
                >
                  <RangePicker
                    className="select-style w-100"
                    disabledTime={disabledRangeTime}
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment(
                          "00:00",
                          MomentDateFormats.CustomDateFormat.hourMinute
                        ),
                        moment(
                          "11:59",
                          MomentDateFormats.CustomDateFormat.hourMinute
                        )
                      ]
                    }}
                    format={
                      MomentDateFormats.CustomDateFormat.dayMonthYearHourMinutes
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="group_call_description" className="labelstyle">
                  Description
                </label>
                <Form.Item
                  name="group_call_description"
                  rules={[
                    { required: true, message: "Description is required" }
                  ]}
                >
                  <TextArea />
                </Form.Item>
              </div>
            </div>
            <div className="col-md-12 offset-9">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {loading ? "Loading..." : "Update"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      )}

      {groupCallData && renderBookedWebinarAppointmentStatus()}
    </ModalWrapper>
  );
};

export default GroupCallAppointmentModal;
