import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";

export const StateContext = createContext();

export const GlobalStateProvider = ({ initialState, reducer, children }) => {
  const value = useReducer(reducer, initialState);
  if (process.env.REACT_APP_TESTING) {
    window.__setGlobalState = action => {
      value[1](action); // value[1] is the dispatch function from useReducer
    };
  }
  return (
    <StateContext.Provider value={value || {}}>
      {children}
    </StateContext.Provider>
  );
};

export const withContext = (stateAndDispatchToProps, Component) => {
  return props => {
    return (
      <StateContext.Consumer>
        {contextValue => {
          const contextProps = stateAndDispatchToProps(contextValue);
          return <Component {...props} {...contextProps} />;
        }}
      </StateContext.Consumer>
    );
  };
};

GlobalStateProvider.propTypes = {
  reducer: PropTypes.func.isRequired,
  initialState: PropTypes.shape().isRequired,
  children: PropTypes.shape().isRequired
};
