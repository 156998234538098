import React from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { withContext } from "Context";
import { Y10Dashboard } from "./Y10Dashboard";
import Year10Dashboard from "./Year10Dashboard";
import Year11Dashboard from "./Year11Dashboard";
import Year11LDashboard from "./Year11LDashboard";
import Year12Dashboard from "./Year12Dashboard";
import Year13Dashboard from "./Year13Dashboard";
import Year13LDashboard from "./Year13LDashboard";
import { SeptemberGuarantee } from "./SeptemberGuarantee";
import { OctoberActivitySurvey } from "./OctoberActivitySurvey";
import { Pre16Reports } from "./Pre16Reports";
import Post16Reports from "./Post16Reports";
import { ADVISER, Routes, SCHOOL_ADMIN, SUPER_ADMIN } from "../../../constants";
import { Col, Row, Button } from "antd";
import { PageMetaTags } from "Common";
//import { reports } from "../../common/SideBar/routes";
import "./style.scss";

const Reports = ({ user }) => {
  /*
  Note:
  This HOC will allow to pass data to all routes instead of using
  `withContext` on each component to get redux store e.g user
 */
  const withUser = (WrappedComponent, rest) => {
    return props => {
      return <WrappedComponent {...props} {...rest} />;
    };
  };

  const ReportsComponent = () => {
    return (
      <div data-cy="reports-page" className="content-panel">
        <PageMetaTags title="Report" />
        <h1 className="page-heading">Reports</h1>
        <div className="container">
          <h5 style={{ color: "#4e4d53" }}>Select Year Group - </h5>
          <p className="pheight"></p>
          <Row
            gutter={[36, 36]}
            className="col-12"
            style={{ paddingBottom: "20px" }}
          >
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              style={{ marginLeft: "60px" }}
            >
              <Link to="/reports/dashboard-y10">
                <Button type="primary" style={{ background: "#19a75a" }}>
                  Year 10
                </Button>
              </Link>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              style={{ marginLeft: "60px" }}
            >
              <Link to="/reports/dashboard-y11">
                <Button type="primary" style={{ background: "#19a75a" }}>
                  Year 11
                </Button>
              </Link>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              style={{ marginLeft: "60px" }}
            >
              <Link to="/reports/dashboard-y11-leaver">
                <Button type="primary" style={{ background: "#19a75a" }}>
                  Year 11 L
                </Button>
              </Link>
            </Col>
          </Row>
          <p style={{ height: "64px" }}></p>
          <Row
            gutter={[36, 36]}
            className="col-12"
            style={{ paddingBottom: "20px" }}
          >
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              style={{ marginLeft: "60px" }}
            >
              <Link to="/reports/dashboard-y12">
                <Button type="primary" style={{ background: "#2b355b" }}>
                  Year 12
                </Button>
              </Link>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              style={{ marginLeft: "60px" }}
            >
              <Link to="/reports/dashboard-y13">
                <Button type="primary" style={{ background: "#2b355b" }}>
                  Year 13
                </Button>
              </Link>
            </Col>
            <Col
              xl={6}
              lg={6}
              md={12}
              sm={24}
              xs={24}
              style={{ marginLeft: "60px" }}
            >
              <Link to="/reports/dashboard-y13-leaver">
                <Button type="primary" style={{ background: "#2b355b" }}>
                  Year 13 L
                </Button>
              </Link>
            </Col>
          </Row>

          <p className="pheight"></p>

          <p className="pheight"></p>
        </div>
      </div>
    );
  };
  const routes = [
    {
      path: "/reports",
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: ReportsComponent
    },
    {
      path: Routes.REPORTS.PRE16.YEAR10DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Year10Dashboard
    },
    {
      path: Routes.REPORTS.PRE16.YEAR11DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Year11Dashboard
    },
    {
      path: Routes.REPORTS.PRE16.YEAR11LDASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Year11LDashboard
    },
    {
      path: Routes.REPORTS.PRE16.YEAR12DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Year12Dashboard
    },
    {
      path: Routes.REPORTS.PRE16.YEAR13DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Year13Dashboard
    },
    {
      path: Routes.REPORTS.PRE16.YEAR13LDASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Year13LDashboard
    },
    {
      path: Routes.REPORTS.PRE16.Y10_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Y10Dashboard
    },
    {
      path: Routes.REPORTS.PRE16.SEPTEMBER_GUARANTEE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: SeptemberGuarantee
    },
    {
      path: Routes.REPORTS.PRE16.OCTOBER_ACTIVITY_SURVEY,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: OctoberActivitySurvey
    },
    {
      path: Routes.REPORTS.PRE16.Y11_LIVE_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.Y11_L_LIVE_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.FSM_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.PP_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.SEND_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.LAC_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.PRE16.EAL_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Pre16Reports
    },
    {
      path: Routes.REPORTS.POST_Y12_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_Y13_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_Y13_L_DASHBOARD,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_BURSARY,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_EAL,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_LAC,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_OCTOBER_SUR,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_SEND,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    },
    {
      path: Routes.REPORTS.POST_SETP_GURANTEE,
      role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
      component: Post16Reports
    }
  ];

  const renderUI = () => (
    <Switch>
      {routes.map(route => {
        if (route.role.includes(user.role_slug)) {
          return (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={withUser(route.component, { user })}
            />
          );
        }
      })}
      <ReportsComponent />
    </Switch>
  );

  return renderUI();
};

export default withContext(contextValue => {
  if (!contextValue && !contextValue?.[0]?.user) {
    return {};
  }
  const [state] = contextValue;
  const { user } = state;
  return {
    user
  };
}, Reports);
