import { callApi } from "../utils/apiUtils";
import destination from "./endpoints/destination";

export const saveks5Studies = ({ body }) =>
  callApi({ uriEndPoint: destination.saveks5Studies.v1, body });

export const getks5Studies = ({ query }) =>
  callApi({ uriEndPoint: destination.getks5Studies.v1, query });

export const saveMayReturn = ({ body }) =>
  callApi({ uriEndPoint: destination.saveMayReturn.v1, body });

export const getMayReturn = ({ query }) =>
  callApi({ uriEndPoint: destination.getMayReturn.v1, query });

export const saveSubjectOptions = ({ body }) =>
  callApi({ uriEndPoint: destination.saveSubjectOptions.v1, body });

export const getSubjectOptions = ({ query }) =>
  callApi({ uriEndPoint: destination.getSubjectOptions.v1, query });

export const savePost16Question = ({ body }) =>
  callApi({ uriEndPoint: destination.savePost16Question.v1, body });

export const getPost16Question = ({ query }) =>
  callApi({ uriEndPoint: destination.getPost16Question.v1, query });

export const saveOctoberActivity = ({ body }) =>
  callApi({ uriEndPoint: destination.saveOctoberActivity.v1, body });

export const getOctoberActivity = ({ query }) =>
  callApi({ uriEndPoint: destination.getOctoberActivity.v1, query });

export const getInformationNotes = ({ query }) =>
  callApi({ uriEndPoint: destination.getInformationNotes.v1, query });

export const saveInformationNotes = ({ body }) =>
  callApi({ uriEndPoint: destination.saveInformationNotes.v1, body });

export const getKS4Data = ({ query }) =>
  callApi({ uriEndPoint: destination.getKS4Data.v1, query });

export const saveKS4Data = ({ body }) =>
  callApi({ uriEndPoint: destination.saveKS4Data.v1, body });

export const getKS4Questionnaire = ({ query }) =>
  callApi({ uriEndPoint: destination.getKS4Questionnaire.v1, query });

export const saveKS4Questionnaire = ({ body }) =>
  callApi({ uriEndPoint: destination.saveKS4Questionnaire.v1, body });

export const getSeptemberGuarantee = ({ query }) =>
  callApi({ uriEndPoint: destination.getSeptemberGuarantee.v1, query });

export const saveSeptemberGuarantee = ({ body }) =>
  callApi({ uriEndPoint: destination.saveSeptemberGuarantee.v1, body });

export const getStudentData = ({ query }) =>
  callApi({ uriEndPoint: destination.getStudentData.v1, query });

export const saveStudentData = ({ body }) =>
  callApi({ uriEndPoint: destination.saveStudentData.v1, body });

export const getY11Dashboard = ({ query }) =>
  callApi({ uriEndPoint: destination.getY11Dashboard.v1, query });

export const saveY11Dashboard = ({ body }) =>
  callApi({ uriEndPoint: destination.saveY11Dashboard.v1, body });

export const getAdvisorsDropdown = ({ body, query }) =>
  callApi({ uriEndPoint: destination.getAdvisorsDropdown.v1, body, query });

export const inlineUpdate = ({ body }) =>
  callApi({ uriEndPoint: destination.inlineUpdate.v1, body });

export const getSeptemberProvider = () =>
  callApi({ uriEndPoint: destination.getSeptemberProvider.v1 });

export const getOctoberProvider = () =>
  callApi({ uriEndPoint: destination.getOctoberProvider.v1 });

export const getDestinationDropdown = ({ query }) =>
  callApi({ uriEndPoint: destination.getDestinationDropdown.v1, query });
