import React, { useEffect, useRef, useState } from "react";
import { Pie } from "@ant-design/plots";

const PieChart = ({ chartData, chartLabel, colors }) => {
  const chartRef = useRef();
  const [data, setData] = useState([]);
  const [pieLabel, setPieLabel] = useState([]);
  const [colorsArr, setcolorsArr] = useState([]);

  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "sno",
    color: colorsArr,
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      autoRotate: false,
      style: {
        textAlign: "center",
        fontSize: 14
      }
    },
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      }
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "26px"
        },
        content: pieLabel
      }
    }
  };

  useEffect(() => {
    setData(chartData);
    setPieLabel(chartLabel);
    setcolorsArr(colors);
  }, [chartData]);

  return (
    <React.Fragment>
      <Pie {...config} chartRef={chartRef} />
    </React.Fragment>
  );
};

export { PieChart };
