import { Empty, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getStudentsList } from "Services/common";

const RecentStudents = props => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStudents = query => {
    setLoading(true);
    getStudentsList({
      body: {
        sort_column: "id",
        sort_order: "desc",
        page_limit: 3,
        page: 1
      },
      query: query || {}
    })
      .then(res => {
        setLoading(false);
        setStudents(res.user_list);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (props.user && props.user.school_id) {
      getStudents({ school_id: props.user.school_id });
    } else {
      getStudents();
    }
  }, [props.user.school_id]);

  return (
    <div data-cy="dashboard-recent-students-section" className="white-box">
      <h2 className="page-sub-heading">Recently Added Students</h2>
      <Skeleton loading={loading}>
        <ul data-cy="dashboard-recent-students-list" className="student-list">
          {Array.isArray(students) && students.length > 0 ? (
            (students || []).map((student, index) => (
              <li key={index}>
                <div className="student-holder">
                  <h6>{student.name}</h6>
                  <p>{student.school_name}</p>
                </div>
                <Link
                  data-cy="dashboard-recent-students-entry"
                  to={`/students/${student.id}`}
                >
                  <i className="fas fa-arrow-right" />
                </Link>
              </li>
            ))
          ) : (
            <Empty description="No students found" />
          )}
        </ul>
        {Array.isArray(students) && students.length > 0 && (
          <Link to="/students" className="icon-button icon-button--iconcolor">
            <span>
              <i className="fas fa-ellipsis-h" />
            </span>{" "}
            View all students
          </Link>
        )}
      </Skeleton>
    </div>
  );
};

export default RecentStudents;
