import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import StudentAdvisorSearch from "./StudentAdvisorSearch";
import ViewAdviser from "./ViewAdviser";
import AdviserSettings from "./AdviserSettings";
import AdvisorSearch from "./AdviserSearch";
import MyAdviser from "./MyAdviser";
import { withContext } from "Context";
import { STUDENT } from "../../../constants";

const Advisor = ({ user }) => (
  <Switch>
    <Route exact path="/appointments/settings" component={AdviserSettings} />
    <Route
      exact
      path="/adviser"
      component={
        user.role_slug === STUDENT ? StudentAdvisorSearch : AdvisorSearch
      }
    />
    <Route exact path="/adviser/:adviserId" component={ViewAdviser} />
    {/* <Route exact path="/my-adviser" component={ViewAdviser} /> */}
    <Route exact path="/my-adviser" component={MyAdviser} />
    <Redirect to="/login" />
  </Switch>
);

export default withContext(([{ user }]) => ({ user }), Advisor);
