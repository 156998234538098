import * as Types from "../types";

const profileMiddleware = ({ type, payload }) => {
  switch (type) {
    case Types.PROFILE_UPDATE_SUCCESS:
      const currentUser = JSON.parse(localStorage.getItem("user"));
      let updatedUser;
      if (currentUser) {
        updatedUser = {
          ...currentUser,
          ...payload
        };
      } else {
        updatedUser = payload;
      }
      localStorage.setItem("user", JSON.stringify(updatedUser));
      break;
    default:
      break;
  }
};

export default profileMiddleware;
