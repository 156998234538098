import React from "react";
import { withContext } from "Context";
import MainDashboard from "./MainDashboard/MainDashboard";
import { PageMetaTags } from "Common";
import StudentDashboard from "./StudentDashboard/StudentDashboard";
import TeacherDashboard from "./TeacherDashboard/TeacherDashboard";
import { SCHOOL_ADMIN, STUDENT } from "../../../constants";

const Dashboard = ({ user }) => {
  const component = () => {
    if (user.role_slug === STUDENT) {
      return <StudentDashboard user={user} />;
    } else if (user.role_slug === SCHOOL_ADMIN) {
      return <TeacherDashboard user={user} />;
    } else {
      return <MainDashboard />;
    }
  };

  return (
    <>
      <PageMetaTags title="Dashboard" />
      {component()}
    </>
  );
};

export default withContext(
  ([{ user }, dispatch]) => ({
    // state
    user
  }),
  Dashboard
);
