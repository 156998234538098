import { defaults } from "./default";

export const compassreport = {
  getBenchmark: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/get-benchmarks"
    }
  },
  getBenchmarkReports: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/benchmark-report"
    }
  },
  getBenchmarkSummaryReports: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/benchmark-summary-report"
    }
  }
};
