import * as Types from "../types";

const studentListReducer = (state, action) => {
  switch (action.type) {
    case Types.UPDATE_INITIAL_LOAD_FLAG:
      return {
        ...state,
        initialLoad: false
      };
    default:
      return state;
  }
};

export default studentListReducer;
