import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { GlobalStateProvider } from "Context/index";
import { mainReducer } from "Reducers";
import { initialState } from "./store/initialState";
import App from "./components/App";
import "antd/dist/antd.less";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <GlobalStateProvider initialState={initialState} reducer={mainReducer}>
    <BrowserRouter>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}
      >
        <App />
      </GoogleOAuthProvider>
    </BrowserRouter>
  </GlobalStateProvider>,
  document.getElementById("root")
);
