import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { withContext } from "Context";
import { Button } from "antd";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import {
  Chat,
  MessageInput,
  MessageList,
  TypingIndicator
} from "@pubnub/react-chat-components";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { AppointmentCallHeader } from "../AppointmentHeader/AppointmentCallHeader";
import { JoiningConfirmationModal } from "../JoiningConfirmationModal/JoiningConfirmationModal";
import { STUDENT, ADVISER } from "../../../../../constants";

function LiveChat({ appointmentDetails, config, user }) {
  const pubnub = new PubNub({
    uuid: user.email,
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
    secretKey: process.env.REACT_APP_PUBNUB_SECRET_KEY
  });

  const [agreedConsent, setAgreedConsent] = useState(false);

  if (!config) {
    return;
  }

  const CHAT_BOX_STYLES = {
    height: "64vh"
  };

  const leaveChatWindow = () => {
    // Note: In case some other browsers may create problem:
    window.open("/appointments", "_self", "");
    window.close();
  };

  const handleIsReadyToStartCall = () => {
    setAgreedConsent(true);
  };
  const [currentTimetoken, setCurrentTimetoken] = useState();
  const [channelss, setChannelss] = useState();
  const handlePresence = event => {
    setChannelss(config.channel_name);
    setCurrentTimetoken(event.timestamp);
  };

  useEffect(() => {
    pubnub.addListener({ presence: handlePresence });
    pubnub.addListener({
      message: function(message) {
        var element = document.getElementsByClassName("pn-msg-list__spacer")[0];
        element.innerHTML = "";
      }
    });

    pubnub.fetchMessages(
      {
        channels: [channelss],
        timestamp: currentTimetoken,
        count: 15
      },
      (status, response) => {
        console.log(response);

        if (response) {
          if (response.hasOwnProperty("channels")) {
            var element = document.getElementsByClassName(
              "pn-msg-list__spacer"
            )[0];
            if (Object.keys(response.channels).length > 0) {
              const ccc = encodeURIComponent(channelss);
              if (response.channels[ccc].length == 1) {
                pubnub.publish(
                  {
                    channel: channelss,
                    message: {
                      text:
                        response.channels[ccc][0].uuid + " has joined the chat."
                    }
                  },
                  function(status, response) {
                    console.log(status);
                    console.log(response);
                  }
                );
              }
              element.innerHTML = "";
            } else {
              element.innerHTML =
                '<h4 style="color:red; text-align:center">The live chat session is initiating, please wait...</h4>';
            }
          } else {
            var element = document.getElementsByClassName(
              "pn-msg-list__spacer"
            )[0];
            if (element) {
              element.innerHTML =
                '<h4 style="color:red; text-align:center">The live chat session is initiating, please wait...</h4>';
            }
          }
        }
      }
    );
    setChannelss(config.channel_name);
  }, [pubnub]);

  const renderLiveChatComponent = () => {
    return (
      <div>
        <AppointmentCallHeader
          title={"Live chat appointment between"}
          appointmentDetails={appointmentDetails}
          startTime={appointmentDetails.appointment.start_time}
        >
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={() => leaveChatWindow()}
          >
            {" "}
            Leave chat{" "}
          </Button>
        </AppointmentCallHeader>

        <div className="m-4" style={CHAT_BOX_STYLES}>
          <PubNubProvider client={pubnub}>
            <Chat
              theme="light"
              users={config.users}
              currentChannel={config.channel_name}
              channels={[config.channel_name]}
              typingIndicatorTimeout={3}
            >
              <MessageList
                enableReactions
                fetchMessages={10}
                reactionsPicker={<Picker />}
              >
                <TypingIndicator />
              </MessageList>
              <MessageInput
                placeholder={"Send message"}
                senderInfo={true}
                typingIndicator
                emojiPicker={<Picker />}
              />
            </Chat>
          </PubNubProvider>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {user && (user.role_slug === STUDENT || user.role_slug === ADVISER) ? (
        agreedConsent ? (
          renderLiveChatComponent()
        ) : (
          <JoiningConfirmationModal
            title={"Are you ready to start the chat?"}
            visible={!agreedConsent}
            handleIsReadyToStartCall={handleIsReadyToStartCall}
          />
        )
      ) : (
        renderLiveChatComponent()
      )}
    </React.Fragment>
  );
}

export default withContext(([{ user }]) => ({ user }), LiveChat);
