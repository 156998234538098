import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, notification, Alert } from "antd";
import PropTypes from "prop-types";
import { ModalWrapper } from "../shared/ModalWrapper";
import { resendOTPCode } from "../../../services/user";

function TwoFactorVerification({ onVerificationCode, onCancel }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // Use useEffect to focus the input element when the component mounts
  useEffect(() => {
    document.getElementById("2fa-firstInput").focus();
  }, []);
  const onFinish = values => {
    if (onVerificationCode) {
      const verificationCode = Object.keys(values).reduce(
        (previous, next) => previous + values[next],
        ""
      );
      onVerificationCode(verificationCode);
    }
  };

  const handlerAutonext = (event, inputRefName) => {
    event.preventDefault();
    const nextInputField = document.querySelector(
      `input[name=${inputRefName}]`
    );
    // If found, focus the next field
    if (nextInputField && event.target.value) {
      nextInputField.focus();
    }
  };
  const handlerArrowLeftRightMove = (event, inputRefId) => {
    event.preventDefault();
    let nextRef;

    if (event.key === "ArrowLeft" || event.keyCode === 37) {
      if (inputRefId === 1) {
        nextRef = "firstInput";
      }
      if (inputRefId === 2) {
        nextRef = "firstInput";
      }
      if (inputRefId === 3) {
        nextRef = "secondInput";
      }
      if (inputRefId === 4) {
        nextRef = "thirdInput";
      }
    } else if (event.key === "ArrowRight" || event.keyCode === 39) {
      if (inputRefId === 1) {
        nextRef = "secondInput";
      }
      if (inputRefId === 2) {
        nextRef = "thirdInput";
      }
      if (inputRefId === 3) {
        nextRef = "fourthInput";
      }
      if (inputRefId === 4) {
        nextRef = "fourthInput";
      }
    } else if (event.key === "Backspace" || event.keyCode === 8) {
      // Logic for moving to the previous input
      if (inputRefId === 1) {
        nextRef = "firstInput"; // Wrap around to the last input if we're at the first
      } else if (inputRefId === 2) {
        nextRef = "firstInput";
      } else if (inputRefId === 3) {
        nextRef = "secondInput";
      } else if (inputRefId === 4) {
        nextRef = "thirdInput";
      }
    }

    const nextInputField = document.querySelector(`input[name=${nextRef}]`);
    if (nextInputField !== null) {
      nextInputField.focus();
    }
  };

  const resendCode = event => {
    setLoading(true);
    event.preventDefault();
    resendOTPCode()
      .then(() => {
        notification.success({
          description:
            "We sent you security code. Please check your registered mail."
        });
      })
      .catch(error => console.log("TwoFactorVerification.resendCode:", error))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ModalWrapper
      title={"Two Factor Authentication (2FA)"}
      width={400}
      onCancel={onCancel}
      visible={true}
    >
      <Alert
        message="Do not close this page whilst looking for your code."
        type="info"
      />
      <br />
      <h4 data-cy="login-2fa" className="two-factor-heading">
        Please enter the 4-digit verification code we've sent via email
      </h4>
      <span className={"text-muted"}>
        If you don't receive a code, please get in touch.
      </span>

      <Form
        name="two-factor-form"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className={"d-flex two-factor-form__pins"}>
          <Form.Item data-cy="2fa1" name="first">
            <Input
              id="2fa-firstInput"
              name="firstInput"
              type="password"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
              onChange={e => handlerAutonext(e, "secondInput")}
              onKeyUp={e => handlerArrowLeftRightMove(e, 1)}
            />
          </Form.Item>
          <Form.Item data-cy="2fa2" name="second">
            <Input
              name="secondInput"
              type="password"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
              onChange={e => handlerAutonext(e, "thirdInput")}
              onKeyUp={e => handlerArrowLeftRightMove(e, 2)}
            />
          </Form.Item>
          <Form.Item data-cy="2fa3" name="third">
            <Input
              name="thirdInput"
              type="password"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
              onChange={e => handlerAutonext(e, "fourthInput")}
              onKeyUp={e => handlerArrowLeftRightMove(e, 3)}
            />
          </Form.Item>
          <Form.Item data-cy="2fa4" name="fourth">
            <Input
              name="fourthInput"
              type="password"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
              onChange={e => handlerAutonext(e, "fourthInput")}
              onKeyUp={e => handlerArrowLeftRightMove(e, 4)}
            />
          </Form.Item>
        </div>
        <Form.Item data-cy="verify-otp">
          <Button type="primary" htmlType="submit">
            Verify
          </Button>
        </Form.Item>

        {
          <div className="text-center">
            <p>{loading ? "Loading..." : ""}</p>
            <p>
              Didn't receive the code?
              <br />
            </p>
            <a href="#" onClick={resendCode}>
              Resend code
            </a>
            <br />
          </div>
        }
      </Form>
    </ModalWrapper>
  );
}

TwoFactorVerification.propTypes = {
  onVerificationCode: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export { TwoFactorVerification };
