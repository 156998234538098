import { Empty, Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getAdvisorTasks } from "Services/task";

const AdviserTasks = () => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const fetchAdviserTasks = () => {
    setLoading(true);
    getAdvisorTasks({
      body: {
        sort_column: "id",
        sort_order: "desc",
        role_id: 3,
        page_limit: 5
      },
      query: { page: 1 }
    })
      .then(res => {
        setTasks(res.tasks || []);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => fetchAdviserTasks(), []);

  return (
    <div data-cy="mytasks-section" className="white-box">
      <h2 className="page-sub-heading">My Tasks</h2>
      <Skeleton loading={loading}>
        <ul data-cy="mytasks-list" className="appoint-list">
          {Array.isArray(tasks) && tasks.length > 0 ? (
            (tasks || []).map((task, index) => (
              <li key={task.advisor_task_log_id + index}>
                <label className="radio-style">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={task.status > 2}
                  />
                  <i />
                  <h6>
                    <span>
                      {moment(task.completion_date).format(
                        "dddd - DD MMMM YYYY"
                      )}
                    </span>
                  </h6>
                  <p>{task.title || ""}</p>
                </label>
              </li>
            ))
          ) : (
            <Empty description="No tasks found" />
          )}
        </ul>
      </Skeleton>
    </div>
  );
};

export default AdviserTasks;
