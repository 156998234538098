import React, { useState } from "react";
import { Badge, Button, Checkbox, Modal } from "antd";
import { ADVISER, STUDENT } from "../../../../../constants";
import RoleCheck from "../../../../../components/helpers/RoleCheck";

function JoiningConfirmationModal({
  title,
  consentLabel,
  consentDescription,
  description,
  visible,
  handleIsReadyToStartCall
}) {
  const [consentAgreed, setConsentAgreed] = useState(false);

  const onChange = e => {
    setConsentAgreed(e.target.checked);
  };

  const handleStartCall = () => {
    if (!consentAgreed) {
      return;
    }
    if (handleIsReadyToStartCall) {
      handleIsReadyToStartCall();
    } else {
      console.info(
        "'handleIsReadyToStartCall' prop is missing to join the call."
      );
    }
  };

  return (
    <Modal
      visible={visible}
      closeIcon={null}
      closable={false}
      centered
      footer={
        <Button
          className="ml-4"
          type="primary"
          disabled={!consentAgreed}
          onClick={handleStartCall}
        >
          Join
        </Button>
      }
    >
      <div className="p-1">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ height: "25px", width: "25px" }}
            src={require("../../../../../assets/FontAwesome/svgs/regular/info-circle.svg")}
            alt="information"
          />
          <div className="ml-3 text-bold">{title}</div>
        </div>

        <div className={"mt-4"}>
          <RoleCheck authority={[ADVISER]}>
            <p className="text-muted">{consentDescription}</p>
          </RoleCheck>

          <RoleCheck authority={[STUDENT]}>
            <p className="text-muted">
              When our advisers meet with young people virtually (online either
              in a video chat or live chat), it is important that we keep you
              safe, exactly like we would do when you are at school. Therefore
              please make sure that you can confirm the following before you
              join your meeting:
            </p>
            <p className="text-muted">
              <Badge
                color="#6c757d"
                text="You are NOT in your bedroom. You MUST be in an open area of your home."
              />
              <Badge
                color="#6c757d"
                text="You MUST be appropriately dressed as you would be if you were attending a meeting normally."
              />
              <Badge
                color="#6c757d"
                text="You should give your adviser your full attention and not be using your phone or
              other devices (exactly like you would if you were meeting in school)."
              />
              <Badge
                color="#6c757d"
                text="You MUST NOT at any time take screen shots during the meeting. If the adviser
               shares their screen with careers information, they will send you the weblink to 
               use at a later time."
              />
            </p>
          </RoleCheck>

          {consentAgreed && <p>{description}</p>}

          <Checkbox onChange={onChange}>{consentLabel}</Checkbox>
        </div>
      </div>
    </Modal>
  );
}

JoiningConfirmationModal.defaultProps = {
  consentLabel: "I Agree",
  consentDescription: `This call will be automatically recorded and made available
  for viewing by you, the adviser and your school’s administrator.
  Please make sure you are in an appropriate location`
};

export { JoiningConfirmationModal };
