import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withContext } from "Context";
import {
  ADVISER,
  SCHOOL_ADMIN,
  STUDENT,
  SUPER_ADMIN
} from "../../../constants/rolesSubtypes";
import ViewAppointments from "./ViewAppointments/ViewAppointments";
import AppointmentsHistory from "./AppointmentsHistory/AppointmentsHistory";
import "./style.scss";

const routes = [
  {
    path: "/appointments",
    role: [STUDENT, ADVISER, SCHOOL_ADMIN],
    component: ViewAppointments
  },
  {
    path: "/appointments-history",
    role: [STUDENT, ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
    component: AppointmentsHistory
  },
  {
    path: "/appointments-history/:studentId",
    role: [ADVISER, SCHOOL_ADMIN, SUPER_ADMIN],
    component: AppointmentsHistory
  }
];

const Appointments = ({ user }) => (
  <Switch>
    {routes.map(route => {
      if (route.role.includes(user.role_slug)) {
        return (
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        );
      }
    })}
    <Redirect to="/dashboard" />
  </Switch>
);

export default withContext(([{ user }]) => ({ user }), Appointments);
