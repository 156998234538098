import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, notification } from "antd";
import { PageMetaTags } from "Common";
import { registerUser } from "../../../../services/user";
/*
 NOTE:
 we’ll never allow registrations on the site. Only via import therefore this component is never being used.
*/
const RegisterForm = ({ signUpSuccess = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const STYLE_LOGIN_IMAGE = {
    height: "100%",
    width: "100%",
    objectFit: "fill"
  };

  const onFinish = values => {
    setLoading(true);
    registerUser({ body: values })
      .then(res => {
        notification.success({
          message: "Register successful"
        });
        history.push("/login");
      })
      .catch(err => {
        if (err && err.status === 422) {
          notification.error({
            message: Object.keys(err.data)
              .map(key => err.data[key][0])
              .join(" ")
          });
        } else {
          notification.error({
            message: "Failed to register your account."
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <React.Fragment>
      <PageMetaTags title="Register" />
      <section>
        <div className="row">
          <div className="col-12 col-md-5">
            <img
              style={{ ...STYLE_LOGIN_IMAGE }}
              src={require("../../../../assets/images/login-photo.png")}
              alt="login"
            />
          </div>

          <div className="col-12 col-md-7">
            <div className="login-holder">
              <div className="login-inner text-center">
                <figure>
                  <img
                    src={require("../../../../assets/images/careersinc_logo.png")}
                    alt="logo"
                  />
                </figure>
                <h1 className="page-heading">Register</h1>
                <Form onFinish={onFinish} autoComplete="off">
                  <div className="form-group-custom">
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name"
                        }
                      ]}
                    >
                      <input
                        type="text"
                        placeholder="Name"
                        className="input-style"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group-custom">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email"
                        },
                        {
                          type: "email",
                          message: "Please enter a valid email"
                        }
                      ]}
                    >
                      <input
                        type="email"
                        placeholder="Email"
                        className="input-style"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group-custom">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password"
                        }
                      ]}
                    >
                      <input
                        type="password"
                        className="input-style"
                        placeholder="Password"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group-custom">
                    <Form.Item
                      name="password_confirmation"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password"
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          }
                        })
                      ]}
                    >
                      <input
                        type="password"
                        className="input-style"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>
                  </div>

                  <div className="form-footer">
                    <input
                      type="submit"
                      disabled={loading}
                      className="button-style button-style-green-btm button-style-fullwidth-button"
                      value={loading ? "Loading..." : "Sign Up"}
                    />
                    <span className="text-divider">Or</span>
                    <Link
                      to="/login"
                      className="button-style button-style-gray-btm button-style-fullwidth-button"
                    >
                      Login
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default RegisterForm;
