import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withContext } from "Context";
import {
  ADVISER,
  SCHOOL_ADMIN,
  STUDENT
} from "../../../constants/rolesSubtypes";
import ViewLocations from "./ViewLocations";

const routes = [
  {
    path: "/school-locations",
    role: [STUDENT, ADVISER, SCHOOL_ADMIN],
    component: ViewLocations
  }
];

const SchoolLocation = ({ user }) => (
  <Switch>
    {routes.map(route => {
      if (route.role.includes(user.role_slug)) {
        return (
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        );
      }
    })}
    <Redirect to="/dashboard" />
  </Switch>
);

export default withContext(([{ user }]) => ({ user }), SchoolLocation);
