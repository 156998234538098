export const defaults = {
  methods: {
    GET: {
      method: "GET"
    },
    POST: {
      method: "POST"
    },
    PUT: {
      method: "PUT"
    },
    DELETE: {
      method: "DELETE"
    }
  },
  versions: {
    v1: {
      version: "/api"
    }
  }
};
