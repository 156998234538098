import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import Text from "antd/lib/typography/Text";

function TableSummary(props) {
  return (
    <Table.Summary.Row>
      <Table.Summary.Cell>Total</Table.Summary.Cell>
      <Table.Summary.Cell>
        <Text type="success">{props.total}</Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell />
    </Table.Summary.Row>
  );
}

TableSummary.propTypes = {
  total: PropTypes.number
};

TableSummary.defaultProps = {
  total: 0
};

export { TableSummary };
