import { reports } from "../../../common/SideBar/routes";
import React from "react";

const mockResponse = {
  success: true,
  data: {
    rag_before: {
      name: "RAG (BEFORE careers meeting)",
      data: [
        { sno: 1, value: 0, name: "Choices made, likely to have applied." },
        { sno: 2.1, value: 0, name: "Place identified, needs help to apply." },
        { sno: 2.2, value: 0, name: "Place identified, needs guidance." },
        { sno: 2.3, value: 0, name: "Nowhere identified, needs help." },
        { sno: 3, value: 0, name: "Needs urgent appointment." }
      ],
      total: 0
    },
    destinations: {
      name: "Destinations",
      data: [
        { name: "University (Non-Russell Gp)", no: 0, percent: 0 },
        { name: "Russell Group Uni", no: 0, percent: 0 },
        { name: "Apprenticeship", no: 0, percent: 0 },
        { name: "Gap Year", no: 0, percent: 0 },
        { name: "Employment", no: 0, percent: 0 },
        { name: "FE College", no: 0, percent: 0 },
        { name: "Other Sixth Form", no: 0, percent: 0 },
        { name: "Other", no: 0, percent: 0 },
        { name: "NEET", no: 0, percent: 0 }
      ]
    },
    rag_after: {
      name: "RAG (AFTER careers meeting)",
      data: [
        { sno: 1, value: 0, name: "Choices made, likely to have applied." },
        { sno: 2.1, value: 0, name: "Place identified, needs help to apply." },
        { sno: 2.2, value: 0, name: "Place identified, needs guidance." },
        { sno: 2.3, value: 0, name: "Nowhere identified, needs help." },
        { sno: 3, value: 0, name: "Needs urgent appointment." }
      ],
      total: 0
    },
    expected_level: {
      name: "Expected Level of Study",
      data: [
        { name: "3", no: 0, percent: 0 },
        { name: "2", no: 0, percent: 0 },
        { name: "1", no: 0, percent: 0 },
        { name: "Entry", no: 0, percent: 0 }
      ],
      total: 0
    },
    intended_level: {
      name: "Intended Level of Study",
      data: [
        { name: "3", no: 0, percent: 0 },
        { name: "2", no: 0, percent: 0 },
        { name: "1", no: 0, percent: 0 },
        { name: "Entry", no: 0, percent: 0 }
      ],
      total: 0
    },
    sectors: {
      name: "Sectors",
      data: [
        { name: "Animal Care / Land Based Studies", no: 0, percent: 0 },
        { name: "Art, Design & Creative Media", no: 0, percent: 0 },
        { name: "Business & Finance", no: 0, percent: 0 },
        { name: "Construction", no: 0, percent: 0 },
        { name: "Education & Childcare", no: 0, percent: 0 },
        { name: "Engineering", no: 0, percent: 0 },
        { name: "English", no: 0, percent: 0 },
        { name: "Government & Politics", no: 0, percent: 0 },
        { name: "Hair & Beauty", no: 0, percent: 0 },
        { name: "Health & Social (incl nursing)", no: 0, percent: 0 },
        { name: "Hospitality & Catering", no: 0, percent: 0 },
        { name: "Humanities", no: 0, percent: 0 },
        { name: "ICT / Computing", no: 0, percent: 0 },
        { name: "Languages", no: 0, percent: 0 },
        { name: "Law", no: 0, percent: 0 },
        { name: "Maths", no: 0, percent: 0 },
        { name: "Medicine & related", no: 0, percent: 0 },
        { name: "Performing Arts", no: 0, percent: 0 },
        { name: "Public Services / Armed Forces", no: 0, percent: 0 },
        { name: "Sport & Leisure", no: 0, percent: 0 },
        { name: "Science", no: 0, percent: 0 },
        { name: "Social Science", no: 0, percent: 0 },
        { name: "Other", no: 0, percent: 0 }
      ]
    }
  }
};

const linkReportToStudent = (value, record) => {
  const queryString = new URLSearchParams(window.location.search);
  const yeargroup = queryString.get("year_group");
  if (window.location.pathname === "/reports/y10-dashboard") {
    if (
      record.filter_column_key === "rag_before" ||
      record.filter_column_key === "rag_after"
    ) {
      return (
        <a
          className={"cursor-pointer"}
          href={`/students?year_group_filter=10&data_filter_column=${record.filter_key}&rag_data=${record.sno}`}
        >
          {value}
        </a>
      );
    } else {
      return (
        <a
          className={"cursor-pointer"}
          href={`/students?year_group_filter=10&data_filter_column=${record.filter_key}&filter_column_key=${record.filter_column_key}&rag_data=${record.name}`}
        >
          {value}
        </a>
      );
    }
  } else {
    if (
      record.filter_column_key === "rag_before" ||
      record.filter_column_key === "rag_after"
    ) {
      if (
        typeof record.report_filter_field !== "undefined" &&
        record.report_filter_field
      ) {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&rag_data=${record.sno}&report_filter_field=${record.report_filter_field}`}
          >
            {value}
          </a>
        );
      } else {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&rag_data=${record.sno}`}
          >
            {value}
          </a>
        );
      }
    } else {
      if (
        typeof record.report_filter_field !== "undefined" &&
        record.report_filter_field
      ) {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&filter_column_key=${record.filter_column_key}&rag_data=${record.name}&report_filter_field=${record.report_filter_field}`}
          >
            {value}
          </a>
        );
      } else {
        return (
          <a
            className={"cursor-pointer"}
            href={`/students?year_group_filter=${yeargroup}&data_filter_column=${record.filter_key}&filter_column_key=${record.filter_column_key}&rag_data=${record.name}`}
          >
            {value}
          </a>
        );
      }
    }
  }
};

const rag_cols = [
  { dataIndex: "sno" },
  { dataIndex: "value", render: linkReportToStudent },
  { dataIndex: "name" }
];

const cols = [
  { dataIndex: "name" },
  { dataIndex: "no", render: linkReportToStudent },
  { dataIndex: "percent", render: value => `${value} %` }
];

const getPageTitle = () => {
  return reports.find(report => report.path === window.location.pathname);
};

export { mockResponse, rag_cols, cols, getPageTitle };
