import React, { useEffect, useState } from "react";
import { Skeleton, Table, Divider } from "antd";
import { cols, getPageTitle, mockResponse, rag_cols } from "../Shared/common";
import { PageMetaTags } from "../../../common/PageMetaTags";
import { TableSummary } from "../Shared/TableSummary";
import { useHistory } from "react-router";
import { getPre16Reports } from "../../../../services/report";
import { PieChart } from "../../Resources/StarExercise/AntDesignPlotCharts";

function Y10Dashboard({ user }) {
  const [payload, setPayload] = useState({ ...mockResponse.data });
  const [response, setResponse] = useState();
  const [piedemoRagLive, setPiedemoRagLive] = useState();
  const [piedemoRagBefore, setPiedemoRagBefore] = useState();
  const [pieRagBeforeColor, setRagBeforeColor] = useState();
  const [pieRagAfterColor, setRagAfterColor] = useState();
  const [labelRagLive, setlabelRagLive] = useState();
  const [labelRagbefore, setlabelRagbefore] = useState();
  const [pageHeading, setPageHeading] = useState("");
  const [getLoading, setGetLoading] = useState(false);
  const [getTotal, setTotal] = useState();
  const history = useHistory();
  const urlQuery = new URLSearchParams(history.location.search);
  const yearsGroup = useState(urlQuery.get("year_group"))[0];

  const getReportDetails = query => {
    setGetLoading(true);
    getPre16Reports({
      pathParams: { path: history.location.pathname },
      query: query || {}
    })
      .then(res => {
        setResponse(res);
        setPayload(res.data);
        setTotal(res.total_student_count);

        if (res.data.rag_after.data) {
          var newrAfterList = [];
          var newrAfterColors = [];
          var colors = ["#00b050", "#ED9A76", "#ffbc6a", "#D79334", "#ff0000"];
          for (var i = 0; i < res.data.rag_after.data.length; i++) {
            if (res.data.rag_after.data[i].sno) {
              if (res.data.rag_after.data[i].value !== 0) {
                res.data.rag_after.data[i].sno =
                  "RAG " + res.data.rag_after.data[i].sno;
                newrAfterColors.push(colors[i]);
                newrAfterList.push(res.data.rag_after.data[i]);
              } else {
                res.data.rag_after.data[i].sno =
                  "RAG " + res.data.rag_after.data[i].sno;
              }
            }
          }
          newrAfterColors.push("#833c0b");
          newrAfterList.push({
            sno: "No data",
            value: res.total_student_count - res.data.rag_after.total,
            name: "",
            filter_key: res.data.rag_after.data[0].filter_key,
            filter_column_key: "rag_after"
          });
          setPiedemoRagLive(newrAfterList);
          setlabelRagLive("Live RAG");
          setRagAfterColor(newrAfterColors);
          //res.data.rag_after.data.push({sno: '(No data)', value: NullTotalAfter , name: '', filter_key: 'informationNotesColumn'});
        }
        if (res.data.rag_before.data) {
          var newrBeforeList = [];
          var newrBeforeColors = [];
          for (var j = 0; j < res.data.rag_before.data.length; j++) {
            if (res.data.rag_before.data[j].sno) {
              if (res.data.rag_before.data[j].value !== 0) {
                res.data.rag_before.data[j].sno =
                  "RAG " + res.data.rag_before.data[j].sno;
                newrBeforeList.push(res.data.rag_before.data[j]);
                newrBeforeColors.push(colors[j]);
              } else {
                res.data.rag_before.data[j].sno =
                  "RAG " + res.data.rag_before.data[j].sno;
              }
            }
          }
          newrBeforeColors.push("#833c0b");
          newrBeforeList.push({
            sno: "No data",
            value: res.total_student_count - res.data.rag_before.total,
            name: "",
            filter_key: res.data.rag_before.data[0].filter_key,
            filter_column_key: "rag_before"
          });
          setPiedemoRagBefore(newrBeforeList);
          setRagBeforeColor(newrBeforeColors);
          setlabelRagbefore("KS4 Questionnaire RAG");
          //res.data.rag_before.data.push({sno: 'No data', value: NullTotalbefore , name: '', filter_key: 'ks4QuestionnaireColumn'});
        }
        if (
          typeof res.data.rag_after !== "undefined" &&
          res.data.rag_after.data
        ) {
          res.data.rag_after.data.push({
            sno: "No data",
            value: res.total_student_count - res.data.rag_after.total,
            name: "",
            filter_key: res.data.rag_after.data[0].filter_key,
            filter_column_key: "rag_after"
          });
        }
        if (
          typeof res.data.rag_before !== "undefined" &&
          res.data.rag_before.data
        ) {
          res.data.rag_before.data.push({
            sno: "No data",
            value: res.total_student_count - res.data.rag_before.total,
            name: "",
            filter_key: res.data.rag_before.data[0].filter_key,
            filter_column_key: "rag_before"
          });
        }
        if (
          typeof res.data.destinations !== "undefined" &&
          res.data.destinations.data
        ) {
          if (res.total_student_count - res.data.destinations.total === 0) {
            res.data.destinations.data.push({
              name: "No data",
              no: res.total_student_count - res.data.destinations.total,
              percent: 0,
              filter_key: res.data.destinations.data[0].filter_key,
              filter_column_key: "destinations"
            });
          } else {
            res.data.destinations.data.push({
              name: "No data",
              no: res.total_student_count - res.data.destinations.total,
              percent: Math.round(
                ((res.total_student_count - res.data.destinations.total) /
                  res.total_student_count) *
                  100
              ),
              filter_key: res.data.destinations.data[0].filter_key,
              filter_column_key: "destinations"
            });
          }
        }
        if (
          typeof res.data.expected_level !== "undefined" &&
          res.data.expected_level.data
        ) {
          if (res.total_student_count - res.data.expected_level.total === 0) {
            res.data.expected_level.data.push({
              name: "No data",
              no: res.total_student_count - res.data.expected_level.total,
              percent: 0,
              filter_key: res.data.expected_level.data[0].filter_key,
              filter_column_key: "expected_level"
            });
          } else {
            res.data.expected_level.data.push({
              name: "No data",
              no: res.total_student_count - res.data.expected_level.total,
              percent: Math.round(
                ((res.total_student_count - res.data.expected_level.total) /
                  res.total_student_count) *
                  100
              ),
              filter_key: res.data.expected_level.data[0].filter_key,
              filter_column_key: "expected_level"
            });
          }
        }
        if (
          typeof res.data.intended_level !== "undefined" &&
          res.data.intended_level.data
        ) {
          if (res.total_student_count - res.data.intended_level.total === 0) {
            res.data.intended_level.data.push({
              name: "No data",
              no: res.total_student_count - res.data.intended_level.total,
              percent: 0,
              filter_key: res.data.intended_level.data[0].filter_key,
              filter_column_key: "intended_level"
            });
          } else {
            res.data.intended_level.data.push({
              name: "No data",
              no: res.total_student_count - res.data.intended_level.total,
              percent: Math.round(
                ((res.total_student_count - res.data.intended_level.total) /
                  res.total_student_count) *
                  100
              ),
              filter_key: res.data.intended_level.data[0].filter_key,
              filter_column_key: "intended_level"
            });
          }
        }
        if (typeof res.data.sectors !== "undefined" && res.data.sectors.data) {
          if (res.total_student_count - res.data.sectors.total === 0) {
            res.data.sectors.data.push({
              name: "No data",
              no: res.total_student_count - res.data.sectors.total,
              percent: 0,
              filter_key: res.data.sectors.data[0].filter_key,
              filter_column_key: "sectors"
            });
          } else {
            res.data.sectors.data.push({
              name: "No data",
              no: res.total_student_count - res.data.sectors.total,
              percent: Math.round(
                ((res.total_student_count - res.data.sectors.total) /
                  res.total_student_count) *
                  100
              ),
              filter_key: res.data.sectors.data[0].filter_key,
              filter_column_key: "sectors"
            });
          }
        }
      })
      .catch(error => console.log(error))
      .finally(() => {
        setGetLoading(false);
      });
  };

  useEffect(() => {
    if (user && user.school_id && yearsGroup) {
      getReportDetails({
        school_id: user.school_id,
        year_group: yearsGroup
      });
    } else if (user && user.school_id) {
      getReportDetails({
        school_id: user.school_id
      });
    } else if (yearsGroup) {
      getReportDetails({
        year_group: yearsGroup
      });
    } else {
      getReportDetails();
    }
  }, [history.location.pathname]);

  useEffect(() => {
    setPageHeading(getPageTitle().name || "Report");
  }, []);

  const renderReportHeading = (length = 0) => {
    return (
      <div className={"row justify-content-center align-content-center"}>
        <h2 className="page-sub-heading">
          Number of students in year group: {length}
        </h2>
      </div>
    );
  };

  return (
    <div className="content-panel">
      <PageMetaTags title={pageHeading} />
      <h1 className="page-heading">{pageHeading}</h1>
      <Skeleton loading={getLoading}>
        <div className="profile-wrapper">
          <div className="container">
            {renderReportHeading(response && response.total_student_count)}
            <br />
            <div className="row">
              {labelRagLive ? (
                <div className="col-12 col-lg-6 mt-4">
                  <PieChart
                    chartData={piedemoRagLive}
                    colors={pieRagAfterColor}
                    chartLabel={labelRagLive}
                  />
                </div>
              ) : (
                <></>
              )}
              {labelRagbefore ? (
                <div className="col-12 col-lg-6 mt-4">
                  <PieChart
                    chartData={piedemoRagBefore}
                    colors={pieRagBeforeColor}
                    chartLabel={labelRagbefore}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* Row 1 */}
            <div className="row">
              <div className="col-12 col-lg-6">
                <label className="labelstyle">
                  {payload.rag_after.name || "N/A"}
                </label>
                <Table
                  size={"small"}
                  bordered={true}
                  showHeader={false}
                  pagination={false}
                  columns={rag_cols}
                  rowKey={record => record.name}
                  dataSource={payload.rag_after.data}
                  summary={() => <TableSummary total={getTotal || 0} />}
                />
                {payload.rag_after && (
                  <Divider style={{ background: "#c0c0c0" }} />
                )}
              </div>
              <div className="col-12 col-lg-6">
                <label className="labelstyle">
                  {payload.rag_before.name || "N/A"}
                </label>
                <Table
                  size={"small"}
                  bordered={true}
                  showHeader={false}
                  pagination={false}
                  columns={rag_cols}
                  rowKey={record => record.name}
                  dataSource={payload.rag_before.data}
                  summary={() => <TableSummary total={getTotal || 0} />}
                />
                {payload.rag_before && (
                  <Divider style={{ background: "#c0c0c0" }} />
                )}
              </div>
            </div>
            <br />
            <br />
            {/*  Row 2*/}
            <div className="row">
              <div className="col-12 col-lg-6">
                <label className="labelstyle">
                  {payload.destinations.name || "N/A"}
                </label>
                <Table
                  size={"small"}
                  bordered={true}
                  showHeader={false}
                  pagination={false}
                  columns={cols}
                  dataSource={payload.destinations.data}
                  rowKey={record => record.name}
                  summary={() => <TableSummary total={getTotal || 0} />}
                />
              </div>
              <div className="col-12 col-lg-6">
                <label className="labelstyle">
                  {payload.sectors.name || "N/A"}
                </label>
                <Table
                  size={"small"}
                  bordered={true}
                  showHeader={false}
                  pagination={false}
                  columns={cols}
                  dataSource={payload.sectors.data}
                  rowKey={record => record.name}
                  summary={() => <TableSummary total={getTotal || 0} />}
                />
              </div>
            </div>
          </div>
        </div>
      </Skeleton>
    </div>
  );
}

export { Y10Dashboard };
