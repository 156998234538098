import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  notification,
  Radio,
  Skeleton,
  Space,
  Select
} from "antd";
import { Option } from "antd/lib/mentions";
import { PageMetaTags } from "Common";
import { getPost16Question, savePost16Question } from "Services/destination";
import { useHistory } from "react-router";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper/index.jsx";
import { STUDENT } from "../../../../constants/rolesSubtypes.js";

const items = [
  { id: "University", name: "University" },
  { id: "Apprenticeship", name: "Apprenticeship" },
  { id: "Gap Year", name: "Gap Year" },
  { id: "Employment", name: "Employment" },
  { id: "FE College", name: "FE College" },
  { id: "Other", name: "Other" },
  { id: "Don’t Know", name: "I don’t know and need some help" }
];

const interest = [
  { label: "Interested", name: "Interested" },
  { label: "Not Sure", name: "Not Sure" },
  { label: "Not at all interested", name: "Not at all interested" }
];

const sectors = [
  {
    id: "Animal Care / Land Based Studies",
    name: "Animal Care / Land Based Studies"
  },
  {
    id: "Art, Design & Creative Media",
    name: "Art, Design & Creative Media"
  },
  { id: "Business & Finance", name: "Business & Finance" },
  { id: "Construction", name: "Construction" },
  { id: "Education & Childcare", name: "Education & Childcare" },
  { id: "Engineering", name: "Engineering" },
  { id: "English", name: "English" },
  { id: "Government & Politics", name: "Government & Politics" },
  { id: "Hair & Beauty", name: "Hair & Beauty" },
  {
    id: "Health & Social (incl nursing)",
    name: "Health & Social (incl nursing)"
  },
  { id: "Hospitality & Catering", name: "Hospitality & Catering" },
  { id: "Humanities", name: "Humanities" },
  { id: "ICT / Computing", name: "ICT / Computing" },
  { id: "Languages", name: "Languages" },
  { id: "Law", name: "Law" },
  { id: "Maths", name: "Maths" },
  { id: "Medicine & related", name: "Medicine & related" },
  { id: "Performing Arts", name: "Performing Arts" },
  {
    id: "Public Services / Armed Forces",
    name: "Public Services / Armed Forces"
  },
  { id: "Sport & Leisure", name: "Sport & Leisure" },
  { id: "Science", name: "Science" },
  { id: "Social Science", name: "Social Science" },
  { id: "Other", name: "Other" },
  {
    id: "I don’t know and need some help",
    name: "I don’t know and need some help"
  }
];

const Post16Quest = ({ user }) => {
  const pageHeading = "Post 16 Questionnaire";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [getLoading, setGetLoading] = useState(false);
  const [formData, setFormData] = useState();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group

  const getDetails = () => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
    }
    getPost16Question({ query: payload })
      .then(res => {
        if (res) {
          setFormData(res.data || undefined);
          setEditable(res.data && res.data.form_visible === 0 ? false : true);
          const values = Object.keys(res.data).reduce(
            (prev, k) => ({ ...prev, [k]: res.data[k].value }),
            {}
          );
          setGetLoading(false);
          form.setFieldsValue({ ...values });
        }
      })
      .catch(() => setGetLoading(false));
  };

  useEffect(() => getDetails(), [query.get("id")]);

  const onFinish = values => {
    setLoading(true);
    if (query.get("id")) {
      values.student_id = query.get("id");
    }
    savePost16Question({ body: values })
      .then(res => {
        setLoading(false);
        getDetails();
        notification.success({
          message: "Saved the Post 16 Questionnaire"
        });
      })
      .catch(err => {
        setLoading(false);
        notification.error({
          message: "Failed to save Post 16 Questionnaire"
        });
      })
      .finally(() => setLoading(false));
  };

  const getLabel = (array, key) => {
    if (array && key) {
      const index = array.findIndex(a => a.id === key);
      return (array[index] && array[index].name && array[index].name) || key;
    }
    return "";
  };

  return (
    <div className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}
      <div className="profile-wrapper" style={{ paddingTop: "30px" }}>
        <Skeleton loading={getLoading}>
          <Form form={form} onFinish={onFinish}>
            <div className="row">
              {formData && formData["other_information"].visibile && (
                <div className="col-12 col-md-12">
                  <div className="form-group pb-2">
                    <label
                      className="labelstyle"
                      style={{ textAlign: "justify" }}
                    >
                      MOST STUDENTS STAY ON AT SIXTH FORM INTO YEAR 13 AND YOU
                      CAN COMPLETE THE SECTIONS BELOW. HOWEVER, SOME STUDENTS
                      MAY CHOOSE TO LEAVE AT THE END OF YEAR 12 AND IF YOU ARE
                      CONSIDERING THIS, PLEASE TRY TO EXPLAIN WHY IN THE BOX
                      BELOW
                    </label>
                    <Form.Item name="other_information">
                      {editable ? (
                        <Input.TextArea
                          disabled={
                            formData && formData["other_information"]
                              ? !formData["other_information"].editable
                              : false
                          }
                        />
                      ) : (
                        <div className="label-desc">
                          {formData && formData["other_information"].value}
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}

              {formData && formData["he_intention"].visibile && (
                <div className="col-12 col-md-12">
                  <div className="form-group pb-2">
                    <label className="labelstyle">
                      HOW INTERESTED ARE YOU IN GOING TO UNIVERSITY AFTER SIXTH
                      FORM?
                    </label>
                    <Form.Item name="he_intention">
                      {editable ? (
                        <Select
                          showSearch
                          className={"w-100"}
                          allowClear={true}
                          style={{ width: 200 }}
                          optionFilterProp="children"
                          disabled={!formData["he_intention"].editable}
                          defaultValue={formData["he_intention"].value}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          <Option disabled>Select</Option>
                          {interest &&
                            interest.map(option => (
                              <Option value={option.name}>
                                {option.label}
                              </Option>
                            ))}
                        </Select>
                      ) : (
                        <div className="label-desc">
                          <Select
                            showSearch
                            className={"w-100"}
                            allowClear={true}
                            style={{ width: 200 }}
                            optionFilterProp="children"
                            disabled={!formData["he_intention"].editable}
                            defaultValue={formData["he_intention"].value}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option disabled>Select</Option>
                            {interest &&
                              interest.map(option => (
                                <Option value={option.name}>
                                  {option.label}
                                </Option>
                              ))}
                          </Select>
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}

              {formData && formData["intended_destination"].visibile && (
                <div className="col-12 col-md-12">
                  <div className="form-group pb-2">
                    <label className="labelstyle">
                      If staying on at the Sixth Form, which of the options
                      below do you think you are most likely to do at the end of
                      Year 13?
                    </label>
                    <Form.Item name="intended_destination">
                      {editable ? (
                        <Radio.Group
                          disabled={
                            formData && formData["intended_destination"]
                              ? !formData["intended_destination"].editable
                              : false
                          }
                        >
                          <Space direction="vertical">
                            {items.map(({ id, name }) => (
                              <Radio value={id}>{name}</Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      ) : (
                        <div className="label-desc">
                          {getLabel(
                            items,
                            formData && formData["intended_destination"].value
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}

              {/* <div className="form-group pb-2">
              <label className="labelstyle">
                Even if it is not your first choice, how interested are you /
                how likely are you to consider going to university?
              </label>
              <Form.Item name="staying_sixth_form">
                <Radio.Group>
                  <Space direction="vertical">
                    {interest.map((i) => (
                      <Radio value={i.id}>{i.name}</Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div> */}
              {formData && formData["career_ideas"].visibile && (
                <div className="col-12 col-md-12">
                  <div className="form-group pb-2">
                    <label className="labelstyle">
                      Do you have any specific career ideas?
                    </label>
                    <Form.Item name="career_ideas">
                      {editable ? (
                        <Input.TextArea
                          disabled={
                            formData && formData["career_ideas"]
                              ? !formData["career_ideas"].editable
                              : false
                          }
                        />
                      ) : (
                        <div className="label-desc">
                          {formData && formData["career_ideas"].value}
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}

              {formData && formData["rag_for_priority"].visibile && (
                <div className="col-12 col-md-12">
                  <div className="form-group pb-2">
                    <label className="labelstyle">RAG for Priority</label>
                    <Form.Item name="rag_for_priority">
                      {editable ? (
                        <Input.TextArea
                          disabled={
                            formData && formData["rag_for_priority"]
                              ? !formData["rag_for_priority"].editable
                              : false
                          }
                        />
                      ) : (
                        <div className="label-desc">
                          {formData && formData["rag_for_priority"].value}
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}

              {formData && formData["sector"].visibile && (
                <div className="col-12 col-md-12">
                  <div className="form-group pb-2">
                    <label className="labelstyle">
                      Which of the following career areas / sectors are you
                      mainly interested in?
                    </label>
                    <Form.Item name="sector">
                      {editable ? (
                        <Radio.Group
                          disabled={
                            formData && formData["sector"]
                              ? !formData["sector"].editable
                              : false
                          }
                        >
                          <Space direction="vertical">
                            {sectors.map(({ id, name }) => (
                              <Radio value={id}>{name}</Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      ) : (
                        <div className="label-desc">
                          {getLabel(
                            sectors,
                            formData && formData["sector"].value
                          )}
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
            {editable && (
              <div className="form-group d-flex justify-content-end">
                <input
                  type="submit"
                  disabled={loading}
                  className="button-style button-style-green-btm button-style-normal-button"
                  value={loading ? "Loading..." : "Update"}
                />
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
};

export default Post16Quest;
