import { GCSE_OPTIONS, INTERESTS, SECTORS, TYPE } from "../common";

const formItems = {
  form_visible: 1, // Default check to make it editable as fields rather label
  intended_destination: {
    name: "intended_destination",
    label:
      "Which of these options do you think you are most likely to do after your GCSE’s?",
    options: GCSE_OPTIONS,
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.radio
  },
  sector: {
    name: "sector",
    label:
      "Which of the following career areas / sectors are you mainly interested in?",
    options: SECTORS,
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.radio
  },
  he_intention: {
    name: "he_intention",
    label:
      "How interested are you in going to university/college and getting a degree?",
    options: INTERESTS,
    editable: true,
    visibile: true,
    value: null,
    type: TYPE.radio
  },
  info_question_for_adviser: {
    name: "info_question_for_adviser",
    label: "Other Information",
    placeholder: "Other Information",
    editable: true,
    value: null,
    type: TYPE.input
  },
  rag_for_priority: {
    name: "rag_for_priority",
    label: "RAG for Priority",
    editable: true,
    visibile: true,
    value: null,
    placeholder: "Select RAG for Priority",
    options: [
      {
        label: "Positive destination confirmed",
        value: "1"
      },
      {
        label: "Positive destination identified",
        value: "2.1"
      },
      {
        label: "Destination(s) identified",
        value: "2.2"
      },
      {
        label: "No destination(s) identified",
        value: "2.3"
      },
      {
        label: "Potential NEET",
        value: "3"
      }
    ],
    type: TYPE.select
  }
};

export { formItems, TYPE };
