import React from "react";
import TimeLogForm from "./TimeLogForm";
import TimeLogList from "./TimeLogList";
import { Redirect, Route, Switch } from "react-router-dom";

import "./style.scss";

const TimeLog = () => {
  return (
    <Switch>
      <Route exact path="/timelog" component={TimeLogForm} />
      <Route exact path="/timelog/list" component={TimeLogList} />
      <Redirect to="/timelog" />
    </Switch>
  );
};

export default TimeLog;
