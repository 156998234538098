import React from "react";
import { Form, Radio } from "antd";
import PropTypes from "prop-types";
import { PageMetaTags } from "../../../../common/PageMetaTags";

const Questionnaire = ({
  title,
  category,
  options,
  name,
  setFormFields,
  value
}) => {
  const onChange = event => {
    setFormFields(name, event.target.value);
  };

  return (
    <React.Fragment>
      <PageMetaTags title="Resources - Star Exercise" />
      <div className="row bg-white ml-2 mr-2">
        <h5>
          {category} -> {title}
        </h5>
        <Form.Item name={name} className={"poll-question"}>
          <Radio.Group
            onChange={onChange}
            value={value ? value : null}
            rules={[{ required: true, message: "Please choose an option!" }]}
          >
            {options &&
              options.map((option, index) => {
                return (
                  <Radio key={index} value={option.score}>
                    {option.question}
                  </Radio>
                );
              })}
          </Radio.Group>
        </Form.Item>
      </div>
    </React.Fragment>
  );
};

Questionnaire.propTypes = {
  question: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string.isRequired // Form.Item name
};

export { Questionnaire };
