import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import PropTypes from "prop-types";
import { dataSets as DATASETS } from "../StudentsList/dt-table-config";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getYearsList } from "../../../../services/common";

const DTFilter = props => {
  const DELAY_TIME = 1000;
  const FILTER_ENUM = {
    school: "school",
    search: "search",
    email: "email"
  };
  const [schoolName, setSchoolName] = useState();
  const [studentName, setStudentName] = useState();
  const [email, setEmail] = useState();
  const [dataSets] = useState(DATASETS);
  const [yearsData, setYears] = useState([]);
  const debouncedSchoolTerm = useDebounce(schoolName, DELAY_TIME);
  const debouncedStudentTerm = useDebounce(studentName, DELAY_TIME);
  const debouncedEmailTerm = useDebounce(email, DELAY_TIME);
  const [form] = Form.useForm();

  useEffect(() => {
    getYearsList()
      .then(response => {
        setYears(response.data);
      })
      .catch(error => console.log("DTFilter.getYears", error));
  }, []);

  useEffect(
    () => {
      let queryParams = {};
      if (schoolName) {
        queryParams = {
          ...queryParams,
          [schoolName.name]: schoolName.value
        };
      }
      if (studentName) {
        queryParams = {
          ...queryParams,
          [studentName.name]: studentName.value
        };
      }
      if (email) {
        queryParams = {
          ...queryParams,
          [email.name]: email.value
        };
      }

      props.onFilterValueChange(queryParams);
    },
    [debouncedSchoolTerm, debouncedStudentTerm, debouncedEmailTerm] // Only call effect if debounced value changes
  );

  useEffect(() => {
    if (props.filterData && Object.keys(props.filterData).length > 0) {
      form.setFieldsValue({
        ...props.filterData,
        yeargroup: props.yearGroupDefaultValue,
        datasets: props.datasetDefaultValue
      });
    }
  }, [props.filterData]);

  const handleKeyDown = event => {
    let name = getValueFromEvent("name", event);

    if (name === FILTER_ENUM.school) {
      setSchoolName({ name, value: event.target.value });
    } else if (name === FILTER_ENUM.search) {
      setStudentName({ name, value: event.target.value });
    } else if (name === FILTER_ENUM.email) {
      setEmail({ name, value: event.target.value });
    }
  };

  const handleSelectedOptions = value => {
    props.onDataSetsSelect(value);
  };

  const handleSelectedGroup = value => {
    props.onYearGroupSelect(value);
  };

  const getValueFromEvent = (attribute, event) => {
    return event.currentTarget.attributes.getNamedItem(attribute).value;
  };

  const clearFilters = () => {
    const clearData = {
      school: null,
      search: null,
      email: null,
      datasets: ["informationNotesColumn"]
    };
    form.setFieldsValue(clearData);
    props.clearFilters();
    handleSelectedOptions(clearData.datasets);
    handleSelectedGroup(clearData.yeargroup);
  };

  return (
    <div className="search-area student-list">
      <Form form={form} name="filterForm" onFinish={clearFilters}>
        <div className="row">
          <div className="col-12 col-md-2">
            <div className="form-group-custom mb-4 ">
              <label className="labelstyle">Student Name</label>

              <Form.Item name="search">
                <Input
                  name="search"
                  type="text"
                  className="input-style"
                  placeholder="e.g Alexa"
                  onChange={handleKeyDown}
                />
              </Form.Item>
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group-custom mb-4 ">
              <label className="labelstyle">YEAR GROUP</label>

              <Form.Item name="yeargroup">
                <Select
                  name="yeargroup"
                  className="select-style"
                  placeholder="Select year group"
                  onChange={handleSelectedGroup}
                >
                  {yearsData.map((yearsData, index) => (
                    <Select.Option key={index} value={yearsData.id}>
                      {yearsData.id}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="col-12 col-md-5">
            <div className="form-group-custom mb-4 ">
              <label className="labelstyle">Datasets</label>
              <Form.Item name="datasets">
                <Select
                  name="datasets"
                  mode="multiple"
                  className="select-style"
                  placeholder="Select datasets"
                  onChange={handleSelectedOptions}
                >
                  {Object.keys(dataSets).map((key, index) => (
                    <Select.Option key={index} value={dataSets[key].column}>
                      {dataSets[key].title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className="col-12 col-md-3">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group-custom mb-4 ">
                  <label className="labelstyle">&nbsp;</label>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Clear filters
                    </Button>
                  </Form.Item>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group-custom mb-4 ">
                  <label className="labelstyle">&nbsp;</label>
                  <Form.Item>
                    <a href="/students">
                      <Button type="primary" htmlType="button">
                        Clear report filter
                      </Button>
                    </a>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

DTFilter.propTypes = {
  clearFilters: PropTypes.func,
  filterData: PropTypes.any,
  datasetDefaultValue: PropTypes.array.isRequired,
  onDataSetsSelect: PropTypes.func.isRequired,
  onYearGroupSelect: PropTypes.func.isRequired,
  yearGroupDefaultValue: PropTypes.array.isRequired,
  onFilterValueChange: PropTypes.func.isRequired
};

export { DTFilter };
