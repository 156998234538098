import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Form,
  Input,
  notification,
  Radio,
  Select,
  Skeleton,
  Space
} from "antd";
import { Option } from "antd/lib/mentions";
import { PageMetaTags } from "Common";
import { formItems, TYPE } from "./formItemSchema";
import {
  getKS4Questionnaire,
  saveKS4Questionnaire
} from "../../../../services/destination";
import { deepMerge, isAnyFieldEditable } from "../common";
import {
  renderDestinationTrackerBreadcrumbs,
  renderStudentMetaData
} from "../common.jsx";
import { STUDENT } from "../../../../constants";
import HeaderWrapper from "../../../common/ProLayout/HeaderWrapper";

function KS4Questionnaire({ user }) {
  const pageHeading =
    user && user.role_slug === STUDENT
      ? "Career Questionnaire"
      : "KS4 Questionnaire";
  const [ks4QuestionnaireData, setKs4QuestionnaireData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [form] = Form.useForm();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const studentId = useState(query.get("id"))[0]; // Note: Since ID & Year Group

  useEffect(() => {
    setGetLoading(true);
    let payload = {};
    if (query.get("id")) {
      payload.student_id = query.get("id");
    }
    getKS4Questionnaire({ query: payload })
      .then(response => {
        if (response.success && response.status_code === 200) {
          const isFormEditable = isAnyFieldEditable(response.data);
          if (
            !isFormEditable ||
            !(response.data && response.data["form_visible"])
          ) {
            setEditable(false);
          }
          const newData = deepMerge(formItems, response.data);
          setKs4QuestionnaireData(() => {
            setKs4QuestionnaireData(newData);
          });
        } else {
          setKs4QuestionnaireData(() => {
            setKs4QuestionnaireData(formItems);
          });
        }
      })
      .catch(error => {
        console.log(error);
        setKs4QuestionnaireData(() => {
          setKs4QuestionnaireData(formItems);
        });
      })
      .finally(() => setGetLoading(false));
  }, [query.get("id")]);

  const onFinish = formValues => {
    setLoading(true);
    if (query.get("id")) {
      // Note: `student_id` is required when GET/PUT from `adviser`
      formValues.student_id = query.get("id");
    }
    saveKS4Questionnaire({ body: formValues })
      .then(res => {
        notification.success({ message: `Saved the ${pageHeading}` });
      })
      .catch(error => {
        console.log(error);
        notification.error({
          message: `Failed to save ${pageHeading}`
        });
      })
      .finally(() => setLoading(false));
  };

  const renderInputElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !ks4QuestionnaireData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="form-group pb-2">
        <label className="labelstyle">{item.label}</label>
        <Form.Item name={item.name}>
          <Input
            defaultValue={item.value}
            disabled={!item.editable}
            placeholder={item.placeholder}
          />
        </Form.Item>
      </div>
    );
  };

  const renderAsLabel = item => {
    return (
      <div className="col-12 col-md-6">
        <div className="form-group pb-2">
          <label className="labelstyle">{item.label}</label>
          <Form.Item name={item.name}>
            <Input
              defaultValue={item.value}
              readonly="true"
              placeholder={item.placeholder}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderRadioElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !ks4QuestionnaireData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="form-group pb-2">
        <label className="labelstyle">{item.label}</label>
        <Form.Item name={item.name}>
          <Radio.Group defaultValue={item.value}>
            <Space direction="vertical">
              {item.options.map(option => (
                <Radio value={option.value}>{option.label}</Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>
    );
  };

  const renderSelectElement = item => {
    if (!item) {
      return;
    }
    if (!item.editable || !ks4QuestionnaireData["form_visible"]) {
      return renderAsLabel(item);
    }
    return (
      <div className="form-group pb-4">
        <label className="labelstyle">{item.label}</label>
        <Form.Item name={item.name}>
          <Select
            showSearch
            className={"w-100"}
            allowClear={true}
            style={{ width: 200 }}
            placeholder={item.placeholder}
            optionFilterProp="children"
            disabled={!item.editable}
            defaultValue={item.value}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option disabled>Select</Option>
            {item.options &&
              item.options.map(option => (
                <Option value={option.value}>{option.label}</Option>
              ))}
          </Select>
        </Form.Item>
      </div>
    );
  };

  const renderSubHeadingText = () => {
    return (
      <div className="mb-2 ml-2 w-75">
        <span style={{ color: "#4675d6" }}>
          (i) Read me before filling out the questionnaire!
        </span>
        <p>
          You only have one chance to submit your answers to this questionnaire.
          This questionnaire is to help you think about some of the options
          which are available to you. Don’t worry if you don’t have all the
          answers yet. You will be able to talk about it with your adviser
          during your careers meeting. If you are not sure or have any questions
          then please talk to your careers adviser before filling it out.
        </p>
      </div>
    );
  };

  return (
    <div className="content-panel">
      {query.get("id") && renderStudentMetaData(query.get("id"))}
      {studentId && renderDestinationTrackerBreadcrumbs(studentId)}

      {user.role_slug === STUDENT ? (
        <HeaderWrapper title={pageHeading} />
      ) : (
        <>
          <PageMetaTags title={pageHeading} />
          <h1 className="page-heading">{pageHeading}</h1>
        </>
      )}
      {user && user.role_slug === STUDENT && renderSubHeadingText()}
      <div className="profile-wrapper ks4-questionnaire">
        <Skeleton loading={getLoading}>
          <Form form={form} onFinish={onFinish}>
            {ks4QuestionnaireData &&
              Object.keys(formItems).map(key => {
                if (
                  ks4QuestionnaireData[key].type === TYPE.input &&
                  ks4QuestionnaireData[key].visibile
                ) {
                  return renderInputElement(ks4QuestionnaireData[key]);
                } else if (
                  ks4QuestionnaireData[key].type === TYPE.radio &&
                  ks4QuestionnaireData[key].visibile
                ) {
                  return renderRadioElement(ks4QuestionnaireData[key]);
                } else if (
                  ks4QuestionnaireData[key].type === TYPE.select &&
                  ks4QuestionnaireData[key].visibile
                ) {
                  return renderSelectElement(ks4QuestionnaireData[key]);
                }
              })}

            {/*Submit*/}
            {editable && (
              <div className="col-12">
                <div className="form-group pb-4">
                  <input
                    type="submit"
                    className="button-style button-style-green-btm button-style-normal-button"
                    disabled={loading}
                    value={loading ? "Loading..." : "Submit"}
                  />
                </div>
              </div>
            )}
          </Form>
        </Skeleton>
      </div>
    </div>
  );
}

export { KS4Questionnaire };
