import { defaults } from "./default";

export const user = {
  register: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/register"
    }
  },
  schoolsList: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/schools"
    }
  },
  login: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/login"
    }
  },
  currentUser: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/user"
    }
  },
  logout: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/logout"
    }
  },
  resendOTPCode: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/refresh"
    }
  },
  requestPasswordReset: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/request-password-reset"
    }
  },
  updatePassword: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/update-password"
    }
  },

  // @Deprecated:
  thirdPartyRegister: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/third-party-register"
    }
  },
  thirdPartyLogin: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/third-party-login"
    }
  },
  schoolStudents: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/school-students"
    }
  },
  logTime: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/log-time"
    }
  },
  listTimeLog: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/list-timelog"
    }
  },
  deleteTimeLog: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/delete-logged-time"
    }
  },
  uploadProfilePic: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/upload-profile-pic",
      headerProps: {
        "Content-Type": "multipart/form-data"
      }
    }
  },
  getProfilePic: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-profile-pic"
    }
  },
  getProfileDetails: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-user-details/:userId"
    }
  },
  getNextAppointment: {
    v1: {
      ...defaults.methods.GET,
      ...defaults.versions.v1,
      uri: "/get-next-appointment"
    }
  },
  getToEmailUserList: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/to-user-list"
    }
  },
  sendEmail: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/send-email"
    }
  },
  getEmails: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/:emailType"
    }
  },
  readEmail: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/mark-status-read"
    }
  },
  starEmail: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/marking-starred"
    }
  },
  singleEmail: {
    v1: {
      ...defaults.methods.POST,
      ...defaults.versions.v1,
      uri: "/mail-details"
    }
  }
};
